export const BookkeeperABI = [
  {
    inputs: [],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "bytes32",
        name: "blueprintHash",
        type: "bytes32",
      },
    ],
    name: "DestroyedBlueprint",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        components: [
          {
            components: [
              {
                internalType: "address",
                name: "publisher",
                type: "address",
              },
              {
                internalType: "bytes",
                name: "data",
                type: "bytes",
              },
              {
                internalType: "uint256",
                name: "maxNonce",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "startTime",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "endTime",
                type: "uint256",
              },
            ],
            internalType: "struct Blueprint",
            name: "blueprint",
            type: "tuple",
          },
          {
            internalType: "bytes32",
            name: "blueprintHash",
            type: "bytes32",
          },
          {
            internalType: "bytes",
            name: "signature",
            type: "bytes",
          },
        ],
        indexed: false,
        internalType: "struct SignedBlueprint",
        name: "agreement",
        type: "tuple",
      },
      {
        indexed: false,
        internalType: "bytes32",
        name: "orderBlueprintHash",
        type: "bytes32",
      },
      {
        indexed: false,
        internalType: "address",
        name: "taker",
        type: "address",
      },
    ],
    name: "OrderFilled",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        components: [
          {
            components: [
              {
                internalType: "address",
                name: "publisher",
                type: "address",
              },
              {
                internalType: "bytes",
                name: "data",
                type: "bytes",
              },
              {
                internalType: "uint256",
                name: "maxNonce",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "startTime",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "endTime",
                type: "uint256",
              },
            ],
            internalType: "struct Blueprint",
            name: "blueprint",
            type: "tuple",
          },
          {
            internalType: "bytes32",
            name: "blueprintHash",
            type: "bytes32",
          },
          {
            internalType: "bytes",
            name: "signature",
            type: "bytes",
          },
        ],
        indexed: false,
        internalType: "struct SignedBlueprint",
        name: "agreement",
        type: "tuple",
      },
      {
        indexed: false,
        internalType: "address",
        name: "position",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "closer",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "closeValue",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "loanOracle",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "assessorCost",
        type: "uint256",
      },
    ],
    name: "PositionClosed",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        components: [
          {
            components: [
              {
                internalType: "address",
                name: "publisher",
                type: "address",
              },
              {
                internalType: "bytes",
                name: "data",
                type: "bytes",
              },
              {
                internalType: "uint256",
                name: "maxNonce",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "startTime",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "endTime",
                type: "uint256",
              },
            ],
            internalType: "struct Blueprint",
            name: "blueprint",
            type: "tuple",
          },
          {
            internalType: "bytes32",
            name: "blueprintHash",
            type: "bytes32",
          },
          {
            internalType: "bytes",
            name: "signature",
            type: "bytes",
          },
        ],
        indexed: false,
        internalType: "struct SignedBlueprint",
        name: "agreement",
        type: "tuple",
      },
      {
        indexed: false,
        internalType: "address",
        name: "position",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "liquidator",
        type: "address",
      },
    ],
    name: "PositionLiquidated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        components: [
          {
            components: [
              {
                internalType: "address",
                name: "publisher",
                type: "address",
              },
              {
                internalType: "bytes",
                name: "data",
                type: "bytes",
              },
              {
                internalType: "uint256",
                name: "maxNonce",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "startTime",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "endTime",
                type: "uint256",
              },
            ],
            internalType: "struct Blueprint",
            name: "blueprint",
            type: "tuple",
          },
          {
            internalType: "bytes32",
            name: "blueprintHash",
            type: "bytes32",
          },
          {
            internalType: "bytes",
            name: "signature",
            type: "bytes",
          },
        ],
        indexed: false,
        internalType: "struct SignedBlueprint",
        name: "signedBlueprint",
        type: "tuple",
      },
    ],
    name: "PublishedBlueprint",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "operator",
        type: "address",
      },
      {
        indexed: false,
        internalType: "bytes32",
        name: "blueprintHash",
        type: "bytes32",
      },
    ],
    name: "UsedBlueprint",
    type: "event",
  },
  {
    inputs: [],
    name: "BLUEPRINT_TYPEHASH",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "PROTOCOL_NAME",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "PROTOCOL_VERSION",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    name: "agreementClosed",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            components: [
              {
                internalType: "address",
                name: "publisher",
                type: "address",
              },
              {
                internalType: "bytes",
                name: "data",
                type: "bytes",
              },
              {
                internalType: "uint256",
                name: "maxNonce",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "startTime",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "endTime",
                type: "uint256",
              },
            ],
            internalType: "struct Blueprint",
            name: "blueprint",
            type: "tuple",
          },
          {
            internalType: "bytes32",
            name: "blueprintHash",
            type: "bytes32",
          },
          {
            internalType: "bytes",
            name: "signature",
            type: "bytes",
          },
        ],
        internalType: "struct SignedBlueprint",
        name: "agreementBlueprint",
        type: "tuple",
      },
    ],
    name: "closePosition",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            components: [
              {
                internalType: "address",
                name: "publisher",
                type: "address",
              },
              {
                internalType: "bytes",
                name: "data",
                type: "bytes",
              },
              {
                internalType: "uint256",
                name: "maxNonce",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "startTime",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "endTime",
                type: "uint256",
              },
            ],
            internalType: "struct Blueprint",
            name: "blueprint",
            type: "tuple",
          },
          {
            internalType: "bytes32",
            name: "blueprintHash",
            type: "bytes32",
          },
          {
            internalType: "bytes",
            name: "signature",
            type: "bytes",
          },
        ],
        internalType: "struct SignedBlueprint",
        name: "signedBlueprint",
        type: "tuple",
      },
    ],
    name: "destroyBlueprint",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "domainSeparator",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            components: [
              {
                internalType: "address",
                name: "addr",
                type: "address",
              },
              {
                internalType: "bytes",
                name: "parameters",
                type: "bytes",
              },
            ],
            internalType: "struct PluginReference",
            name: "account",
            type: "tuple",
          },
          {
            internalType: "uint256",
            name: "loanAmount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "takerIdx",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "loanAssetIdx",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "collAssetIdx",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "factoryIdx",
            type: "uint256",
          },
          {
            internalType: "bytes",
            name: "fillerData",
            type: "bytes",
          },
          {
            internalType: "bool",
            name: "isOfferFill",
            type: "bool",
          },
          {
            components: [
              {
                internalType: "uint256",
                name: "collAmount",
                type: "uint256",
              },
              {
                internalType: "bytes",
                name: "positionParameters",
                type: "bytes",
              },
            ],
            internalType: "struct BorrowerConfig",
            name: "borrowerConfig",
            type: "tuple",
          },
        ],
        internalType: "struct Fill",
        name: "fill",
        type: "tuple",
      },
      {
        components: [
          {
            components: [
              {
                internalType: "address",
                name: "publisher",
                type: "address",
              },
              {
                internalType: "bytes",
                name: "data",
                type: "bytes",
              },
              {
                internalType: "uint256",
                name: "maxNonce",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "startTime",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "endTime",
                type: "uint256",
              },
            ],
            internalType: "struct Blueprint",
            name: "blueprint",
            type: "tuple",
          },
          {
            internalType: "bytes32",
            name: "blueprintHash",
            type: "bytes32",
          },
          {
            internalType: "bytes",
            name: "signature",
            type: "bytes",
          },
        ],
        internalType: "struct SignedBlueprint",
        name: "orderBlueprint",
        type: "tuple",
      },
    ],
    name: "fillOrder",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "address",
            name: "publisher",
            type: "address",
          },
          {
            internalType: "bytes",
            name: "data",
            type: "bytes",
          },
          {
            internalType: "uint256",
            name: "maxNonce",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "startTime",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "endTime",
            type: "uint256",
          },
        ],
        internalType: "struct Blueprint",
        name: "blueprint",
        type: "tuple",
      },
    ],
    name: "getBlueprintHash",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getChainId",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "blueprintHash",
        type: "bytes32",
      },
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    name: "isValidSignature",
    outputs: [
      {
        internalType: "bytes4",
        name: "magicValue",
        type: "bytes4",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "signer",
        type: "address",
      },
      {
        internalType: "bytes32",
        name: "hash",
        type: "bytes32",
      },
      {
        internalType: "bytes",
        name: "signature",
        type: "bytes",
      },
    ],
    name: "isValidSignatureNow",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes1",
        name: "dataType",
        type: "bytes1",
      },
      {
        internalType: "bytes",
        name: "data",
        type: "bytes",
      },
    ],
    name: "packDataField",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            components: [
              {
                internalType: "address",
                name: "publisher",
                type: "address",
              },
              {
                internalType: "bytes",
                name: "data",
                type: "bytes",
              },
              {
                internalType: "uint256",
                name: "maxNonce",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "startTime",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "endTime",
                type: "uint256",
              },
            ],
            internalType: "struct Blueprint",
            name: "blueprint",
            type: "tuple",
          },
          {
            internalType: "bytes32",
            name: "blueprintHash",
            type: "bytes32",
          },
          {
            internalType: "bytes",
            name: "signature",
            type: "bytes",
          },
        ],
        internalType: "struct SignedBlueprint",
        name: "signedBlueprint",
        type: "tuple",
      },
    ],
    name: "publishBlueprint",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            components: [
              {
                internalType: "address",
                name: "publisher",
                type: "address",
              },
              {
                internalType: "bytes",
                name: "data",
                type: "bytes",
              },
              {
                internalType: "uint256",
                name: "maxNonce",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "startTime",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "endTime",
                type: "uint256",
              },
            ],
            internalType: "struct Blueprint",
            name: "blueprint",
            type: "tuple",
          },
          {
            internalType: "bytes32",
            name: "blueprintHash",
            type: "bytes32",
          },
          {
            internalType: "bytes",
            name: "signature",
            type: "bytes",
          },
        ],
        internalType: "struct SignedBlueprint",
        name: "agreementBlueprint",
        type: "tuple",
      },
      {
        internalType: "bytes",
        name: "liquidatorLogic",
        type: "bytes",
      },
    ],
    name: "triggerLiquidation",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes",
        name: "data",
        type: "bytes",
      },
    ],
    name: "unpackDataField",
    outputs: [
      {
        internalType: "bytes1",
        name: "",
        type: "bytes1",
      },
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            components: [
              {
                internalType: "address",
                name: "publisher",
                type: "address",
              },
              {
                internalType: "bytes",
                name: "data",
                type: "bytes",
              },
              {
                internalType: "uint256",
                name: "maxNonce",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "startTime",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "endTime",
                type: "uint256",
              },
            ],
            internalType: "struct Blueprint",
            name: "blueprint",
            type: "tuple",
          },
          {
            internalType: "bytes32",
            name: "blueprintHash",
            type: "bytes32",
          },
          {
            internalType: "bytes",
            name: "signature",
            type: "bytes",
          },
        ],
        internalType: "struct SignedBlueprint",
        name: "agreementBlueprint",
        type: "tuple",
      },
    ],
    name: "unwindPosition",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
] as const;

import React, { useMemo } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useAccount, useNetwork } from "wagmi";
import { useWeb3Modal } from "@web3modal/react";
import { useDispatch } from "react-redux";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

import { OrderTypes, PageName } from "../../helpers/enums";
import PageMeta from "../../layout/PageMeta";
import { useOneOrder } from "../../hooks/apis";
import PageSpinner from "../../components/PageSpinner";
import { formatNumber, secondToDateType, shortenAddress } from "../../helpers/utils/format";
import { getAssetData } from "../../helpers/utils/assets";
import { Order } from "../../helpers/types/response";
import { setOrderFillModalState } from "../../redux/reducers/order-slice";
import { useNotification } from "../../hooks/useNotification";
import { useAppSelector } from "../../redux/store";

function OrderDetail() {
  const { chain } = useNetwork();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const tokenId = searchParams.get('tokenId');
  const { isConnected } = useAccount();
  const { open } = useWeb3Modal();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { displayNotification } = useNotification();
  const accounts = useAppSelector((state) => state.user.accounts);

  const { order, availableAmount, isLoading } = useOneOrder(params.assetAddress, params.orderHash, chain?.id, tokenId ?? "0");

  const durationData = useMemo(() => {
    if (order) return secondToDateType(order?.duration);
  }, [order]);

  const loanAssetInfo = useMemo(() => {
    if (order) return getAssetData(order?.loanAsset, order.chainId);
  }, [order]);

  const handleFillButton = async (order: Order, available: number) => {
    if (!isConnected) {
      await open();
    } else if (accounts.length > 0) {
      dispatch(setOrderFillModalState({ isOpened: true, orderData: order, available }));
    } else {
      displayNotification({ message: "Please create accounts first", type: "info" });
      navigate("/account");
    }
  };

  return (
    <>
      <PageMeta pageName={PageName.MARKETS} />
      {isLoading || order === undefined ? (
        <PageSpinner />
      ) : (
        <Container maxWidth="lg">
          <Stack flexDirection={"row"} gap={3} mt={6} mb={2} alignItems={"center"}>
            <Typography variant="h6">market - {shortenAddress(order.blueprintData.blueprintHash)}</Typography>
            <Button
              onClick={() => handleFillButton(order, availableAmount)}
              sx={{ borderRadius: 2, px: 2, py: 1, bgcolor: "background.paper", color: "white.main" }}
            >
              {isConnected ? (order?.orderType === OrderTypes.LEND ? "borrow" : "lend") : "connect wallet"}
            </Button>
          </Stack>
          <Paper elevation={0} sx={{ p: 4 }}>
            <Grid container rowSpacing={2}>
              <Grid xs={12} container>
                <Grid xs={12} sm={6} md={3}>
                  <Typography variant="body2" color={"gray.main"}>
                    market type
                  </Typography>
                  <Typography variant="body1">{order?.orderType}</Typography>
                </Grid>
                <Grid xs={12} sm={6} md={3}>
                  <Typography variant="body2" color={"gray.main"}>
                    {order?.orderType === OrderTypes.LEND ? "lender" : "borrower"}
                  </Typography>
                  <Typography variant="body1">{shortenAddress(order?.orderer?.creatorAddress)}</Typography>
                </Grid>
                <Grid xs={12} sm={6} md={3}>
                  <Typography variant="body2" color={"gray.main"}>
                    type
                  </Typography>
                  <Typography variant="body1">{order?.takers?.length ? "private" : "public"}</Typography>
                </Grid>
                <Grid xs={12} sm={6} md={3}>
                  <Typography variant="body2" color={"gray.main"}>
                    duration
                  </Typography>
                  <Typography variant="body1">
                    {durationData?.value} {durationData?.unit.toLocaleLowerCase()}
                  </Typography>
                </Grid>
                {order?.orderType === OrderTypes.BORROW && (
                  <Grid xs={12} sm={6} md={3}>
                    <Typography variant="body2" color={"gray.main"}>
                      ICR
                    </Typography>
                    <Typography variant="body1">{order?.initialCollateralRatio}%</Typography>
                  </Grid>
                )}
                <Grid xs={12} sm={6} md={3}>
                  <Typography variant="body2" color={"gray.main"}>
                    oracle
                  </Typography>
                  <Typography variant="body1">{order?.loanOracle?.pluginName}</Typography>
                </Grid>
                <Grid xs={12} sm={6} md={3}>
                  <Typography variant="body2" color={"gray.main"}>
                    liquidation
                  </Typography>
                  <Typography variant="body1">{order?.closer?.pluginName}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Divider sx={{ borderColor: "white", borderWidth: 1, opacity: 1, my: 3 }} />
            <Grid container rowSpacing={2}>
              <Grid xs={12}>
                <Typography variant="h6" mb={1}>
                  assets
                </Typography>
              </Grid>
              <Grid xs={12} container>
                <Grid xs={12} sm={6} md={2}>
                  <Typography variant="body2" color={"gray.main"}>
                    asset type
                  </Typography>
                  <Typography variant="body1">loan</Typography>
                </Grid>
                <Grid xs={12} sm={6} md={2}>
                  <Typography variant="body2" color={"gray.main"}>
                    asset
                  </Typography>
                  <Typography variant="body1">{loanAssetInfo?.symbol}</Typography>
                </Grid>
                <Grid xs={12} sm={6} md={3}>
                  <Typography variant="body2" color={"gray.main"}>
                    oracle
                  </Typography>
                  <Typography variant="body1">{order?.loanOracle?.pluginName}</Typography>
                </Grid>
                <Grid xs={12} sm={6} md={3}>
                  <Typography variant="body2" color={"gray.main"}>
                    min. loan amount
                  </Typography>
                  <Typography variant="body1">
                    {order?.minLoanAmount} {loanAssetInfo?.symbol}
                  </Typography>
                </Grid>
                <Grid xs={12} sm={6} md={2}>
                  <Typography variant="body2" color={"gray.main"}>
                    available amount
                  </Typography>
                  <Typography variant="body1">
                    {formatNumber(availableAmount)} {loanAssetInfo?.symbol}
                  </Typography>
                </Grid>
              </Grid>
              {order?.collateralAssets?.map((asset: any, i: number) => {
                const assetData = getAssetData(asset, order.chainId);
                return (
                  <Grid xs={12} container key={i}>
                    <Grid xs={12} sm={6} md={2}>
                      <Typography variant="body2" color={"gray.main"}>
                        asset type
                      </Typography>
                      <Typography variant="body1">collateral</Typography>
                    </Grid>
                    <Grid xs={12} sm={6} md={2}>
                      <Typography variant="body2" color={"gray.main"}>
                        asset
                      </Typography>
                      <Typography variant="body1">{assetData?.symbol}</Typography>
                    </Grid>
                    <Grid xs={12} sm={6} md={3}>
                      <Typography variant="body2" color={"gray.main"}>
                        oracle
                      </Typography>
                      <Typography variant="body1">{order?.collateralOracles[i]?.pluginName}</Typography>
                    </Grid>
                    <Grid xs={12} sm={6} md={3}>
                      <Typography variant="body2" color={"gray.main"}>
                        MCR
                      </Typography>
                      <Typography variant="body1">{order?.minCollateralRatio[i]}%</Typography>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Paper>
        </Container>
      )}
    </>
  );
}

export default OrderDetail;

import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
import { useNetwork } from "wagmi";

import { OrderTypes, PageName } from "../../helpers/enums";
import PageMeta from "../../layout/PageMeta";
import PageSpinner from "../../components/PageSpinner";
import { secondToDateType, shortenAddress } from "../../helpers/utils/format";
import { getAssetData } from "../../helpers/utils/assets";
import { useOneAgreement } from "../../hooks/apis/useOneAgreement";
import { Agreement } from "../../helpers/types/response";

function AgreementDetail() {
  const { chain } = useNetwork();
  const params = useParams();

  const { agreement, isLoading } = useOneAgreement(params.agreementHash, chain?.id);

  const leftDuration = React.useCallback((agreement: Agreement | undefined) => {
    if (!agreement) {
      return "--";
    }
    if (Number(agreement.order.duration) === Number.MAX_SAFE_INTEGER) {
      return "infinity";
    }
    const endDate = new Date(agreement?.endDate).getTime();
    const currentDate = new Date().getTime();
    const result = secondToDateType((endDate - currentDate) / 1000);
    return `${Math.round(result.value || 0)} ${result.unit}`;
  }, []);

  const loanAssetInfo = useMemo(() => {
    if (agreement) return getAssetData(agreement?.loanAsset, agreement.chainId);
  }, [agreement]);
  const collAssetInfo = useMemo(() => {
    if (agreement) return getAssetData(agreement?.collAsset, agreement.chainId);
  }, [agreement]);
  return (
    <>
      <PageMeta pageName={PageName.MARKETS} />
      {isLoading || agreement === undefined ? (
        <PageSpinner />
      ) : (
        <Container maxWidth="lg">
          <Stack flexDirection={"row"} gap={3} mt={6} mb={2} alignItems={"center"}>
            <Typography variant="h6">agreement - {shortenAddress(agreement.agreementHash)}</Typography>
          </Stack>
          <Paper elevation={0} sx={{ p: 4 }}>
            <Grid container rowSpacing={2}>
              <Grid xs={12} container>
                <Grid xs={12} sm={6} md={3}>
                  <Typography variant="body2" color={"gray.main"}>
                    creator
                  </Typography>
                  <Typography variant="body1">
                    <Link
                      color={"white.main"}
                      target="_blank"
                      rel="noreferrer"
                      underline="hover"
                      href={`${chain?.blockExplorers?.default.url}/address/${
                        agreement?.order?.orderType === OrderTypes.LEND
                          ? agreement.lender?.creatorAddress
                          : agreement.borrower?.creatorAddress
                      }`}
                    >
                      {agreement?.order?.orderType === OrderTypes.LEND
                        ? shortenAddress(agreement.lender?.creatorAddress)
                        : shortenAddress(agreement.borrower?.creatorAddress)}
                    </Link>
                  </Typography>
                </Grid>
                <Grid xs={12} sm={6} md={3}>
                  <Typography variant="body2" color={"gray.main"}>
                    lender
                  </Typography>
                  <Typography variant="body1">
                    <Link
                      color={"white.main"}
                      target="_blank"
                      rel="noreferrer"
                      underline="hover"
                      href={`${chain?.blockExplorers?.default.url}/address/${agreement?.lender?.creatorAddress}`}
                    >
                      {shortenAddress(agreement?.lender?.creatorAddress)}
                    </Link>
                  </Typography>
                </Grid>
                <Grid xs={12} sm={6} md={3}>
                  <Typography variant="body2" color={"gray.main"}>
                    borrower
                  </Typography>
                  <Typography variant="body1">
                    <Link
                      color={"white.main"}
                      target="_blank"
                      rel="noreferrer"
                      underline="hover"
                      href={`${chain?.blockExplorers?.default.url}/address/${agreement?.borrower?.creatorAddress}`}
                    >
                      {shortenAddress(agreement?.borrower?.creatorAddress)}
                    </Link>
                  </Typography>
                </Grid>
                <Grid xs={12} sm={6} md={3}>
                  <Typography variant="body2" color={"gray.main"}>
                    type
                  </Typography>
                  <Typography variant="body1">
                    {agreement.order?.takers?.length ? "private" : "public"}
                  </Typography>
                </Grid>
                <Grid xs={12} sm={6} md={3}>
                  <Typography variant="body2" color={"gray.main"}>
                    MCR
                  </Typography>
                  <Typography variant="body1">{agreement?.minCollateralRatio}%</Typography>
                </Grid>
                <Grid xs={12} sm={6} md={3}>
                  <Typography variant="body2" color={"gray.main"}>
                    position
                  </Typography>
                  <Typography variant="body1">
                    <Link
                      color={"white.main"}
                      target="_blank"
                      rel="noreferrer"
                      underline="hover"
                      href={`${chain?.blockExplorers?.default.url}/address/${agreement?.positionAddress}`}
                    >
                      {shortenAddress(agreement?.positionAddress)}
                    </Link>
                  </Typography>
                </Grid>
                <Grid xs={12} sm={6} md={3}>
                  <Typography variant="body2" color={"gray.main"}>
                    liquidation
                  </Typography>
                  <Typography variant="body1">{agreement?.closer?.pluginName}</Typography>
                </Grid>
                <Grid xs={12} sm={6} md={3}>
                  <Typography variant="body2" color={"gray.main"}>
                    duration
                  </Typography>
                  <Typography variant="body1">{leftDuration(agreement)}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Divider sx={{ borderColor: "white", borderWidth: 1, opacity: 1, my: 3 }} />
            <Grid container rowSpacing={2}>
              <Grid xs={12}>
                <Typography variant="h6" mb={1}>
                  assets
                </Typography>
              </Grid>
              <Grid xs={12} container>
                <Grid xs={12} sm={6} md={3}>
                  <Typography variant="body2" color={"gray.main"}>
                    asset type
                  </Typography>
                  <Typography variant="body1">loan</Typography>
                </Grid>
                <Grid xs={12} sm={6} md={3}>
                  <Typography variant="body2" color={"gray.main"}>
                    asset
                  </Typography>
                  <Typography variant="body1">{loanAssetInfo?.symbol}</Typography>
                </Grid>
                <Grid xs={12} sm={6} md={3}>
                  <Typography variant="body2" color={"gray.main"}>
                    oracle
                  </Typography>
                  <Typography variant="body1">{agreement?.loanOracle?.pluginName}</Typography>
                </Grid>
                <Grid xs={12} sm={6} md={3}>
                  <Typography variant="body2" color={"gray.main"}>
                    min. loan amount
                  </Typography>
                  <Typography variant="body1">
                    {agreement?.minLoanAmount} {loanAssetInfo?.symbol}
                  </Typography>
                </Grid>
              </Grid>
              <Grid xs={12} container>
                <Grid xs={12} sm={6} md={3}>
                  <Typography variant="body2" color={"gray.main"}>
                    asset type
                  </Typography>
                  <Typography variant="body1">collateral</Typography>
                </Grid>
                <Grid xs={12} sm={6} md={3}>
                  <Typography variant="body2" color={"gray.main"}>
                    asset
                  </Typography>
                  <Typography variant="body1">{collAssetInfo?.symbol}</Typography>
                </Grid>
                <Grid xs={12} sm={6} md={3}>
                  <Typography variant="body2" color={"gray.main"}>
                    oracle
                  </Typography>
                  <Typography variant="body1">{agreement?.collateralOracle?.pluginName}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Container>
      )}
    </>
  );
}

export default AgreementDetail;

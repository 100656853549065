import { useState, useEffect } from "react";
import { Order } from "../../helpers/types/response";
import { getOrderDetail } from "../../helpers/utils/api";
import { OrderTypes } from "../../helpers/enums";

export const useOrderDetail = (
  assetAddress: string | undefined,
  orderType: OrderTypes,
  chainId = Number(process.env.REACT_APP_CHAIN_ID)
) => {
  const [orderDetail, setOrderDetail] = useState<Order[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [availableAmounts, setAvailableAmounts] = useState<number[]>([]);

  useEffect(() => {
    (async () => {
      try {
        if (assetAddress) {
          setIsLoading(true);
          const ordersDetail = await getOrderDetail(assetAddress, orderType, chainId);
          setOrderDetail(ordersDetail[0]);
          setAvailableAmounts(ordersDetail[1]);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [orderType, assetAddress, chainId]);

  return { orderDetail, isLoading, availableAmounts };
};

import React from "react";
import {
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useAppSelector } from "../../redux/store";
import { ADDRESS_TO_PAIR } from "../../helpers/constants/tokens";
import PageMeta from "../../layout/PageMeta";
import { PageName } from "../../helpers/enums";
import { useChainId, useContractReads } from "wagmi";
import { useAgreements } from "../../hooks/apis";
import { getAssetData } from "../../helpers/utils/assets";
import { AgreementStruct, PositionAbi } from "../../helpers/abis";
import { Agreement } from "../../helpers/types/response";
import { secondToDateType, unpackDataField } from "../../helpers/utils/format";
import { PluginType } from "../../helpers/types/basic";
import { decodeAbiParameters, formatUnits, parseAbiParameters } from "viem";
import { ParametersToDecode } from "../../config/parameter-abis";
import { WETH_ADDRESS } from "../../helpers/constants/address";

function Liquidation() {
  const tokenPrices = useAppSelector((state) => state.app.tokenPrices);
  const userAddress = useAppSelector((state) => state.user.address);
  const chainId = useChainId();
  const { agreements, isLoading: apiLoading } = useAgreements(undefined, chainId);
  const [checked, setChecked] = React.useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };
  const contracts = React.useMemo(() => {
    const returnValue: { abi: any; address: `0x${string}`; functionName: string; args: any[] }[] = [];
    const amountIndex: string[] = [];
    // eslint-disable-next-line array-callback-return
    agreements?.map((agreement) => {
      returnValue.push({
        abi: PositionAbi,
        address: agreement.positionAddress,
        functionName: "getCloseAmount",
        args: [
          decodeAbiParameters(
            parseAbiParameters(AgreementStruct),
            unpackDataField(agreements[0].agreementData) as `0x${string}`
          )[0],
        ],
      });
      amountIndex.push(agreement.agreementData);
    });
    return { returnValue, amountIndex };
  }, [agreements]);
  const {
    data: positionValues,
    isFetching,
    isLoading,
  } = useContractReads({ contracts: contracts.returnValue });

  const leftDuration = React.useCallback((agreement: Agreement | undefined) => {
    if (!agreement) {
      return "--";
    }
    if (Number(agreement.order.duration) === Number.MAX_SAFE_INTEGER) {
      return "infinity";
    }
    const endDate = new Date(agreement?.endDate).getTime();
    const currentDate = new Date().getTime();
    let result = secondToDateType((endDate - currentDate) / 1000);
    if (result.value && result.value < 0) result = { ...result, value: 0 };
    return `${Math.round(result.value || 0)} ${result.unit}`;
  }, []);

  const fee = React.useCallback((collector: PluginType) => {
    const fees = decodeAbiParameters(
      parseAbiParameters(ParametersToDecode[collector.pluginName]),
      collector.parameters
    );
    return [
      parseFloat(formatUnits((fees[0] as any)["originationFeeRatio"] || 0n, 18)),
      parseFloat(formatUnits((fees[0] as any)["profitShareRatio"] || 0n, 18)),
    ];
  }, []);

  const PnL = React.useCallback(
    (positionValue: number, agreement: Agreement) => {
      if (agreement.lender.creatorAddress === userAddress) {
        if (positionValue > Number(agreement.loanAmount)) {
          return fee(agreement.collector);
        } else {
          return positionValue - Number(agreement.loanAmount) + fee(agreement.collector)[0];
        }
      } else {
        return positionValue - Number(agreement.loanAmount) - fee(agreement.collector)[0];
      }
    },
    [fee, userAddress]
  );

  return (
    <Container maxWidth="xl" sx={{ py: 5 }}>
      <PageMeta pageName={PageName.LIQUIDATION} />
      <FormControlLabel
        control={
          <Checkbox checked={checked} onChange={handleChange} inputProps={{ "aria-label": "controlled" }} />
        }
        label="hide healthy loans"
      />
      <TableContainer component={Paper} sx={{ borderRadius: 2 }} elevation={0}>
        <Table sx={{ minWidth: 650 }} aria-label="market table">
          <TableHead>
            <TableRow sx={{ "td,th": { border: 0 } }}>
              <TableCell align="right">loan value</TableCell>
              <TableCell align="right">duration left</TableCell>
              <TableCell align="right">role</TableCell>
              <TableCell align="right">positions value</TableCell>
              <TableCell align="right">liquidation price</TableCell>
              <TableCell align="right">CR</TableCell>
              <TableCell align="right">MCR</TableCell>
              <TableCell align="right">collateral value</TableCell>
              <TableCell align="right">PnL</TableCell>
              <TableCell align="right">reward</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {apiLoading || isLoading ? (
              <TableRow sx={{ "td,th": { border: 0 } }}>
                <TableCell>Loading</TableCell>
              </TableRow>
            ) : (
              <>
                {agreements?.length ? (
                  <>
                    {agreements.map((agreement, i) => {
                      const loanAsset = getAssetData(agreement.loanAsset, agreement.chainId);
                      const collAsset = getAssetData(agreement.collAsset, agreement.chainId);
                      const positionValue =
                        positionValues?.[contracts.amountIndex.indexOf(agreement.agreementData)];
                      const loanOracleRatio =
                        tokenPrices[ADDRESS_TO_PAIR[WETH_ADDRESS[chainId]]] /
                        tokenPrices[ADDRESS_TO_PAIR[agreement.loanAsset.addr]];

                      const collateralOracleRatio =
                        tokenPrices[ADDRESS_TO_PAIR[WETH_ADDRESS[chainId]]] /
                        tokenPrices[ADDRESS_TO_PAIR[agreement.collAsset.addr]];
                      const CR =
                        (parseFloat(formatUnits((positionValue?.result as unknown as bigint) || 0n, 18)) /
                          loanOracleRatio +
                          Number(agreement.collAmount) / collateralOracleRatio) /
                        (Number(agreement.loanAmount) / loanOracleRatio);
                      const MCR = agreement?.minCollateralRatio / 100;
                      const endDate = new Date(agreement?.endDate).getTime();
                      const currentDate = new Date().getTime();
                      let isHealthy = true;
                      if (endDate < currentDate) isHealthy = false;
                      if (CR <= MCR) isHealthy = false;
                      if (!checked || (checked && !isHealthy)) {
                        return (
                          <TableRow key={i} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                            {/* <TableCell sx={{ wordBreak: "keep-all" }}>
                            loan - {i.toString().padStart(3, "0")}
                          </TableCell> */}
                            <TableCell align="right">
                              {agreement.loanAmount || 0} {loanAsset?.symbol}
                            </TableCell>
                            <TableCell align="right">{leftDuration(agreement)}</TableCell>
                            <TableCell align="right">
                              {agreement.lender.creatorAddress === userAddress ? "lender" : "borrower"}{" "}
                            </TableCell>
                            <TableCell align="right">
                              {isLoading || isFetching
                                ? "--"
                                : formatUnits((positionValue?.result as unknown as bigint) || 0n, 18)}{" "}
                              {loanAsset?.symbol}
                            </TableCell>
                            <TableCell align="right">{fee(agreement.collector)[0] || 0}</TableCell>
                            <TableCell align="right">
                              {isLoading || isFetching
                                ? "--"
                                : (
                                    (parseFloat(
                                      formatUnits((positionValue?.result as unknown as bigint) || 0n, 18)
                                    ) /
                                      loanOracleRatio +
                                      Number(agreement.collAmount) / collateralOracleRatio) /
                                    (Number(agreement.loanAmount) / loanOracleRatio)
                                  ).toFixed(2)}
                            </TableCell>
                            <TableCell align="right">{agreement?.minCollateralRatio / 100}</TableCell>
                            <TableCell align="right">
                              {agreement.collAmount || "--"} {collAsset?.symbol}
                            </TableCell>
                            <TableCell align="right">
                              {isLoading || isFetching
                                ? "--"
                                : PnL(
                                    parseFloat(
                                      formatUnits((positionValue?.result as unknown as bigint) || 0n, 18)
                                    ),
                                    agreement
                                  )}
                            </TableCell>
                            <TableCell align="right">{fee(agreement.collector)[1] || 0}</TableCell>
                            <TableCell sx={{ fontSize: 16 }}>
                              {agreement.isClosed ? (
                                <Button disabled>closed</Button>
                              ) : (
                                <Button disabled={true} color="error">
                                  close
                                </Button>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </>
                ) : (
                  <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell sx={{ px: 2 }}>no loans</TableCell>
                  </TableRow>
                )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}

export default Liquidation;

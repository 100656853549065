import React from "react";
import { Box } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';

const PageSpinner = () => {
  return (
    <Box
      className="backdrop-blur"
      sx={{
        position: "fixed",
        left: 0,
        top: 0,
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 50,
        bgcolor: "transparent",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default PageSpinner;

import React, { useMemo } from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { useAppSelector } from "../../redux/store";
import { Box, Container, Paper } from "@mui/material";
import { ADDRESS_TO_PAIR } from "../../helpers/constants/tokens";

function UserInfo({ loansNumber }: { loansNumber: number }) {
  const userSlice = useAppSelector((state) => state.user);
  const tokenPrices = useAppSelector((state) => state.app.tokenPrices);
  const values = useMemo(() => {
    let totalDeposits = 0,
      totalLoaned = 0,
      totalBorrowed = 0,
      totalCollateral = 0,
      totalAvailable = 0;
    // eslint-disable-next-line array-callback-return
    userSlice.accounts.map((account) => {
      if (account?.assets?.length) {
        for (const asset of account?.assets) {
          totalDeposits += (tokenPrices[ADDRESS_TO_PAIR[asset.assetType.addr]] ?? 0) * Number(asset.amount);
          totalLoaned += (tokenPrices[ADDRESS_TO_PAIR[asset.assetType.addr]] ?? 0) * Number(asset.loanedAmount);
          totalBorrowed += (tokenPrices[ADDRESS_TO_PAIR[asset.assetType.addr]] ?? 0) * Number(asset.borrowedAmount);
          totalCollateral +=
            (tokenPrices[ADDRESS_TO_PAIR[asset.assetType.addr]] ?? 0) * Number(asset.collateralAmount);
        }
      }
    });
    totalAvailable = totalDeposits - totalCollateral;
    return [
      totalDeposits.toFixed(0),
      totalLoaned.toFixed(0),
      totalBorrowed.toFixed(0),
      totalCollateral.toFixed(0),
      totalAvailable.toFixed(0),
    ];
  }, [tokenPrices, userSlice]);

  return (
    <Paper elevation={0}>
      <Container maxWidth="xl">
        <Stack
          px={{ sx: 2, md: 3, lg: 4, xl: 5 }}
          py={2}
          flexDirection={"row"}
          gap={2}
          flexWrap={"wrap"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Box>
            <Typography color="grey">accounts</Typography>
            <Typography variant="h6" color="white">
              {userSlice.accounts.length}
            </Typography>
          </Box>
          <Box>
            <Typography color="grey">total deposits</Typography>
            <Typography variant="h6" color="white">
              ${values[0] || "--"}
            </Typography>
          </Box>
          <Box>
            <Typography color="grey">loans</Typography>
            <Typography variant="h6" color="white">
              {loansNumber}
            </Typography>
          </Box>
          <Box>
            <Typography color="grey">total loaned</Typography>
            <Typography variant="h6" color="white">
              ${values[1] || "--"}
            </Typography>
          </Box>
          <Box>
            <Typography color="grey">total borrowed</Typography>
            <Typography variant="h6" color="white">
              ${values[2] || "--"}
            </Typography>
          </Box>
          <Box>
            <Typography color="grey">total collateral</Typography>
            <Typography variant="h6" color="white">
              ${values[3] || "--"}
            </Typography>
          </Box>
          <Box>
            <Typography color="grey">total available</Typography>
            <Typography variant="h6" color="white">
              ${values[4] || "--"}
            </Typography>
          </Box>
        </Stack>
      </Container>
    </Paper>
  );
}

export default UserInfo;

import { useState, useEffect } from "react";
import { Agreement } from "../../helpers/types/response";
import { getOneAgreement } from "../../helpers/utils/api";
import { useNotification } from "../useNotification";
import { AxiosError } from "axios";

export const useOneAgreement = (hash: string | undefined, chainId: number | undefined) => {
  const [agreement, setAgreement] = useState<Agreement>();
  const [isLoading, setIsLoading] = useState(false);
  const { displayNotification } = useNotification();

  useEffect(() => {
    (async () => {
      try {
        if (hash) {
          setIsLoading(true);
          const result = await getOneAgreement(hash, chainId);
          setAgreement(result);
        }
      } catch (error: any) {
        const e = error as AxiosError;
        if (e.isAxiosError) {
          displayNotification({ message: error?.response?.data.message, type: "error" });
        } else {
          console.error(error);
        }
      } finally {
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { agreement, isLoading };
};

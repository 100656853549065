import React from "react";
import ReactDOM from "react-dom/client";
import { EthereumClient, w3mConnectors, w3mProvider } from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { mainnet, sepolia } from "wagmi/chains";
import { publicProvider } from "wagmi/providers/public";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { muiTheme } from "./config/theme";
import { ThemeProvider, CssBaseline } from "@mui/material";
import { Provider } from "react-redux";
import store from "./redux/store";
import { Notification } from "./components/Notification";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

if (!process.env.REACT_APP_PROJECT_ID) {
  throw new Error("You need to provide REACT_APP_PROJECT_ID env variable");
}
if (!process.env.REACT_APP_ALCHEMY_API_KEY) {
  throw new Error("You need to provide REACT_APP_ALCHEMY_API_KEY env variable");
}
const projectId = process.env.REACT_APP_PROJECT_ID;
// const apiKey = process.env.REACT_APP_ALCHEMY_API_KEY;

const { publicClient, webSocketPublicClient } = configureChains(
  [mainnet, sepolia],
  [publicProvider(), w3mProvider({ projectId })]
);
const config = createConfig({
  autoConnect: true,
  publicClient,
  webSocketPublicClient,
  connectors: w3mConnectors({ chains: [mainnet, sepolia], projectId }),
});
const ethereumClient = new EthereumClient(config, [mainnet, sepolia]);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <WagmiConfig config={config}>
        <ThemeProvider theme={muiTheme}>
          <BrowserRouter>
            <App />
            <Notification />
            <Web3Modal
              projectId={projectId}
              ethereumClient={ethereumClient}
              defaultChain={mainnet}
              themeVariables={{
                "--w3m-background-color": "#00A1FF",
                "--w3m-accent-color": "#00A1FF",
              }}
            />
            <CssBaseline />
          </BrowserRouter>
        </ThemeProvider>
      </WagmiConfig>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import {
  PluginType,
  Asset,
  AccountType,
  MessageType,
  ERC721Asset,
  ERC1155Asset,
} from "../../helpers/types/basic";
import { RootState } from "../store";
import { APIServer } from "../../helpers/utils/api";
import { addNotification } from "./notification-slice";
import { PluginTypesEnum } from "../../helpers/enums";
import { setPageSpinnerLoading } from "./app-slice";
import { getNfts } from "../../helpers/utils/alchemy_api";
export interface UserState {
  address: `0x${string}`;
  // erc20Assets: ERC20Asset[];
  erc721Assets: ERC721Asset[];
  erc1155Assets: ERC1155Asset[];
  accounts: AccountType[];
  plugins: PluginType[];
}

const initialState: UserState = {
  address: "" as `0x${string}`,
  // erc20Assets: [],
  erc721Assets: [],
  erc1155Assets: [],
  accounts: [],
  plugins: [],
};

export const createNewPlugin = createAsyncThunk(
  "user/createPlugin",
  async (
    {
      signature,
      message,
      pluginType,
      chainId,
      pluginName,
      name,
      parameters,
      description,
    }: {
      signature: `0x${string}`;
      message: MessageType;
      pluginType: string;
      chainId: number;
      pluginName?: string;
      name?: string;
      parameters?: string;
      description?: string;
    },
    { getState, dispatch }
  ) => {
    const currentState = (getState() as RootState).user;
    try {
      const { data: newPlugin } = await APIServer.post("/plugin", {
        creatorAddress: currentState.address,
        signature,
        message,
        pluginType,
        chainId,
        pluginName,
        name,
        parameters,
        description,
      });
      dispatch(addNotification({ message: "Successfully Created!", type: "success" }));
      return newPlugin;
    } catch (error: any) {
      const e = error as AxiosError;
      if (e.isAxiosError) {
        dispatch(addNotification({ message: error?.response?.data.message, type: "error" }));
      }
    }
  }
);
export const updatePlugin = createAsyncThunk(
  "user/updatePlugin",
  async (
    {
      signature,
      message,
      pluginType,
      chainId,
      pluginName,
      name,
      parameters,
      description,
      id,
    }: {
      signature: `0x${string}`;
      message: MessageType;
      pluginType: string;
      chainId: number;
      id: number;
      pluginName?: string;
      name?: string;
      parameters?: string;
      description?: string;
    },
    { getState, dispatch }
  ) => {
    const currentState = (getState() as RootState).user;
    try {
      await APIServer.put("/plugin", {
        creatorAddress: currentState.address,
        signature,
        message,
        id,
        pluginType,
        chainId,
        pluginName,
        name,
        parameters,
        description,
      });
      dispatch(addNotification({ message: "Successfully Updated!", type: "success" }));
      return {
        creatorAddress: currentState.address,
        pluginAddress: message.domain.verifyingContract,
        id,
        pluginType,
        chainId,
        pluginName,
        name,
        parameters,
        description,
      } as PluginType;
    } catch (error: any) {
      const e = error as AxiosError;
      if (e.isAxiosError) {
        dispatch(addNotification({ message: error?.response?.data.message, type: "error" }));
      }
    }
  }
);
export const deletePlugin = createAsyncThunk(
  "user/deletePlugin",
  async (
    {
      signature,
      message,
      id,
    }: {
      signature: `0x${string}`;
      message: MessageType;
      id: number;
    },
    { getState, dispatch }
  ) => {
    const currentState = (getState() as RootState).user;
    try {
      await APIServer.post("/plugin/remove", {
        creatorAddress: currentState.address,
        signature,
        message,
        id,
      });
      dispatch(addNotification({ message: "Successfully Deleted!", type: "success" }));
      return id;
    } catch (error: any) {
      const e = error as AxiosError;
      if (e.isAxiosError) {
        dispatch(addNotification({ message: error?.response?.data.message, type: "error" }));
      }
    }
  }
);

export const initUser = createAsyncThunk(
  "user/init",
  async (
    { address, chainId }: { address: `0x${string}` | undefined; chainId: number },
    { dispatch, rejectWithValue }
  ) => {
    try {
      dispatch(setPageSpinnerLoading(true));
      const [assetData, pluginData, nfts] = await Promise.all([
        APIServer.get(`/asset?ownerAddress=${address}&chainId=${chainId}`),
        APIServer.get(`/plugin?creatorAddress=${address}&chainId=${chainId}`),
        // getERC20Assets(address),
        getNfts(address), //no need for v0
      ]);
      return {
        accounts: pluginData.data
          ?.filter((plugin: PluginType) => plugin.pluginType === PluginTypesEnum.ACCOUNT)
          ?.map((account: AccountType) => {
            const temp = account;
            temp["assets"] = assetData.data?.filter((asset: Asset) => asset.accountId === account.id);
            return temp;
          }),
        plugins: pluginData.data?.filter(
          (plugin: PluginType) => plugin.pluginType !== PluginTypesEnum.ACCOUNT
        ),
        // erc20Assets,
        nfts,
      };
    } catch {
      rejectWithValue(address);
    } finally {
      dispatch(setPageSpinnerLoading(false));
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setDefaultUser: (state: UserState) => {
      state = initialState;
    },
    addAsset: (
      state: UserState,
      actions: PayloadAction<{ accountId: number; assetType: any; amount: number; chainId: number }>
    ) => {
      const accountIdx = state.accounts.findIndex(
        (account: AccountType) => account.id === actions.payload.accountId
      );
      const assetIndex = state.accounts?.[accountIdx]?.assets?.findIndex(
        (asset: Asset) => asset.assetType.addr === actions.payload.assetType.addr
      );
      if (assetIndex > -1) {
        state.accounts[accountIdx].assets[assetIndex].amount += Number(actions.payload.amount.toFixed(6));
      } else {
        const assets: Asset[] = state.accounts[accountIdx].assets;
        assets.push({
          accountId: actions.payload.accountId,
          amount: actions.payload.amount.toFixed(6),
          assetType: actions.payload.assetType,
          loanedAmount: "0",
          borrowedAmount: "0",
          collateralAmount: "0",
          chainId: actions.payload.chainId,
        });
        state.accounts[accountIdx].assets = assets;
      }
    },
    reduceAsset: (
      state: UserState,
      actions: PayloadAction<{ accountId: number; assetType: any; amount: number; chainId: number }>
    ) => {
      const accountIdx = state.accounts.findIndex(
        (account: AccountType) => account.id === actions.payload.accountId
      );
      const assetIndex = state.accounts[accountIdx].assets.findIndex(
        (asset: Asset) =>
          asset.assetType.addr === actions.payload.assetType.addr && asset.chainId === actions.payload.chainId
      );
      state.accounts[accountIdx].assets[assetIndex].amount = (Number(state.accounts[accountIdx].assets[assetIndex].amount) - actions.payload.amount).toFixed(6);
    },
  },
  extraReducers(builder) {
    builder.addCase(initUser.rejected, (state, actions) => {
      if (actions.meta.arg.address) state.address = actions.meta.arg.address;
    });
    builder.addCase(initUser.fulfilled, (state, actions) => {
      state.accounts = actions.payload?.accounts ?? [];
      state.plugins = actions.payload?.plugins ?? [];
      // state.erc20Assets = actions.payload?.erc20Assets ?? [];
      state.erc721Assets = actions.payload?.nfts?.erc721 ?? [];
      state.erc1155Assets = actions.payload?.nfts?.erc1155 ?? [];
      if (actions.meta.arg.address) state.address = actions.meta.arg.address;
    });
    builder.addCase(createNewPlugin.fulfilled, (state, actions) => {
      if (actions.payload) {
        if (actions.payload.pluginType === PluginTypesEnum.ACCOUNT) {
          state.accounts.push(actions.payload);
        } else {
          state.plugins.push(actions.payload);
        }
      }
    });
    builder.addCase(updatePlugin.fulfilled, (state, actions) => {
      if (actions.payload) {
        state.plugins = state.plugins.map((plugin) => {
          if (plugin.id === actions.payload?.id) {
            return actions.payload;
          }
          return plugin;
        });
      }
    });
    builder.addCase(deletePlugin.fulfilled, (state, actions) => {
      if (actions.payload) {
        state.plugins = state.plugins.filter((plugin) => plugin.id !== actions.payload);
      }
    });
  },
});

// Action creators are generated for each case reducer function
export const { setDefaultUser, addAsset, reduceAsset } = userSlice.actions;

export default userSlice.reducer;

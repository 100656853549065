import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  InputAdornment,
  Stack,
  TextField,
  Typography,
  Box,
  CircularProgress,
  Autocomplete,
} from "@mui/material";
import { useBalance, useChainId } from "wagmi";
import LoadingButton from "@mui/lab/LoadingButton";
import { formatUnits } from "viem";

import { useAppDispatch, useAppSelector } from "../../redux/store";
import { useNotification } from "../../hooks/useNotification";
import { useAccountManagerContract } from "../../hooks/contracts/useAccountManagerContract";
import { setAssetModalState } from "../../redux/reducers/app-slice";
import { ADDRESS_TO_PAIR, NativeToken, tokenList } from "../../helpers/constants/tokens";
import { AddressZero } from "../../helpers/constants/address";
import { symbolToAsset } from "../../helpers/utils/assets";
import SingleSelectInput from "../SelectBox/SingleSelectInput";
import { ERC1155Asset, ERC721Asset } from "../../helpers/types/basic";
export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ bgcolor: "background.paper", p: 3 }} {...other}>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 3,
            top: 3,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
// const TokenStandard = {
//   ERC20: 1,
//   ERC721: 2,
//   ERC1155: 3,
// };

export default function AccountMangeModal() {
  const userSlice = useAppSelector((state) => state.user);
  const tokenPrices = useAppSelector((state) => state.app.tokenPrices);
  const assetManageModalState = useAppSelector((state) => state.app.assetManageModal);
  const chainId = useChainId();
  const [assetAddr, setAssetAddr] = React.useState<string>("");
  const [tokenAmount, setTokenAmount] = React.useState("");
  const [actionType, setActionType] = React.useState<"supply" | "withdraw">("supply");
  const [unknownAddress, setUnknownAddress] = React.useState("");

  const [tokenType, setTokenType] = React.useState<"erc20" | "erc721" | "erc1155">("erc20");
  const [nft, setNft] = React.useState<ERC1155Asset | ERC721Asset>({
    name: "",
    address: "",
    symbol: "",
    amount: 0,
    tokenId: "",
  });

  const { displayNotification } = useNotification();
  const dispatch = useAppDispatch();
  const {
    data: tokenData,
    isFetching,
    refetch,
  } = useBalance({
    address: userSlice.address as `0x${string}`,
    token:
      assetAddr === AddressZero
        ? undefined
        : unknownAddress === ""
        ? (assetAddr as `0x${string}`)
        : (unknownAddress as `0x${string}`),
    enabled: !(assetAddr === "" && unknownAddress === ""),
  });

  const supportedTokens = React.useMemo(() => {
    return tokenList.filter((token) => token.chainId === (chainId || Number(process.env.REACT_APP_CHAIN_ID)));
  }, [chainId]);

  const erc20Balance = React.useMemo(() => {
    if (actionType === "supply") {
      return (!isFetching && tokenData && parseFloat(parseFloat(tokenData.formatted).toFixed(4))) || "---";
    } else {
      const asset = userSlice.accounts?.[assetManageModalState.accountIndex]?.assets?.find(
        (asset: any) => asset.assetType.addr.toLocaleLowerCase() === assetAddr.toLocaleLowerCase()
      );
      return (Number(asset?.amount || 0) - Number(asset?.collateralAmount || 0)).toString() || "0";
    }
  }, [actionType, assetAddr, assetManageModalState.accountIndex, isFetching, tokenData, userSlice.accounts]);

  const erc1155Balance = React.useMemo(() => {
    if (actionType === "supply") {
      return (nft as ERC1155Asset)?.amount;
    } else {
      const asset = userSlice.accounts?.[assetManageModalState.accountIndex]?.assets?.find(
        (asset: any) =>
          asset.assetType.addr.toLocaleLowerCase() === nft.address.toLocaleLowerCase() &&
          asset.assetType.tokenId === nft.tokenId
      );
      return (Number(asset?.amount || 0) - Number(asset?.collateralAmount || 0)).toString() || "0";
    }
  }, [actionType, assetManageModalState.accountIndex, nft, userSlice.accounts]);

  const { loadErc20, unloadErc20, loadNft, unloadNft, isLoading } = useAccountManagerContract();

  const handleLoad = async () => {
    if (tokenType === "erc20") {
      await handleLoadErc20();
    } else {
      await handleLoadNft();
    }
  };
  const handleUnload = async () => {
    if (tokenType === "erc20") {
      await handleUnloadErc20();
    } else {
      await handleUnloadNft();
    }
  };

  const handleLoadErc20 = async () => {
    try {
      if (!assetAddr) {
        displayNotification({ message: "Choose asset", type: "warning" });
        return;
      }
      if (!tokenData) {
        displayNotification({ message: "Invalid token", type: "error" });
        return;
      }
      if (assetManageModalState.accountIndex === undefined) {
        displayNotification({ message: "Choose account", type: "warning" });
        return;
      }
      if (Number(tokenAmount) > Number(erc20Balance)) {
        displayNotification({ message: "Invalid amount", type: "warning" });
        return;
      }
      await loadErc20(assetManageModalState.accountIndex, assetAddr, Number(tokenAmount), tokenData?.decimals);
      await refetch();
      handleClose();
    } catch (e) {
      console.error(e);
    }
  };

  const handleLoadNft = async () => {
    try {
      if (nft.address === "" && nft.tokenId === "") {
        displayNotification({ message: "Choose asset", type: "warning" });
        return;
      }
      if (assetManageModalState.accountIndex === undefined) {
        displayNotification({ message: "Choose account", type: "warning" });
        return;
      }
      if (Number(tokenAmount) > Number(erc1155Balance) && tokenType === "erc1155") {
        displayNotification({ message: "Invalid amount", type: "warning" });
        return;
      }
      if (tokenType === "erc20") return;
      await loadNft(assetManageModalState.accountIndex, nft, Number(tokenAmount), tokenType);
      setNft({ name: "", address: "", symbol: "", amount: 0, tokenId: "" });
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  const handleUnloadErc20 = async () => {
    try {
      if (!assetAddr) {
        displayNotification({ message: "Choose asset", type: "warning" });
        return;
      }
      if (!tokenData) {
        displayNotification({ message: "Invalid token", type: "error" });
        return;
      }
      if (Number(tokenAmount) > Number(erc20Balance)) {
        displayNotification({ message: "Invalid amount", type: "warning" });
        return;
      }
      if (assetManageModalState.accountIndex === undefined) {
        displayNotification({ message: "Choose your account", type: "warning" });
        return;
      }
      await unloadErc20(assetManageModalState.accountIndex, assetAddr, Number(tokenAmount), tokenData?.decimals);
      await refetch();
      handleClose();
    } catch (e) {
      console.error(e);
    }
  };

  const handleUnloadNft = async () => {
    try {
      if (nft.address === "" && nft.tokenId === "") {
        displayNotification({ message: "Choose asset", type: "warning" });
        return;
      }
      if (assetManageModalState.accountIndex === undefined) {
        displayNotification({ message: "Choose account", type: "warning" });
        return;
      }
      if (Number(tokenAmount) > Number(erc1155Balance) && tokenType === "erc1155") {
        displayNotification({ message: "Invalid amount", type: "warning" });
        return;
      }
      if (tokenType === "erc20") return;
      await unloadNft(assetManageModalState.accountIndex, nft, Number(tokenAmount), tokenType);
      setNft({ name: "", address: "", symbol: "", amount: 0, tokenId: "" });
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = () => {
    dispatch(setAssetModalState({ isOpened: false, accountIndex: 0 }));
  };

  const onTagsChange = (event: any, value: any) => {
    if (value === "ETH") {
      setAssetAddr(AddressZero);
      setUnknownAddress("");
    } else {
      setAssetAddr(symbolToAsset(value, chainId).addr);
      setUnknownAddress("");
    }
  };
  const onMaxButtonClick = () => {
    if (actionType === "supply") {
      if(tokenType === 'erc20') {
        setTokenAmount(formatUnits(tokenData?.value || 0n, tokenData?.decimals || 18));
      } else {
        setTokenAmount((nft as ERC1155Asset).amount.toString())
      }
    } else {
      const asset = userSlice.accounts?.[assetManageModalState.accountIndex]?.assets?.find(
        (asset: any) => asset.assetType.addr.toLocaleLowerCase() === assetAddr.toLocaleLowerCase()
      );
      setTokenAmount((asset?.amount || 0).toString());
    }
  };

  const onNftsChange = (event: any, value: any) => {
    setNft(value);
  };
  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={assetManageModalState.isOpened || false}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}></BootstrapDialogTitle>
        <DialogContent sx={{ bgcolor: "background.paper" }}>
          <Stack gap={3} direction={"row"}>
            <Button
              sx={{
                width: "265px",
                py: 2,
                paddingX: 5,
                borderRadius: 2,
                bgcolor: actionType === "supply" ? "primary.main" : "background.default",
                color: actionType === "supply" ? "background.default" : "white.main",
                "&:hover": {
                  bgcolor: actionType === "supply" ? "primary.main" : "",
                  color: actionType === "supply" ? "background.default" : "",
                },
              }}
              onClick={() => setActionType("supply")}
            >
              supply
            </Button>
            <Button
              sx={{
                py: 2,
                width: "265px",
                paddingX: 5,
                borderRadius: 2,
                bgcolor: actionType === "withdraw" ? "primary.main" : "background.default",
                color: actionType === "withdraw" ? "background.default" : "white.main",
                "&:hover": {
                  bgcolor: actionType === "withdraw" ? "primary.main" : "",
                  color: actionType === "withdraw" ? "background.default" : "",
                },
              }}
              onClick={() => setActionType("withdraw")}
            >
              withdraw
            </Button>
          </Stack>
          <Typography variant="body2" p={1} pt={7}>
            {actionType === "supply" ? "my wallet" : `account #${assetManageModalState.accountIndex + 1}`}
          </Typography>
          <SingleSelectInput
            placeholder="select token type"
            values={["erc20", "erc721", "erc1155"]}
            selectValue={setTokenType}
            selectedValue={tokenType}
          />
          {tokenType === "erc20" && (
            <Box bgcolor={"background.default"} p={1} borderRadius={2}>
              <Autocomplete
                freeSolo
                options={[NativeToken, ...supportedTokens].map((asset) => asset.symbol)}
                onChange={onTagsChange}
                sx={{ width: "100%", my: 1 }}
                renderOption={(props, option) => {
                  const asset = [NativeToken, ...supportedTokens].find((token) => token.symbol === option);
                  return (
                    <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                      <img
                        loading="lazy"
                        width={25}
                        height={25}
                        src={asset?.logoURI}
                        srcSet={`${asset?.img} 2x`}
                        alt={asset?.name || "unknown token"}
                      />
                      <Box>
                        <Typography variant="body1" color={"white.main"}>
                          {asset?.name}
                        </Typography>
                        <Typography variant="body2" color={"gray.main"}>
                          {asset?.symbol}
                        </Typography>
                      </Box>
                    </Box>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      fontSize: 14,
                      borderRadius: 2,
                      border: 0,
                      bgcolor: "background.default",
                      "& fieldset": {
                        borderColor: "transparent",
                        borderRadius: 2,
                        border: 0,
                      },
                      "& input": { fontSize: 14, py: 2 },
                    }}
                    placeholder="select asset or paste address"
                    onChange={(e) => {
                      setUnknownAddress(e.target.value);
                    }}
                    fullWidth
                  />
                )}
              />
              <TextField
                fullWidth
                variant="outlined"
                sx={{
                  borderRadius: 2,
                  my: 1,
                  border: 0,
                  bgcolor: "background.default",
                  "& fieldset": {
                    borderColor: "transparent",
                    borderRadius: 2,
                    border: 0,
                  },
                  "& input": { fontSize: 14, py: 1.5 },
                }}
                color="primary"
                placeholder="enter amount"
                value={tokenAmount}
                onChange={(e) => setTokenAmount(e.target.value)}
                type="number"
                InputProps={{
                  inputProps: { style: { borderRadius: 5 } },
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button onClick={onMaxButtonClick} sx={{ color: "white.main" }}>
                        max
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
              <Stack flexDirection={"row"} justifyContent={"space-between"} px={3}>
                <Typography sx={{ color: "gray.main" }}>
                  $
                  {parseFloat(
                    (
                      (tokenPrices[
                        ADDRESS_TO_PAIR[
                          unknownAddress === ""
                            ? (assetAddr as `0x${string}`)
                            : (unknownAddress as `0x${string}`)
                        ]
                      ] || 0) * parseFloat(tokenAmount)
                    ).toFixed(2)
                  ) || 0}
                </Typography>
                <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
                  <Typography sx={{ color: "gray.main" }}>
                    {`balance ${erc20Balance} ${tokenData?.symbol}`}
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          )}
          {tokenType !== "erc20" && (
            <Box bgcolor={"background.default"} p={1} borderRadius={2}>
              <Autocomplete
                options={tokenType === "erc1155" ? userSlice.erc1155Assets : userSlice.erc721Assets}
                onChange={onNftsChange}
                sx={{ width: "100%", my: 1 }}
                getOptionLabel={(option) => option?.name + "(" + option?.tokenId + ")"}
                isOptionEqualToValue={(option, value) => true}
                renderOption={(props, option) => {
                  return (
                    <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                      <Box>
                        <Typography variant="body1" color={"white.main"}>
                          {option?.name}
                        </Typography>
                        <Typography variant="body2" color={"gray.main"}>
                          {option?.symbol}
                        </Typography>
                      </Box>
                      <Typography variant="body2" color={"gray.main"}>
                        {option?.tokenId}
                      </Typography>
                    </Box>
                  );
                }}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      sx={{
                        fontSize: 14,
                        borderRadius: 2,
                        border: 0,
                        bgcolor: "background.default",
                        "& fieldset": {
                          borderColor: "transparent",
                          borderRadius: 2,
                          border: 0,
                        },
                        "& input": { fontSize: 14, py: 2 },
                      }}
                      placeholder="select asset or paste token id"
                      fullWidth
                    />
                  );
                }}
              />
              {tokenType === "erc1155" && (
                <>
                  <TextField
                    fullWidth
                    variant="outlined"
                    sx={{
                      borderRadius: 2,
                      my: 1,
                      border: 0,
                      bgcolor: "background.default",
                      "& fieldset": {
                        borderColor: "transparent",
                        borderRadius: 2,
                        border: 0,
                      },
                      "& input": { fontSize: 14, py: 1.5 },
                    }}
                    color="primary"
                    placeholder="enter amount"
                    value={tokenAmount}
                    onChange={(e) => setTokenAmount(e.target.value)}
                    type="number"
                    InputProps={{
                      inputProps: { style: { borderRadius: 5 } },
                      endAdornment: (
                        <InputAdornment position="end">
                          <Button onClick={onMaxButtonClick} sx={{ color: "white.main" }}>
                            max
                          </Button>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Stack flexDirection={"row"} justifyContent={"flex-end"} px={3}>
                    <Typography sx={{ color: "gray.main" }}>
                      {`balance ${erc1155Balance} ${nft?.symbol}`}
                    </Typography>
                  </Stack>
                </>
              )}
            </Box>
          )}
          <Box mt={3}>
            <LoadingButton
              fullWidth
              loading={isLoading}
              loadingIndicator={<CircularProgress color="inherit" size={28} />}
              sx={{
                py: 2,
                paddingX: 5,
                borderRadius: 2,
                bgcolor: "primary.main",
                color: "background.default",
                "&:hover": {
                  bgcolor: "background.default",
                  color: "white.main",
                },
              }}
              onClick={() => (actionType === "supply" ? handleLoad() : handleUnload())}
            >
              {actionType}
            </LoadingButton>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

import { useDispatch } from "react-redux";
import { NotificationState, addNotification, removeNotification } from "../redux/reducers/notification-slice";

export const useNotification = () => {
  const dispatch = useDispatch();

  const displayNotification = (notification: NotificationState) => {
    dispatch(addNotification(notification));
  };

  const clearNotification = () => {
    dispatch(removeNotification());
  };

  return { displayNotification, clearNotification } as const;
};
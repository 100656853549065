import axios from "axios";
import { Activity, AggregateInfo, Agreement, OrderInfo, Order } from "../types/response";
import { OrderTypes } from "../enums";

export const APIServer = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const getOrderInfo = async (chainId: number): Promise<OrderInfo[]> => {
  const { data } = await APIServer.get(`/order/info?chainId=${chainId}`);
  return data;
};

export const getActivities = async (chainId: number): Promise<Activity[]> => {
  const { data } = await APIServer.get(`/order/activity?chainId=${chainId}`);
  return data;
};

export const getAggregateInfo = async (chainId: number, assetAddress?: string): Promise<AggregateInfo> => {
  const url = assetAddress
    ? `/order/aggregate?assetAddress=${assetAddress}&chainId=${chainId}`
    : `/order/aggregate?chainId=${chainId}`;
  const { data } = await APIServer.get(url);
  return data;
};

export const getOrderList = async (
  ordererAddress: string,
  chainId: number
): Promise<{ count: number; orders: Order[] }> => {
  const { data } = await APIServer.get(`/order?ordererAddress=${ordererAddress}&chainId=${chainId}`);
  return data;
};

export const getAgreements = async (
  ordererAddress: string | undefined,
  chainId: number
): Promise<Agreement[]> => {
  const url = ordererAddress
    ? `/order/agreements?ordererAddress=${ordererAddress}&chainId=${chainId}`
    : `/order/agreements?chainId=${chainId}`;
  const { data } = await APIServer.get(url);
  return data;
};

export const getOrderDetail = async (
  assetAddress: string,
  orderType: OrderTypes,
  chainId: number
): Promise<[Order[], number[]]> => {
  const { data } = await APIServer.get(
    `/order/detail?assetAddress=${assetAddress}&orderType=${orderType}&chainId=${chainId}`
  );
  return data;
};

export const getOneOrder = async (
  assetAddress: string,
  hash: string,
  chainId: number | undefined,
  tokenId: string,
): Promise<[Order, number]> => {
  const url = chainId
    ? `/order/${assetAddress}/${hash}?chainId=${chainId}&tokenId=${tokenId}`
    : `/order/${assetAddress}/${hash}&tokenId=${tokenId}`;
  const { data } = await APIServer.get(url);
  return data;
};

export const getOneAgreement = async (hash: string, chainId: number | undefined): Promise<Agreement> => {
  const url = chainId ? `/order/agreement/${hash}?chainId=${chainId}` : `/order/agreement/${hash}`;
  const { data } = await APIServer.get(url);
  return data;
};

import { useCallback, useState } from "react";
import { readContract } from "@wagmi/core";
import { useNetwork } from "wagmi";
import { decodeAbiParameters, parseAbiParameters } from "viem";
import { AgreementStruct } from "../../helpers/abis";
import { useAppSelector } from "../../redux/store";
import { Agreement } from "../../helpers/types/response";
import { AssessorAbi } from "../../helpers/abis/Assessor.abi";
import { unpackDataField } from "../../helpers/utils/format";

export const useAssessorContract = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { chain } = useNetwork();
  const userSlice = useAppSelector((state) => state.user);

  const getCost = useCallback(
    async (agreement: Agreement) => {
      if (!userSlice.address) throw new Error("Undefined address");
      if (!chain) {
        throw new Error("Undefined Chain");
      }

      try {
        setIsLoading(true);
        const cost = await readContract({
          address: agreement.collector.pluginAddress,
          abi: AssessorAbi,
          //@ts-ignore
          functionName: "getCost",
          args: [
            decodeAbiParameters(
              parseAbiParameters(AgreementStruct),
              unpackDataField(agreement.agreementData) as `0x${string}`
            )[0],
          ],
        });
        return cost;
      } catch (error: any) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [chain, userSlice.address]
  );

  return { getCost, isLoading };
};

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { addNotification } from "./notification-slice";
import { APIServer } from "../../helpers/utils/api";
import { RootState } from "../store";
import { BlueprintDataType, MessageType } from "../../helpers/types/basic";
import { Order } from "../../helpers/types/response";

export type OrderCreationForm = {
  [key: string]: any;
};
export interface OrderState {
  step: number;
  orderCreationForm: OrderCreationForm;
  orderFillModalState: { isOpened: boolean; orderData: Order; available: number };
}

const initialState: OrderState = {
  step: 1,
  orderCreationForm: {} as OrderCreationForm,
  orderFillModalState: { isOpened: false, orderData: {} as Order, available: 0 },
};

export const createNewOrder = createAsyncThunk(
  "order/createNewOrder",
  async (
    {
      blueprintData,
      message,
      chainId,
    }: { blueprintData: BlueprintDataType; message: MessageType; chainId: number },
    { getState, dispatch, rejectWithValue }
  ) => {
    const currentState = getState() as RootState;
    try {
      const { data: newOrder } = await APIServer.post("/order", {
        blueprintData,
        data: currentState.order.orderCreationForm,
        message,
        chainId,
      });
      dispatch(addNotification({ message: "Successfully Created!", type: "success" }));
      return newOrder;
    } catch (error) {
      dispatch(addNotification({ message: "Failed to create new Order", type: "error" }));
      rejectWithValue(error);
    }
  }
);

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload;
    },
    fillCreationForm: (state, action: PayloadAction<[string, any]>) => {
      state.orderCreationForm[action.payload[0]] = action.payload[1];
    },
    setOrderFillModalState: (
      state,
      action: PayloadAction<{ isOpened: boolean; orderData: Order; available: number }>
    ) => {
      state.orderFillModalState = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(createNewOrder.rejected, (state, actions) => {
      console.error(actions.payload);
    });
    builder.addCase(createNewOrder.fulfilled, (state, actions) => {
      state.orderCreationForm = {} as OrderCreationForm;
      state.step = 1;
    });
  },
});

// Action creators are generated for each case reducer function
export const { setStep, fillCreationForm, setOrderFillModalState } = orderSlice.actions;

export default orderSlice.reducer;

import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useOrderDetail } from "../../hooks/apis";
import { OrderTypes, PageName } from "../../helpers/enums";
import MarketInfo from "../../components/Markets/MarketInfo";
import OrderDetailTable from "../../components/Markets/OrderDetailTable";
import PageMeta from "../../layout/PageMeta";
import { useNetwork } from "wagmi";

function Market() {
  const { chain } = useNetwork();
  const params = useParams();
  const [orderType, setOrderType] = useState(OrderTypes.LEND);
  const { isLoading, orderDetail, availableAmounts } = useOrderDetail(
    params.assetAddress,
    orderType,
    chain?.id
  );

  return (
    <>
      <PageMeta pageName={PageName.MARKETS} />
      <MarketInfo chainId={chain?.id} />
      <OrderDetailTable
        orderDetail={orderDetail}
        isLoading={isLoading}
        orderType={orderType}
        setOrderType={setOrderType}
        availableAmounts={availableAmounts}
      />
    </>
  );
}

export default Market;

import * as React from "react";
import Accordion from "@mui/material/Accordion";
import Container from "@mui/material/Container";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";

import { useAppDispatch, useAppSelector } from "../../../redux/store";
import AssetsTable from "./AssetsTable";
import { setAssetModalState } from "../../../redux/reducers/app-slice";
import LoansTable from "./LoansTable";
import { Order } from "../../../helpers/types/response";
import { fillCreationForm, setStep } from "../../../redux/reducers/order-slice";
import { useNavigate } from "react-router-dom";
import OrdersTable from "./OrdersTable";
import { useAgreements } from "../../../hooks/apis/useAgreements";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

type Props = {
  orders: Order[] | undefined;
  chainId: number | undefined;
};

function AccountsInfo({ orders, chainId }: Props) {
  const accounts = useAppSelector((state) => state.user.accounts);
  const userAddress = useAppSelector((state) => state.user.address);
  const [expanded, setExpanded] = React.useState<number | false>(false);
  const [value, setValue] = React.useState(0);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { agreements, isLoading: apiLoading } = useAgreements(userAddress, chainId);

  const handleChange = (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setValue(0);
    setExpanded(isExpanded ? panel : false);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleClickOpenAssetModal = (accountIndex: number) => {
    dispatch(setAssetModalState({ isOpened: true, accountIndex }));
  };
  const handleClickCreateOrder = (accountId: number) => {
    dispatch(fillCreationForm(["ordererId", accountId]));
    dispatch(setStep(1));
    navigate("/create");
  };

  const filteredLoans = React.useCallback(
    (accountId: number) => {
      return agreements?.filter(
        (agreement) => agreement.lenderId === accountId || agreement.borrowerId === accountId
      );
    },
    [agreements]
  );
  const filteredOrders = React.useCallback(
    (accountId: number) => {
      if (!orders) {
        return [];
      }
      return orders?.filter((order) => order.ordererId === accountId);
    },
    [orders]
  );
  return (
    <Container maxWidth="xl" sx={{ py: 5 }}>
      {accounts.length !== 0 &&
        accounts.map((account, index) => (
          <Accordion
            key={account.id}
            expanded={expanded === index}
            sx={{
              borderRadius: "8px !important",
              my: 2,
              "&:before": {
                display: "none",
              },
            }}
            onChange={handleChange(index)}
            elevation={0}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              {index === expanded && <Typography color={"primary"}> &gt;&nbsp;</Typography>}
              <Typography color={index === expanded ? "primary" : "white.main"}>account #{index + 1}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Button
                sx={{
                  p: 2,
                  mr: 2,
                  borderRadius: 2,
                  color: "white.main",
                  bgcolor: "background.default",
                }}
                onClick={() => handleClickOpenAssetModal(index)}
              >
                add / remove asset
              </Button>
              <Button
                sx={{
                  p: 2,
                  borderRadius: 2,
                  color: "white.main",
                  bgcolor: "background.default",
                }}
                startIcon={<AddIcon />}
                onClick={() => handleClickCreateOrder(account.id)}
              >
                create market
              </Button>
              <Box sx={{ width: "100%" }}>
                <Box>
                  <Tabs
                    TabIndicatorProps={{ sx: { backgroundColor: "transparent" } }}
                    value={value}
                    textColor="primary"
                    onChange={handleTabChange}
                    aria-label="basic tabs example"
                  >
                    <Tab
                      icon={value === 0 ? <>&gt;&nbsp;</> : <></>}
                      iconPosition="start"
                      label="assets"
                      {...a11yProps(0)}
                    />
                    <Tab
                      icon={value === 1 ? <>&gt;&nbsp;</> : <></>}
                      iconPosition="start"
                      label="loans"
                      {...a11yProps(1)}
                    />
                    <Tab
                      icon={value === 2 ? <>&gt;&nbsp;</> : <></>}
                      iconPosition="start"
                      label="markets"
                      {...a11yProps(2)}
                    />
                  </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                  <AssetsTable assets={account?.assets} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  {orders && (
                    <LoansTable
                      agreements={filteredLoans(account.id)}
                      apiLoading={apiLoading}
                      accountId={account.id}
                      chainId={chainId}
                    />
                  )}
                </TabPanel>
                <TabPanel value={value} index={2}>
                  {orders && <OrdersTable orders={filteredOrders(account.id)} />}
                </TabPanel>
              </Box>
            </AccordionDetails>
          </Accordion>
        ))}
    </Container>
  );
}
export default AccountsInfo;

import { useState, useEffect } from "react";
import { Order } from "../../helpers/types/response";
import { getOrderList } from "../../helpers/utils/api";

export const useOrderList = (
  ordererAddress: string | undefined,
  chainId = Number(process.env.REACT_APP_CHAIN_ID)
) => {
  const [orderList, setOrderList] = useState<Order[]>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        if (ordererAddress) {
          setIsLoading(true);
          const orderList = await getOrderList(ordererAddress, chainId);
          setOrderList(orderList?.orders);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [chainId, ordererAddress]);

  return { orderList, isLoading };
};

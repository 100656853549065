import React from "react";
import Jazzicon from "react-jazzicon";

type Props = {
  address: string;
  size: number;
};

const RandomAvatar = ({ address, size }: Props) => {
  return (
    <Jazzicon
      paperStyles={{ width: `${size}px`, height: `${size}px` }}
      svgStyles={{
        transform: `scale(${size / 100})`,
        transformOrigin: "top left",
      }}
      diameter={100}
      seed={parseInt(address.slice(2, 10), 16)}
    />
  );
};

export default RandomAvatar;
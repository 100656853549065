import React from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "./layout";
import CreateOrder from "./pages/create";
import Account from "./pages/account";
import Dashboard from "./pages/dashboard";
import Market from "./pages/market";
import Plugin from "./pages/plugin";
import Activity from "./pages/activity";
import OrderDetail from "./pages/market/OrderDetail";
import AgreementDetail from "./pages/market/AgreementDetail";
import PluginDetail from "./pages/plugin/PluginDetail";
import Liquidation from "./pages/liquidation";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Dashboard />} />
        <Route path="markets">
          <Route path="agreement/:agreementHash" element={<AgreementDetail />} />
          <Route path=":assetAddress" element={<Market />} />
          <Route path=":assetAddress/:orderHash" element={<OrderDetail />} />
        </Route>
        <Route path="plugin">
          <Route index element={<Plugin />} />
          <Route path=":pluginId" element={<PluginDetail />} />
        </Route>
        <Route path="create" element={<CreateOrder />} />
        <Route path="activity" element={<Activity />} />
        <Route path="liquidation" element={<Liquidation />} />
        <Route path="account" element={<Account />} />
        <Route path="*" element={<>page not found</>} />
      </Route>
    </Routes>
  );
}

export default App;

import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Typography,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { getAssetData } from "../../helpers/utils/assets";
import RandomAvatar from "../RandomAvatar";
import { Token } from "../../helpers/types/basic";
import { useAppDispatch } from "../../redux/store";
import { setActiveMarket } from "../../redux/reducers/app-slice";
import { BootstrapTooltip } from "../Tooltip";
import { OrderInfo } from "../../helpers/types/response";
import untypedData from "../../helpers/data/tooltip-data.json";
import { formatNumber } from "../../helpers/utils/format";
const tooltipData: any = untypedData;

type Props = {
  orderInfo: OrderInfo[];
  isLoading: boolean;
};

function MarketTable({ orderInfo, isLoading }: Props) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleClick = (assetData: Token | undefined, totalBorrowed: string, totalSupplied: string) => {
    dispatch(
      setActiveMarket({
        assetData,
        totalBorrowed: Number(totalBorrowed),
        totalSupplied: Number(totalSupplied),
      })
    );
    navigate(`/markets/${assetData?.address}`);
  };

  return (
    <Container maxWidth="xl" sx={{ py: 5 }}>
      <TableContainer component={Paper} sx={{ borderRadius: 2 }} elevation={0}>
        <Table sx={{ minWidth: 650 }} aria-label="market table">
          <TableHead>
            <TableRow sx={{ "td,th": { border: 0 } }}>
              <TableCell sx={{ fontSize: 14 }}>asset</TableCell>
              <TableCell sx={{ fontSize: 14 }}>total supplied</TableCell>
              <TableCell sx={{ fontSize: 14 }}>total borrowed</TableCell>
              <TableCell sx={{ fontSize: 14 }}>collateral</TableCell>
              <TableCell sx={{ fontSize: 14, display: "flex", gap: 0.5 }}>
                position
                <BootstrapTooltip title={tooltipData["position"]} arrow>
                  <HelpOutlineIcon fontSize={"inherit"} sx={{ cursor: "pointer" }} />
                </BootstrapTooltip>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow sx={{ "td,th": { border: 0 } }}>
                <TableCell>Loading</TableCell>
              </TableRow>
            ) : (
              <>
                {orderInfo.length ? (
                  orderInfo.map((order) => {
                    const assetData = getAssetData(order.asset, order.chainId);
                    return (
                      <TableRow
                        hover
                        key={order.id}
                        sx={{ td: { border: 0 }, textDecoration: "none", cursor: "pointer" }}
                        onClick={() => handleClick(assetData, order.totalBorrowed, order.totalSupplied)}
                      >
                        <TableCell scope="row" sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                          <img src={assetData?.img} width={40} height={40} alt={assetData?.name} />
                          <Box>
                            <Typography noWrap>{assetData?.name}</Typography>
                            <Typography variant="body2" color={"grey"}>
                              {assetData?.symbol}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ fontSize: 16 }}>
                          {formatNumber(Number(order.totalSupplied))} {assetData?.symbol}
                        </TableCell>
                        <TableCell sx={{ fontSize: 16 }}>
                          {formatNumber(Number(order.totalBorrowed))} {assetData?.symbol}
                        </TableCell>
                        <TableCell>
                          <Box sx={{ display: "flex" }}>
                            {order.collateralAssets.length
                              ? order.collateralAssets?.map((asset, i) => {
                                  const data = getAssetData(asset, order.chainId);
                                  return (
                                    <BootstrapTooltip key={i} title={data?.name} arrow>
                                      <img
                                        style={{
                                          translate: -(8 * i),
                                          borderRadius: "50%",
                                          cursor: "pointer",
                                        }}
                                        width={32}
                                        src={data?.img}
                                        alt="coll_image"
                                      />
                                    </BootstrapTooltip>
                                  );
                                })
                              : ""}
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box sx={{ display: "flex" }}>
                            {order.terminals.length
                              ? order.terminals?.map((terminal, i) => (
                                  <BootstrapTooltip key={i} title={terminal?.pluginName} arrow>
                                    <Box
                                      component={"span"}
                                      sx={{
                                        cursor: "pointer",
                                        borderRadius: "50%",
                                        height: 34,
                                        translate: -(10 * i),
                                        border: "1.5px solid transparent",
                                        "&:hover": {
                                          zIndex: 2,
                                          borderColor: "primary.main",
                                        },
                                      }}
                                    >
                                      <RandomAvatar address={terminal.pluginAddress} size={32} />
                                    </Box>
                                  </BootstrapTooltip>
                                ))
                              : ""}
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell sx={{ px: 2 }}>no markets</TableCell>
                  </TableRow>
                )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}

export default MarketTable;

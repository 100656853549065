import { createTheme } from "@mui/material/styles";
import type {} from "@mui/lab/themeAugmentation";

declare module "@mui/material/styles" {
  interface Palette {
    white: Palette["primary"];
    black: Palette["primary"];
    gray: Palette["primary"];
    red: Palette["primary"];
    green: Palette["primary"];
  }
  interface PaletteOptions {
    white: PaletteOptions["primary"];
    black: PaletteOptions["primary"];
    gray: PaletteOptions["primary"];
    red: PaletteOptions["primary"];
    green: PaletteOptions["primary"];
  }
}

export const muiTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#00A1FF",
    },
    secondary: {
      main: "#FFF288",
    },
    background: {
      default: "#10161F",
      paper: "#1B2433",
    },
    white: { main: "#F9FFF8" },
    black: { main: "#000", light: "#0A0E14" },
    gray: { main: "#636363", light: "#d1d5db", dark: "#0d111c" },
    red: { main: "#ef4444", light: "#fca5a5", dark: "#b91c1c" },
    green: { main: "#22c55e", light: "#86efac", dark: "#15803d" },
  },
  typography: {
    fontFamily: "JetBrains Mono",
    button: {
      textTransform: "none",
    },
  },
});

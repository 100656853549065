import { useCallback, useState } from "react";
import { writeContract, waitForTransaction, readContract } from "@wagmi/core";
import { useNetwork } from "wagmi";
import {
  encodeAbiParameters,
  getAddress,
  parseAbiParameters,
  parseEther,
  parseUnits,
} from "viem";
import { AccountManagerABI, BeanstalkAbi, ERC1155ABI, ERC20ABI, ERC721ABI } from "../../helpers/abis";
import {
  AccountManagerAddress,
  AddressZero,
  BeanstalkAddress,
  HashZero,
  WETH_ADDRESS,
} from "../../helpers/constants/address";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { addAsset, reduceAsset } from "../../redux/reducers/user-slice";
import { useNotification } from "../useNotification";
import { ERC1155Asset, ERC721Asset } from "../../helpers/types/basic";
import { getBeanAddress } from "../../helpers/utils/assets";
import { AssetParameters } from "../../config/parameter-abis";

export const useAccountManagerContract = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { displayNotification } = useNotification();
  const { chain } = useNetwork();
  const dispatch = useAppDispatch();
  const userSlice = useAppSelector((state) => state.user);

  const loadErc20 = useCallback(
    async (accountIdx: number, assetAddr: string, tokenAmount: number, decimal: number) => {
      if (!userSlice.address) throw new Error("Undefined address");
      if (!chain) {
        throw new Error("Undefined Chain");
      }
      let value: bigint | undefined;
      let addr: string;
      if (assetAddr === AddressZero) {
        addr = WETH_ADDRESS[chain.id];
        value = parseEther(`${tokenAmount}`);
      } else {
        addr = assetAddr;
        value = 0n;
      }
      const amount = parseUnits(`${tokenAmount}`, 18);
      const selectedAccount = userSlice.accounts[accountIdx];
      if (!selectedAccount) {
        throw new Error("Not found account");
      }
      const asset = {
        standard: 1,
        addr: addr as `0x${string}`,
        decimals: decimal,
        tokenId: 0n,
        data: HashZero,
      };
      try {
        setIsLoading(true);
        if (assetAddr !== AddressZero) {
          const data = await readContract({
            address: addr as `0x${string}`,
            abi: ERC20ABI,
            functionName: "allowance",
            args: [userSlice.address, AccountManagerAddress[chain.id]],
          });
          if (data < amount) {
            const { hash: approveHash } = await writeContract({
              address: addr as `0x${string}`,
              abi: ERC20ABI,
              functionName: "approve",
              args: [AccountManagerAddress[chain.id], parseUnits(tokenAmount.toString(), asset.decimals)],
            });
            await waitForTransaction({ hash: approveHash });
          }
        }
        const { hash: loadHash } = await writeContract({
          address: AccountManagerAddress[chain.id],
          abi: AccountManagerABI,
          functionName: "loadFromUser",
          args: [
            encodeAbiParameters(parseAbiParameters(AssetParameters), [
              [asset.standard, asset.addr, asset.decimals, asset.tokenId, asset.data],
            ]),
            amount,
            selectedAccount.parameters,
          ],
          value,
        });
        await waitForTransaction({ hash: loadHash });
        dispatch(
          addAsset({
            accountId: selectedAccount.id,
            assetType: asset,
            amount: tokenAmount,
            chainId: chain.id,
          })
        );
        displayNotification({ message: "Successfully Loaded!", type: "success" });
      } catch (error: any) {
        console.error(error);
        displayNotification({ message: error.message, type: "error" });
      } finally {
        setIsLoading(false);
      }
    },
    [chain, dispatch, displayNotification, userSlice.accounts, userSlice.address]
  );

  const unloadErc20 = useCallback(
    async (accountIdx: number, addr: string, tokenAmount: number, decimal: number) => {
      if (!chain) {
        throw new Error("Undefined Chain");
      }
      const amount = parseUnits(`${tokenAmount}`, 18);
      const selectedAccount = userSlice.accounts[accountIdx];
      if (!selectedAccount) {
        throw new Error("Not found account");
      }
      const asset = {
        standard: 1,
        addr: addr as `0x${string}`,
        decimals: decimal,
        tokenId: 0n,
        data: HashZero,
      };
      try {
        setIsLoading(true);
        const { hash } = await writeContract({
          address: AccountManagerAddress[chain.id],
          abi: AccountManagerABI,
          functionName: "unloadToUser",
          args: [
            encodeAbiParameters(parseAbiParameters("(uint8, address, uint8, uint256, bytes)"), [
              [asset.standard, asset.addr, asset.decimals, asset.tokenId, asset.data],
            ]),
            amount,
            selectedAccount.parameters,
          ],
        });
        await waitForTransaction({ hash });
        dispatch(
          reduceAsset({
            accountId: selectedAccount.id,
            assetType: asset,
            amount: tokenAmount,
            chainId: chain.id,
          })
        );
        displayNotification({ message: "Successfully Unloaded!", type: "success" });
      } catch (error: any) {
        displayNotification({ message: error.message, type: "error" });
      } finally {
        setIsLoading(false);
      }
    },
    [chain, dispatch, displayNotification, userSlice.accounts]
  );

  const loadNft = useCallback(
    async (
      accountIdx: number,
      nft: ERC1155Asset | ERC721Asset,
      tokenAmount: number,
      tokenType: "erc721" | "erc1155"
    ) => {
      if (!userSlice.address) throw new Error("Undefined address");
      if (!chain) {
        throw new Error("Undefined Chain");
      }
      const selectedAccount = userSlice.accounts[accountIdx];
      if (!selectedAccount) {
        throw new Error("Not found account");
      }
      const amount = parseUnits(`${tokenAmount}`, 18);
      const asset = {
        standard: tokenType === "erc1155" ? 3 : 2,
        addr: getAddress(nft.address),
        decimals: 0,
        tokenId: BigInt(nft.tokenId),
        data: HashZero,
      };
      try {
        setIsLoading(true);
        if (tokenType === "erc721") {
          const approvedAddr = await readContract({
            address: asset.addr as `0x${string}`,
            abi: ERC721ABI,
            functionName: "getApproved",
            args: [asset.tokenId],
          });
          if (approvedAddr !== AccountManagerAddress[chain.id]) {
            const { hash: approveHash } = await writeContract({
              address: asset.addr as `0x${string}`,
              abi: ERC721ABI,
              functionName: "approve",
              args: [AccountManagerAddress[chain.id], asset.tokenId],
            });
            await waitForTransaction({ hash: approveHash });
          }
        } else {
          // Beanstalk approval
          if (asset.addr === BeanstalkAddress) {
            const tokenAddress = getBeanAddress(asset.tokenId);
            const approvedAmount = await readContract({
              address: asset.addr as `0x${string}`,
              abi: BeanstalkAbi,
              functionName: "depositAllowance",
              args: [userSlice.address, AccountManagerAddress[chain.id], tokenAddress],
            });
            if (approvedAmount < amount) {
              const { hash: approveHash } = await writeContract({
                address: asset.addr as `0x${string}`,
                abi: BeanstalkAbi,
                functionName: "increaseDepositAllowance",
                args: [AccountManagerAddress[chain.id], tokenAddress, amount - approvedAmount],
              });
              await waitForTransaction({ hash: approveHash });
            }
          } else {
            // standard 1155 approval
            const isApproved = await readContract({
              address: asset.addr as `0x${string}`,
              abi: ERC1155ABI,
              functionName: "isApprovedForAll",
              args: [userSlice.address, AccountManagerAddress[chain.id]],
            });
            if (!isApproved) {
              const { hash: approveHash } = await writeContract({
                address: asset.addr as `0x${string}`,
                abi: ERC1155ABI,
                functionName: "setApprovalForAll",
                args: [AccountManagerAddress[chain.id], true],
              });
              await waitForTransaction({ hash: approveHash });
            }
          }
        }
        const { hash: loadHash } = await writeContract({
          address: AccountManagerAddress[chain.id],
          abi: AccountManagerABI,
          functionName: "loadFromUser",
          args: [
            encodeAbiParameters(parseAbiParameters("(uint8, address, uint8, uint256, bytes)"), [
              [asset.standard, asset.addr, asset.decimals, asset.tokenId, asset.data],
            ]),
            amount,
            selectedAccount.parameters,
          ],
          value: 0n,
        });
        await waitForTransaction({ hash: loadHash });
        dispatch(
          addAsset({
            accountId: selectedAccount.id,
            assetType: asset,
            amount: tokenAmount,
            chainId: chain.id,
          })
        );
        displayNotification({ message: "Successfully Loaded!", type: "success" });
      } catch (error: any) {
        console.error(error);
        displayNotification({ message: error.message, type: "error" });
      } finally {
        setIsLoading(false);
      }
    },
    [chain, dispatch, displayNotification, userSlice.accounts, userSlice.address]
  );

  const unloadNft = useCallback(
    async (
      accountIdx: number,
      nft: ERC1155Asset | ERC721Asset,
      tokenAmount: number,
      tokenType: "erc721" | "erc1155"
    ) => {
      if (!chain) {
        throw new Error("Undefined Chain");
      }
      const selectedAccount = userSlice.accounts[accountIdx];
      if (!selectedAccount) {
        throw new Error("Not found account");
      }
      const asset = {
        standard: tokenType === "erc1155" ? 3 : 2,
        addr: nft.address as `0x${string}`,
        decimals: 0,
        tokenId: BigInt(nft.tokenId),
        data: HashZero,
      };
      try {
        setIsLoading(true);
        const { hash } = await writeContract({
          address: AccountManagerAddress[chain.id],
          abi: AccountManagerABI,
          functionName: "unloadToUser",
          args: [
            encodeAbiParameters(parseAbiParameters("(uint8, address, uint8, uint256, bytes)"), [
              [asset.standard, asset.addr, asset.decimals, asset.tokenId, asset.data],
            ]),
            BigInt(tokenAmount),
            selectedAccount.parameters,
          ],
        });
        await waitForTransaction({ hash });
        dispatch(
          reduceAsset({
            accountId: selectedAccount.id,
            assetType: asset,
            amount: tokenAmount,
            chainId: chain.id,
          })
        );
        displayNotification({ message: "Successfully Unloaded!", type: "success" });
      } catch (error: any) {
        displayNotification({ message: error.message, type: "error" });
      } finally {
        setIsLoading(false);
      }
    },
    [chain, dispatch, displayNotification, userSlice.accounts]
  );

  return { loadErc20, unloadErc20, loadNft, unloadNft, isLoading };
};

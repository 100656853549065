import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import ResponsiveAppBar from "./header";
import { getSocket } from "../helpers/utils/socket";
import { CoinbaseTokenPricePairs } from "../helpers/constants/tokens";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { setTokenPrices } from "../redux/reducers/app-slice";
import TextBanner from "./textBanner";
import PageSpinner from "../components/PageSpinner";
import OrderFillModal from "../components/Markets/OrderFillModal";

function Layout() {
  const isAppLoading = useAppSelector((state) => state.app.appLoading);
  const dispatch = useAppDispatch();
  useEffect(() => {
    const socket = getSocket();
    for (const pair of CoinbaseTokenPricePairs) {
      socket.on(`latest-price-${pair}`, (data) => {
        dispatch(setTokenPrices({ [pair]: data }));
      });
    }
    return () => {
      for (const pair of CoinbaseTokenPricePairs) {
        socket.off(`latest-price-${pair}`);
      }
    };
  }, [dispatch]);
  return (
    <>
      <TextBanner />
      <ResponsiveAppBar />
      {isAppLoading ? <PageSpinner /> : <Outlet />}
      <OrderFillModal />
    </>
  );
}

export default Layout;

import React, { useMemo } from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { useAppSelector } from "../../redux/store";
import { Box, Container, Paper } from "@mui/material";
import { PluginTypesEnum } from "../../helpers/enums";

function PluginInfo() {
  const userSlice = useAppSelector((state) => state.user);

  const totalCounts = useMemo(() => {
    return userSlice.accounts.length + userSlice.plugins.length;
  }, [userSlice.accounts.length, userSlice.plugins.length]);

  const pluginCounts = useMemo(() => {
    return userSlice.plugins.reduce((acc: any, plugin) => {
      if (acc[plugin.pluginType]) {
        acc[plugin.pluginType]++;
      } else {
        acc[plugin.pluginType] = 1;
      }
      return acc;
    }, {});
  }, [userSlice.plugins]);

  return (
    <Paper elevation={0}>
      <Container maxWidth="xl">
        <Stack
          px={{ sx: 2, md: 3, lg: 4, xl: 5 }}
          py={2}
          flexDirection={"row"}
          gap={2}
          flexWrap={"wrap"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Box>
            <Typography color="grey">plugins</Typography>
            <Typography variant="h6" color="white">
              {totalCounts}
            </Typography>
          </Box>
          <Box>
            <Typography color="grey">account</Typography>
            <Typography variant="h6" color="white">
              {userSlice.accounts.length || "--"}
            </Typography>
          </Box>
          <Box>
            <Typography color="grey">assessor</Typography>
            <Typography variant="h6" color="white">
              {pluginCounts[PluginTypesEnum.ASSESSOR] || "--"}
            </Typography>
          </Box>
          <Box>
            <Typography color="grey">oracle</Typography>
            <Typography variant="h6" color="white">
              {pluginCounts[PluginTypesEnum.ORACLE] || "--"}
            </Typography>
          </Box>
          <Box>
            <Typography color="grey">liquidation</Typography>
            <Typography variant="h6" color="white">
              {pluginCounts[PluginTypesEnum.LIQUIDATION] || "--"}
            </Typography>
          </Box>
          <Box>
            <Typography color="grey">position</Typography>
            <Typography variant="h6" color="white">
              {pluginCounts[PluginTypesEnum.POSITION] || "--"}
            </Typography>
          </Box>
        </Stack>
      </Container>
    </Paper>
  );
}

export default PluginInfo;

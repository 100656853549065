import React from "react";
import { Button, CircularProgress, Stack } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useChainId } from "wagmi";
import LoadingButton from "@mui/lab/LoadingButton";

import { useAppDispatch, useAppSelector } from "../../redux/store";
import { createNewOrder, setStep } from "../../redux/reducers/order-slice";
import { useWeb3Modal } from "@web3modal/react";
import { useNavigate } from "react-router-dom";
import { useBlueprintData, useOrderData } from "../../hooks/contracts/useParameters";

type Props = {
  step?: number;
  disabled: boolean;
};

function ActionButtons({ step = 1, disabled }: Props) {
  const userAddress = useAppSelector((state) => state.user.address);
  const chainId = useChainId();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { open } = useWeb3Modal();

  const { orderData } = useOrderData(step);
  const { getBlueprintData, isLoading } = useBlueprintData(chainId);

  const handleClick = async (i: number) => {
    if (userAddress) {
      dispatch(setStep(step + i));
    } else {
      await open();
    }
  };

  const handleSubmit = async () => {
    try {
      if (!orderData) {
        throw new Error("Invalid Order Data");
      }
      if (!userAddress) {
        throw new Error("Undefined publisher");
      }
      const data = await getBlueprintData(orderData, userAddress as `0x${string}`);
      if (data?.blueprintData && data.message) {
        await dispatch(createNewOrder({ blueprintData: data.blueprintData, message: data.message, chainId }));
        navigate("/");
      }
    } catch (error: any) {
      console.error(error);
    }
  };

  return (
    <Stack direction={"row"} justifyContent={"space-between"} mt={3}>
      <Button
        disabled={step === 1}
        startIcon={<ArrowBackIcon />}
        sx={{ borderRadius: 2, fontSize: 16, px: 3, color: "white.main" }}
        onClick={() => handleClick(-1)}
      >
        prev
      </Button>
      <Button
        disabled={disabled}
        endIcon={userAddress && <ArrowForwardIcon />}
        sx={{
          borderRadius: 2,
          fontSize: 16,
          px: 3,
          display: step === 7 ? "none" : "inherit",
          color: "primary.main",
        }}
        onClick={() => handleClick(1)}
      >
        {userAddress ? "next" : "connect"}
      </Button>
      <LoadingButton
        loading={isLoading}
        loadingIndicator={<CircularProgress color="inherit" size={20} />}
        sx={{
          borderRadius: 2,
          fontSize: 16,
          px: 3,
          bgcolor: "background.default",
          display: step === 7 ? "inherit" : "none",
          color: "white.main",
        }}
        onClick={() => handleSubmit()}
      >
        Submit
      </LoadingButton>
    </Stack>
  );
}

export default ActionButtons;

export const PositionAbi = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "bytes32",
        name: "role",
        type: "bytes32",
      },
      {
        indexed: true,
        internalType: "bytes32",
        name: "previousAdminRole",
        type: "bytes32",
      },
      {
        indexed: true,
        internalType: "bytes32",
        name: "newAdminRole",
        type: "bytes32",
      },
    ],
    name: "RoleAdminChanged",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "bytes32",
        name: "role",
        type: "bytes32",
      },
      {
        indexed: true,
        internalType: "address",
        name: "account",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "sender",
        type: "address",
      },
    ],
    name: "RoleGranted",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "bytes32",
        name: "role",
        type: "bytes32",
      },
      {
        indexed: true,
        internalType: "address",
        name: "account",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "sender",
        type: "address",
      },
    ],
    name: "RoleRevoked",
    type: "event",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "loanAmount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "collAmount",
            type: "uint256",
          },
          {
            internalType: "bytes",
            name: "loanAsset",
            type: "bytes",
          },
          {
            internalType: "bytes",
            name: "collAsset",
            type: "bytes",
          },
          {
            internalType: "uint256",
            name: "minCollateralRatio",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "maxDuration",
            type: "uint256",
          },
          {
            internalType: "bool",
            name: "isLeverage",
            type: "bool",
          },
          {
            components: [
              {
                internalType: "address",
                name: "addr",
                type: "address",
              },
              {
                internalType: "bytes",
                name: "parameters",
                type: "bytes",
              },
            ],
            internalType: "struct PluginReference",
            name: "lenderAccount",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "address",
                name: "addr",
                type: "address",
              },
              {
                internalType: "bytes",
                name: "parameters",
                type: "bytes",
              },
            ],
            internalType: "struct PluginReference",
            name: "borrowerAccount",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "address",
                name: "addr",
                type: "address",
              },
              {
                internalType: "bytes",
                name: "parameters",
                type: "bytes",
              },
            ],
            internalType: "struct PluginReference",
            name: "assessor",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "address",
                name: "addr",
                type: "address",
              },
              {
                internalType: "bytes",
                name: "parameters",
                type: "bytes",
              },
            ],
            internalType: "struct PluginReference",
            name: "liquidator",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "address",
                name: "addr",
                type: "address",
              },
              {
                internalType: "bytes",
                name: "parameters",
                type: "bytes",
              },
            ],
            internalType: "struct PluginReference",
            name: "loanOracle",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "address",
                name: "addr",
                type: "address",
              },
              {
                internalType: "bytes",
                name: "parameters",
                type: "bytes",
              },
            ],
            internalType: "struct PluginReference",
            name: "collOracle",
            type: "tuple",
          },
          {
            internalType: "address",
            name: "factory",
            type: "address",
          },
          {
            components: [
              {
                internalType: "address",
                name: "addr",
                type: "address",
              },
              {
                internalType: "bytes",
                name: "parameters",
                type: "bytes",
              },
            ],
            internalType: "struct PluginReference",
            name: "position",
            type: "tuple",
          },
          {
            internalType: "uint256",
            name: "deploymentTime",
            type: "uint256",
          },
          {
            internalType: "bytes",
            name: "fillerData",
            type: "bytes",
          },
        ],
        internalType: "struct Agreement",
        name: "agreement",
        type: "tuple",
      },
      {
        internalType: "uint256",
        name: "amountToClose",
        type: "uint256",
      },
    ],
    name: "close",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "loanAmount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "collAmount",
            type: "uint256",
          },
          {
            internalType: "bytes",
            name: "loanAsset",
            type: "bytes",
          },
          {
            internalType: "bytes",
            name: "collAsset",
            type: "bytes",
          },
          {
            internalType: "uint256",
            name: "minCollateralRatio",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "maxDuration",
            type: "uint256",
          },
          {
            internalType: "bool",
            name: "isLeverage",
            type: "bool",
          },
          {
            components: [
              {
                internalType: "address",
                name: "addr",
                type: "address",
              },
              {
                internalType: "bytes",
                name: "parameters",
                type: "bytes",
              },
            ],
            internalType: "struct PluginReference",
            name: "lenderAccount",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "address",
                name: "addr",
                type: "address",
              },
              {
                internalType: "bytes",
                name: "parameters",
                type: "bytes",
              },
            ],
            internalType: "struct PluginReference",
            name: "borrowerAccount",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "address",
                name: "addr",
                type: "address",
              },
              {
                internalType: "bytes",
                name: "parameters",
                type: "bytes",
              },
            ],
            internalType: "struct PluginReference",
            name: "assessor",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "address",
                name: "addr",
                type: "address",
              },
              {
                internalType: "bytes",
                name: "parameters",
                type: "bytes",
              },
            ],
            internalType: "struct PluginReference",
            name: "liquidator",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "address",
                name: "addr",
                type: "address",
              },
              {
                internalType: "bytes",
                name: "parameters",
                type: "bytes",
              },
            ],
            internalType: "struct PluginReference",
            name: "loanOracle",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "address",
                name: "addr",
                type: "address",
              },
              {
                internalType: "bytes",
                name: "parameters",
                type: "bytes",
              },
            ],
            internalType: "struct PluginReference",
            name: "collOracle",
            type: "tuple",
          },
          {
            internalType: "address",
            name: "factory",
            type: "address",
          },
          {
            components: [
              {
                internalType: "address",
                name: "addr",
                type: "address",
              },
              {
                internalType: "bytes",
                name: "parameters",
                type: "bytes",
              },
            ],
            internalType: "struct PluginReference",
            name: "position",
            type: "tuple",
          },
          {
            internalType: "uint256",
            name: "deploymentTime",
            type: "uint256",
          },
          {
            internalType: "bytes",
            name: "fillerData",
            type: "bytes",
          },
        ],
        internalType: "struct Agreement",
        name: "agreement",
        type: "tuple",
      },
    ],
    name: "getCloseAmount",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "role",
        type: "bytes32",
      },
    ],
    name: "getRoleAdmin",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "role",
        type: "bytes32",
      },
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "grantRole",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "role",
        type: "bytes32",
      },
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "hasRole",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "loanAmount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "collAmount",
            type: "uint256",
          },
          {
            internalType: "bytes",
            name: "loanAsset",
            type: "bytes",
          },
          {
            internalType: "bytes",
            name: "collAsset",
            type: "bytes",
          },
          {
            internalType: "uint256",
            name: "minCollateralRatio",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "maxDuration",
            type: "uint256",
          },
          {
            internalType: "bool",
            name: "isLeverage",
            type: "bool",
          },
          {
            components: [
              {
                internalType: "address",
                name: "addr",
                type: "address",
              },
              {
                internalType: "bytes",
                name: "parameters",
                type: "bytes",
              },
            ],
            internalType: "struct PluginReference",
            name: "lenderAccount",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "address",
                name: "addr",
                type: "address",
              },
              {
                internalType: "bytes",
                name: "parameters",
                type: "bytes",
              },
            ],
            internalType: "struct PluginReference",
            name: "borrowerAccount",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "address",
                name: "addr",
                type: "address",
              },
              {
                internalType: "bytes",
                name: "parameters",
                type: "bytes",
              },
            ],
            internalType: "struct PluginReference",
            name: "assessor",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "address",
                name: "addr",
                type: "address",
              },
              {
                internalType: "bytes",
                name: "parameters",
                type: "bytes",
              },
            ],
            internalType: "struct PluginReference",
            name: "liquidator",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "address",
                name: "addr",
                type: "address",
              },
              {
                internalType: "bytes",
                name: "parameters",
                type: "bytes",
              },
            ],
            internalType: "struct PluginReference",
            name: "loanOracle",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "address",
                name: "addr",
                type: "address",
              },
              {
                internalType: "bytes",
                name: "parameters",
                type: "bytes",
              },
            ],
            internalType: "struct PluginReference",
            name: "collOracle",
            type: "tuple",
          },
          {
            internalType: "address",
            name: "factory",
            type: "address",
          },
          {
            components: [
              {
                internalType: "address",
                name: "addr",
                type: "address",
              },
              {
                internalType: "bytes",
                name: "parameters",
                type: "bytes",
              },
            ],
            internalType: "struct PluginReference",
            name: "position",
            type: "tuple",
          },
          {
            internalType: "uint256",
            name: "deploymentTime",
            type: "uint256",
          },
          {
            internalType: "bytes",
            name: "fillerData",
            type: "bytes",
          },
        ],
        internalType: "struct Agreement",
        name: "agreement",
        type: "tuple",
      },
    ],
    name: "open",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes",
        name: "liquidatorLogic",
        type: "bytes",
      },
    ],
    name: "passThrough",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "role",
        type: "bytes32",
      },
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "renounceRole",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "role",
        type: "bytes32",
      },
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "revokeRole",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "controller",
        type: "address",
      },
    ],
    name: "transferContract",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "loanAmount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "collAmount",
            type: "uint256",
          },
          {
            internalType: "bytes",
            name: "loanAsset",
            type: "bytes",
          },
          {
            internalType: "bytes",
            name: "collAsset",
            type: "bytes",
          },
          {
            internalType: "uint256",
            name: "minCollateralRatio",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "maxDuration",
            type: "uint256",
          },
          {
            internalType: "bool",
            name: "isLeverage",
            type: "bool",
          },
          {
            components: [
              {
                internalType: "address",
                name: "addr",
                type: "address",
              },
              {
                internalType: "bytes",
                name: "parameters",
                type: "bytes",
              },
            ],
            internalType: "struct PluginReference",
            name: "lenderAccount",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "address",
                name: "addr",
                type: "address",
              },
              {
                internalType: "bytes",
                name: "parameters",
                type: "bytes",
              },
            ],
            internalType: "struct PluginReference",
            name: "borrowerAccount",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "address",
                name: "addr",
                type: "address",
              },
              {
                internalType: "bytes",
                name: "parameters",
                type: "bytes",
              },
            ],
            internalType: "struct PluginReference",
            name: "assessor",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "address",
                name: "addr",
                type: "address",
              },
              {
                internalType: "bytes",
                name: "parameters",
                type: "bytes",
              },
            ],
            internalType: "struct PluginReference",
            name: "liquidator",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "address",
                name: "addr",
                type: "address",
              },
              {
                internalType: "bytes",
                name: "parameters",
                type: "bytes",
              },
            ],
            internalType: "struct PluginReference",
            name: "loanOracle",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "address",
                name: "addr",
                type: "address",
              },
              {
                internalType: "bytes",
                name: "parameters",
                type: "bytes",
              },
            ],
            internalType: "struct PluginReference",
            name: "collOracle",
            type: "tuple",
          },
          {
            internalType: "address",
            name: "factory",
            type: "address",
          },
          {
            components: [
              {
                internalType: "address",
                name: "addr",
                type: "address",
              },
              {
                internalType: "bytes",
                name: "parameters",
                type: "bytes",
              },
            ],
            internalType: "struct PluginReference",
            name: "position",
            type: "tuple",
          },
          {
            internalType: "uint256",
            name: "deploymentTime",
            type: "uint256",
          },
          {
            internalType: "bytes",
            name: "fillerData",
            type: "bytes",
          },
        ],
        internalType: "struct Agreement",
        name: "agreement",
        type: "tuple",
      },
    ],
    name: "unwind",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
] as const;

/* eslint-disable array-callback-return */
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { decodeAbiParameters, formatUnits, parseAbiParameters } from "viem";
import { useContractReads } from "wagmi";

import { Agreement } from "../../../helpers/types/response";
import { secondToDateType, unpackDataField } from "../../../helpers/utils/format";
import { AgreementStruct, PositionAbi } from "../../../helpers/abis";
import { getAssetData } from "../../../helpers/utils/assets";
import { Button } from "@mui/material";
import { useAssessorContract } from "../../../hooks/contracts/useAssessorContract";
import { useBookkeeperContract } from "../../../hooks/contracts/useBookkeeperContract";
import { PluginType } from "../../../helpers/types/basic";
import { useAppSelector } from "../../../redux/store";
import { ADDRESS_TO_PAIR } from "../../../helpers/constants/tokens";
import { WETH_ADDRESS } from "../../../helpers/constants/address";
import { ParametersToDecode } from "../../../config/parameter-abis";
import { useNavigate } from "react-router-dom";

export default function LoansTable({
  agreements,
  accountId,
  apiLoading,
  chainId = Number(process.env.REACT_APP_CHAIN_ID),
}: {
  agreements: Agreement[] | undefined;
  accountId: number;
  apiLoading: boolean;
  chainId: number | undefined;
}) {
  const tokenPrices = useAppSelector((state) => state.app.tokenPrices);
  const navigate = useNavigate();

  const contracts = React.useMemo(() => {
    const returnValue: { abi: any; address: `0x${string}`; functionName: string; args: any[] }[] = [];
    const amountIndex: string[] = [];
    agreements?.map((agreement) => {
      returnValue.push({
        abi: PositionAbi,
        address: agreement.positionAddress,
        functionName: "getCloseAmount",
        args: [
          decodeAbiParameters(
            parseAbiParameters(AgreementStruct),
            unpackDataField(agreements[0].agreementData) as `0x${string}`
          )[0],
        ],
      });
      amountIndex.push(agreement.agreementData);
    });
    return { returnValue, amountIndex };
  }, [agreements]);

  const { data: positionValues, isError, isLoading } = useContractReads({ contracts: contracts.returnValue });

  const leftDuration = React.useCallback((agreement: Agreement | undefined) => {
    if (!agreement) {
      return "--";
    }
    if (Number(agreement.order.duration) === Number.MAX_SAFE_INTEGER) {
      return "infinity";
    }
    const endDate = new Date(agreement?.endDate).getTime();
    const currentDate = new Date().getTime();
    const result = secondToDateType((endDate - currentDate) / 1000);
    return `${Math.round(result.value || 0)} ${result.unit}`;
  }, []);

  const fee = React.useCallback((collector: PluginType) => {
    const fees = decodeAbiParameters(
      parseAbiParameters(ParametersToDecode[collector.pluginName]),
      collector.parameters
    );
    return parseFloat(formatUnits((fees[0] as any)["originationFeeValue"], 18));
  }, []);

  const PnL = React.useCallback(
    (positionValue: number, agreement: Agreement) => {
      if (agreement.lenderId === accountId) {
        if (positionValue > Number(agreement.loanAmount)) {
          return fee(agreement.collector);
        } else {
          return positionValue - Number(agreement.loanAmount) + fee(agreement.collector);
        }
      } else {
        return positionValue - Number(agreement.loanAmount) - fee(agreement.collector);
      }
    },
    [accountId, fee]
  );

  const { getCost, isLoading: collectorLoading } = useAssessorContract();
  const { closeOrder, isLoading: bookkeeperLoading } = useBookkeeperContract();

  const handleCloseLoan = async (agreement: Agreement) => {
    const cost = await getCost(agreement);
    await closeOrder(agreement, cost);
  };

  const handleDetailViewButton = (
    e: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    agreementHash: string
  ) => {
    if ((e.target as HTMLElement).tagName !== "BUTTON") {
      navigate(`/markets/agreement/${agreementHash}`);
    }
  };

  return (
    <TableContainer component={Paper} elevation={0} sx={{ borderRadius: 2 }}>
      <Table sx={{ minWidth: 650, bgcolor: "black.light" }} aria-label="asset table">
        <TableHead sx={{ bgcolor: "background.default" }}>
          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell>loan</TableCell>
            <TableCell align="right">loan value</TableCell>
            <TableCell align="right">duration left</TableCell>
            <TableCell align="right">role</TableCell>
            <TableCell align="right">positions value</TableCell>
            <TableCell align="right">liquidation price</TableCell>
            <TableCell align="right">CR</TableCell>
            <TableCell align="right">MCR</TableCell>
            <TableCell align="right">collateral value</TableCell>
            <TableCell align="right">PnL</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {apiLoading ? (
            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell sx={{ px: 2 }}>loading...</TableCell>
            </TableRow>
          ) : (
            <>
              {agreements?.length ? (
                <>
                  {agreements.map((agreement, i) => {
                    const loanAsset = getAssetData(agreement.loanAsset, agreement.chainId);
                    const collAsset = getAssetData(agreement.collAsset, agreement.chainId);
                    const positionValue =
                      positionValues?.[contracts.amountIndex.indexOf(agreement.agreementData)];
                    const loanOracleRatio =
                      tokenPrices[ADDRESS_TO_PAIR[WETH_ADDRESS[chainId]]] /
                      tokenPrices[ADDRESS_TO_PAIR[agreement.loanAsset.addr]];

                    const collateralOracleRatio =
                      tokenPrices[ADDRESS_TO_PAIR[WETH_ADDRESS[chainId]]] /
                      tokenPrices[ADDRESS_TO_PAIR[agreement.collAsset.addr]];

                    return (
                      <TableRow
                        key={i}
                        hover
                        onClick={(e) => handleDetailViewButton(e, agreement.agreementHash)}
                        sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor: "pointer" }}
                      >
                        <TableCell sx={{ wordBreak: "keep-all" }}>
                          loan - {i.toString().padStart(3, "0")}
                        </TableCell>
                        <TableCell align="right">
                          {agreement.loanAmount || 0} {loanAsset?.symbol}
                        </TableCell>
                        <TableCell align="right">{leftDuration(agreement)}</TableCell>
                        <TableCell align="right">
                          {agreement.lenderId === accountId ? "lender" : "borrower"}{" "}
                        </TableCell>
                        <TableCell align="right">
                          {isLoading || isError
                            ? "--"
                            : formatUnits(positionValue?.result as unknown as bigint, 18)}{" "}
                          {loanAsset?.symbol}
                        </TableCell>
                        <TableCell align="right">
                          {fee(agreement.collector) || 0} {loanAsset?.symbol}
                        </TableCell>
                        <TableCell align="right">
                          {isLoading || isError
                            ? "--"
                            : (
                                (parseFloat(formatUnits(positionValue?.result as unknown as bigint, 18)) /
                                  loanOracleRatio +
                                  Number(agreement.collAmount) / collateralOracleRatio) /
                                (Number(agreement.loanAmount) / loanOracleRatio)
                              ).toFixed(2)}
                        </TableCell>
                        <TableCell align="right">{agreement?.minCollateralRatio / 100}</TableCell>
                        <TableCell align="right">
                          {agreement.collAmount || "--"} {collAsset?.symbol}
                        </TableCell>
                        <TableCell align="right">
                          {isLoading || isError
                            ? "--"
                            : PnL(
                                parseFloat(formatUnits(positionValue?.result as unknown as bigint, 18)),
                                agreement
                              )}
                        </TableCell>
                        <TableCell sx={{ fontSize: 16 }}>
                          {agreement.borrowerId === accountId && !agreement.isClosed && (
                            <Button
                              disabled={collectorLoading || bookkeeperLoading}
                              color="error"
                              onClick={() => handleCloseLoan(agreement)}
                            >
                              close
                            </Button>
                          )}
                          {agreement.isClosed && <Button disabled>closed</Button>}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </>
              ) : (
                <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell sx={{ px: 2 }}>no loans</TableCell>
                </TableRow>
              )}
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

import { Token } from "../types/basic";
import tokens from "../../helpers/data/token-lists.json";
import { AddressZero } from "./address";
export const tokenList: Token[] = tokens;
export const ETHLogo = "https://assets.coingecko.com/coins/images/279/thumb/ethereum.png?1595348880";
export const ETHImg = "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880";

export const NativeToken = {
  chainId: Number(process.env.REACT_APP_CHAIN_ID),
  address: AddressZero,
  name: "Ether",
  symbol: "ETH",
  decimals: 18,
  logoURI: ETHLogo,
  img: ETHImg,
};

export const CoinbaseTokenPricePairs = ["CRV-USD", "ETH-USD", "USDT-USD", "BTC-USD", "ARB-USD"];

export const ADDRESS_TO_PAIR: { [address: string]: string } = {
  "0x0000000000000000000000000000000000000000": "ETH-USD",
  // sepolia
  "0x6175a8471C2122f778445e7E07A164250a19E661": "USDT-USD",
  "0x6f14C02Fc1F78322cFd7d707aB90f18baD3B54f5": "USDT-USD",
  "0x7AF17A48a6336F7dc1beF9D485139f7B6f4FB5C8": "USDT-USD",
  "0x7b79995e5f793A07Bc00c21412e50Ecae098E7f9": "ETH-USD",
  // mainnet
  "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2": "ETH-USD", // weth
  "0x6B175474E89094C44Da98b954EedeAC495271d0F": "USDT-USD", // dai
  "0xdAC17F958D2ee523a2206206994597C13D831ec7": "USDT-USD", // usdt
  "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48": "USDT-USD", // usdc
  // arbitrum
  "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1": "ETH-USD", // weth
  "0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f": "BTC-USD", // wbtc
  "0x11cDb42B0EB46D95f990BeDD4695A6e3fA034978": "CRV-USD", // crv
  "0x912CE59144191C1204E64559FE8253a0e49E6548": "ARB-USD", // arb
  "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1": "USDT-USD", // dai
  "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9": "USDT-USD", // usdt
  "0xaf88d065e77c8cC2239327C5EDb3A432268e5831": "USDT-USD", // usdc
  "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8": "USDT-USD", // usdc.e
};

import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Token } from "../../helpers/types/basic";

type AssetManageModalType = {
  isOpened: boolean;
  accountIndex: number;
};

type ActiveAssetInfoType = {
  assetData: Token | undefined;
  totalBorrowed: number;
  totalSupplied: number;
};

type TokenPricesType = {
  [pair: string]: number;
};

export interface AppState {
  appLoading: boolean;
  assetManageModal: AssetManageModalType;
  activeAssetInfo: ActiveAssetInfoType;
  tokenPrices: TokenPricesType;
}

const initialState: AppState = {
  appLoading: false,
  assetManageModal: {} as AssetManageModalType,
  activeAssetInfo: {} as { assetData: Token | undefined; totalBorrowed: number; totalSupplied: number },
  tokenPrices: {},
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setPageSpinnerLoading: (state, action: PayloadAction<boolean>) => {
      state.appLoading = action.payload;
    },
    setAssetModalState: (state, action: PayloadAction<AssetManageModalType>) => {
      state.assetManageModal = action.payload;
    },
    setActiveMarket: (
      state,
      action: PayloadAction<{ assetData: Token | undefined; totalBorrowed: number; totalSupplied: number }>
    ) => {
      state.activeAssetInfo = action.payload;
    },
    setTokenPrices: (state, action: PayloadAction<TokenPricesType>) => {
      state.tokenPrices[Object.keys(action.payload)[0]] = Object.values(action.payload)[0];
    },
  },
});

// Action creators are generated for each case reducer function
export const { setPageSpinnerLoading, setAssetModalState, setActiveMarket, setTokenPrices } =
  appSlice.actions;

export default appSlice.reducer;

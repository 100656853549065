import { sepolia, mainnet } from "viem/chains";

export const HashZero = "0x0000000000000000000000000000000000000000000000000000000000000000" as `0x${string}`;
export const AddressZero = "0x0000000000000000000000000000000000000000" as `0x${string}`;
export const BeanstalkAddress = "0xC1E088fC1323b20BCBee9bd1B9fC9546db5624C5" as `0x${string}`;

export const BookkeeperContractAddress: { [key: number]: `0x${string}` } = {
  [mainnet.id]: "0x98D26887FA32bf2e6C0793cEade9FCb5E960DF86",
  [sepolia.id]:
    process.env.NODE_ENV === "development"
      ? "0x4c02dc789E954C744d6b8A70DF2c8CD267d21DD2"
      : "0x4c02dc789E954C744d6b8A70DF2c8CD267d21DD2",
};

export const AccountManagerAddress: { [key: number]: `0x${string}` } = {
  [mainnet.id]: "0xae319Acf9dC6E952DC456fa9b65DAB0865D6457b",
  [sepolia.id]:
    process.env.NODE_ENV === "development"
      ? "0xD8BCFae7BD1db4580faBCA316C4064cEB43774A3"
      : "0xD8BCFae7BD1db4580faBCA316C4064cEB43774A3",
};

export const WETH_ADDRESS: { [key: number]: `0x${string}` } = {
  [mainnet.id]: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
  [sepolia.id]: "0x7b79995e5f793A07Bc00c21412e50Ecae098E7f9",
};

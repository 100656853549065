import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import type { TypedUseSelectorHook } from "react-redux";
import orderReducer from "./reducers/order-slice";
import appReducer from "./reducers/app-slice";
import userReducer from "./reducers/user-slice";
import NotificationReducer from "./reducers/notification-slice";

export const store = configureStore({
  reducer: {
    order: orderReducer,
    app: appReducer,
    user: userReducer,
    notification: NotificationReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { MenuItem, Select, SelectChangeEvent, Stack } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { fillCreationForm } from "../../../redux/reducers/order-slice";
import ActionButtons from "../ActionButtons";
import { OrderTypes } from "../../../helpers/enums";
import { BootstrapTooltip } from "../../Tooltip";
import untypedData from "../../../helpers/data/tooltip-data.json";
const tooltipData: any = untypedData;

function Step5() {
  const orderSlice = useAppSelector((state) => state.order);
  const [durationUnit, setDurationUnit] = useState(orderSlice.orderCreationForm?.durationUnit || 3600);
  const [duration, setDuration] = useState<number | undefined>(
    orderSlice.orderCreationForm?.duration || undefined
  );

  const [ICR, setICR] = useState<number | undefined>(
    orderSlice.orderCreationForm?.initialCollateralRatio || undefined
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      if (ICR) {
        dispatch(fillCreationForm(["initialCollateralRatio", ICR]));
      }
    };
  }, [ICR, dispatch]);

  useEffect(() => {
    dispatch(fillCreationForm(["durationUnit", durationUnit]));
    dispatch(fillCreationForm(["duration", duration]));
  }, [dispatch, duration, durationUnit]);
  return (
    <>
      <Grid container rowSpacing={2}>
        <Grid xs={12}>
          <Typography variant="h6" mb={3} color={"white.main"}>
            duration and CR
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Stack direction="row" gap={0.5}>
            <Typography variant="body2">max duration</Typography>
            <BootstrapTooltip title={tooltipData["max-duration"]} arrow>
              <HelpOutlineIcon fontSize={"inherit"} sx={{ cursor: "pointer" }} />
            </BootstrapTooltip>
          </Stack>
          <TextField
            fullWidth
            variant="outlined"
            sx={{
              borderRadius: 2,
              my: 1,
              border: 0,
              bgcolor: "background.default",
              "& fieldset": {
                borderColor: "transparent",
                borderRadius: 2,
                border: 0,
              },
              "& input": { fontSize: 14, py: 2 },
            }}
            color="primary"
            placeholder={durationUnit === Number.MAX_SAFE_INTEGER ? "infinity" : "enter max duration"}
            type="number"
            disabled={durationUnit === Number.MAX_SAFE_INTEGER}
            value={durationUnit === Number.MAX_SAFE_INTEGER ? undefined : duration}
            onChange={(e) => setDuration(Number(e.target.value) || undefined)}
            InputProps={{
              inputProps: { style: { borderRadius: 5 } },
              endAdornment: (
                <InputAdornment position="end">
                  <Select
                    value={durationUnit}
                    onChange={(event: SelectChangeEvent) => setDurationUnit(event.target.value)}
                    variant="standard"
                    sx={{
                      opacity: 0.7,
                      fontSize: 14,
                      borderLeft: 1,
                      pl: 2,
                      bgcolor: "background.default",
                      "& fieldset": {
                        borderColor: "transparent",
                        borderRadius: 2,
                        border: 0,
                      },
                    }}
                    disableUnderline
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value={60 * 60}>hours</MenuItem>
                    <MenuItem value={60 * 60 * 24}>days</MenuItem>
                    <MenuItem value={60 * 60 * 24 * 7}>weeks</MenuItem>
                    <MenuItem value={60 * 60 * 24 * 30}>months</MenuItem>
                    <MenuItem value={Number.MAX_SAFE_INTEGER}>infinity</MenuItem>
                  </Select>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        {orderSlice.orderCreationForm?.orderType === OrderTypes.BORROW && (
          <Grid xs={12}>
            <Typography variant="body2">initial collateral ratio</Typography>
            <TextField
              fullWidth
              variant="outlined"
              value={ICR}
              onChange={(e) => setICR(Number(e.target.value) || undefined)}
              sx={{
                borderRadius: 2,
                my: 1,
                border: 0,
                bgcolor: "background.default",
                "& fieldset": {
                  borderColor: "transparent",
                  borderRadius: 2,
                  border: 0,
                },
                "& input": { fontSize: 14, py: 2 },
              }}
              type="number"
              color="primary"
              placeholder="enter percentage"
              InputProps={{
                inputProps: { style: { borderRadius: 5 } },
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography
                      fontSize={14}
                      borderLeft={1}
                      pl={2}
                      pr={1}
                      sx={{ opacity: 0.7 }}
                      color="white.main"
                    >
                      %
                    </Typography>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        )}
      </Grid>
      <ActionButtons
        step={5}
        disabled={
          ((duration === 0 || duration === undefined) && durationUnit !== Number.MAX_SAFE_INTEGER) ||
          (orderSlice.orderCreationForm?.orderType === OrderTypes.BORROW && ICR === undefined)
          // (orderSlice.orderCreationForm?.orderType === OrderTypes.BORROW && Number(MCR) >= Number(ICR))
        }
      />
    </>
  );
}

export default Step5;

import { PluginName } from "../helpers/enums";

export const ParametersToDecode: { [pluginName: string]: string } = {
  [PluginName.StandardAssessor]:
    "(uint256 originationFeeValue, uint256 originationFeePercentage, uint256 interestRate, uint256 profitShareRate)",
  [PluginName.StandardLiquidation]: "(uint256 fixedFee, uint256 percentageFee)",
  [PluginName.Wallet]: "",
  [PluginName.BeanstalkSilo]: "",
  [PluginName.StaticOracle]: "(uint256 number)",
  [PluginName.BeanDepositOracle]: "",
  [PluginName.BeanOracle]: "(uint8 input)",
  [PluginName.ChainlinkOracle]: "(address addr)",
};

export const AssetParameters = "(uint8, address, uint8, uint256, bytes)";

import { useState, useEffect } from "react";
import { getOrderInfo } from "../../helpers/utils/api";
import { OrderInfo } from "../../helpers/types/response";

export const useOrderInfo = (chainId = Number(process.env.REACT_APP_CHAIN_ID)) => {
  const [orderInfo, setOrderInfo] = useState<OrderInfo[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const orderInfo = await getOrderInfo(chainId);
        setOrderInfo(orderInfo);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [chainId]);

  return { orderInfo, isLoading };
};

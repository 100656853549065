export const OrderStruct = [
  {
    type: "tuple",
    components: [
      { type: "uint256[]", name: "minLoanAmounts" },
      { type: "bytes[]", name: "loanAssets" },
      { type: "bytes[]", name: "collAssets" },
      { type: "uint256[]", name: "minCollateralRatio" },
      { type: "address[]", name: "fillers" },
      { type: "bool", name: "isLeverage" },
      { type: "uint256", name: "maxDuration" },
      {
        type: "tuple",
        name: "account",
        components: [
          { type: "address", name: "addr" },
          { type: "bytes", name: "parameters" },
        ],
      },
      {
        type: "tuple",
        name: "assessor",
        components: [
          { type: "address", name: "addr" },
          { type: "bytes", name: "parameters" },
        ],
      },
      {
        type: "tuple",
        name: "liquidator",
        components: [
          { type: "address", name: "addr" },
          { type: "bytes", name: "parameters" },
        ],
      },
      {
        type: "tuple[]",
        name: "loanOracles",
        components: [
          { type: "address", name: "addr" },
          { type: "bytes", name: "parameters" },
        ],
      },
      {
        type: "tuple[]",
        name: "collOracles",
        components: [
          { type: "address", name: "addr" },
          { type: "bytes", name: "parameters" },
        ],
      },
      { type: "address[]", name: "factories" },
      { type: "bool", name: "isOffer" },
      {
        type: "tuple",
        name: "borrowerConfig",
        components: [
          { type: "uint256", name: "collAmount" },
          { type: "bytes", name: "positionParameters" },
        ],
      },
    ],
  },
] as const;

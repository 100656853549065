import { useState, useEffect } from "react";
import { getAggregateInfo } from "../../helpers/utils/api";
import { AggregateInfo } from "../../helpers/types/response";

export const useAggregateInfo = (chainId = Number(process.env.REACT_APP_CHAIN_ID), assetAddress?: string) => {
  const [aggregateInfo, setAggregateInfo] = useState<AggregateInfo>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const aggregateInfo = await getAggregateInfo(chainId, assetAddress);
        setAggregateInfo(aggregateInfo);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [assetAddress, chainId]);

  return { aggregateInfo, isLoading };
};

import { useEffect } from "react";
import { useAppDispatch } from "../../redux/store";
import { initUser } from "../../redux/reducers/user-slice";

export const usePharosUser = (
  isConnected: boolean,
  wagmiAddress: `0x${string}` | undefined,
  isReconnecting: boolean,
  chainId = Number(process.env.REACT_APP_CHAIN_ID)
) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (wagmiAddress) dispatch(initUser({ address: wagmiAddress, chainId }));
  }, [chainId, dispatch, isConnected, isReconnecting, wagmiAddress]);
};

import { HashZero } from "../constants/address";
import { tokenList } from "../constants/tokens";
import { AssetType } from "../types/basic";

export const getAssetData = (assetType: AssetType, chainId = Number(process.env.REACT_APP_CHAIN_ID)) => {
  const result = tokenList.find(
    (token) =>
      token.address.toLocaleLowerCase() === assetType?.addr.toLocaleLowerCase() &&
      token.chainId === Number(chainId)
  );
  if (!result) {
    return {
      standard: assetType?.standard,
      logoURI: "",
      chainId: Number(process.env.REACT_APP_CHAIN_ID),
      decimals: assetType?.decimals,
      address: assetType?.addr,
      name: "unknown",
      img: "",
      symbol: "",
    };
  }
  return result;
};

export const symbolToAsset = (
  symbol: string,
  chainId = Number(process.env.REACT_APP_CHAIN_ID),
  tokenId = "0"
): AssetType => {
  const asset = tokenList.find((token) => token.symbol === symbol && token.chainId === chainId);
  if (asset) {
    return {
      standard: asset.standard,
      addr: asset.address as `0x${string}`,
      decimals: asset.decimals,
      tokenId: tokenId,
      data: HashZero,
    };
  } else {
    return {} as AssetType;
  }
};

export const getBeanAddress = (tokenId: bigint) => {
  const address: bigint = tokenId >> 96n;
  // const int96Value: bigint = tokenId & BigInt(2n ** 96n - 1n);
  const addressToken: string = address.toString(16);
  // const int96Token: string = int96Value.toString();
  return `0x${addressToken}` as `0x${string}`;
};

import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import Stack from "@mui/material/Stack";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

import SelectInput from "../../SelectBox/SingleSelectInput";
import ActionButtons from "../ActionButtons";
import MultiSelectInput from "../../SelectBox/MultiSelectInput";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { fillCreationForm } from "../../../redux/reducers/order-slice";
import { PluginTypesEnum } from "../../../helpers/enums";
import { PluginColors } from "../../../helpers/constants/plugin-colors";
import { BootstrapTooltip } from "../../Tooltip";
import untypedData from "../../../helpers/data/tooltip-data.json";
const tooltipData: any = untypedData;

function Step6() {
  const plugins = useAppSelector((state) => state.user.plugins);
  const userSlice = useAppSelector((state) => state.user);
  const [terminals, setTerminals] = useState<string[]>([]);
  const [collector, setCollector] = useState("");
  const [closer, setCloser] = useState("");
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(
      fillCreationForm([
        "terminalIds",
        terminals.map(
          (terminal) =>
            plugins.find(
              (plugin) => plugin.name === terminal && plugin.pluginType === PluginTypesEnum.POSITION
            )?.id
        ),
      ])
    );
  }, [dispatch, plugins, terminals]);

  useEffect(() => {
    dispatch(
      fillCreationForm([
        "collectorId",
        plugins.find((plugin) => plugin.name === collector && plugin.pluginType === PluginTypesEnum.ASSESSOR)
          ?.id,
      ])
    );
  }, [collector, dispatch, plugins]);

  useEffect(() => {
    dispatch(
      fillCreationForm([
        "closerId",
        plugins.find((plugin) => plugin.name === closer && plugin.pluginType === PluginTypesEnum.LIQUIDATION)
          ?.id,
      ])
    );
  }, [dispatch, closer, plugins]);

  return (
    <>
      <Grid container rowSpacing={2}>
        <Grid xs={12}>
          <Typography variant="h6" fontWeight={"600"} mb={3} color={"white.main"}>
            plugins
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Stack direction="row" gap={0.5}>
            <Typography variant="body2" color={PluginColors[PluginTypesEnum.ASSESSOR]}>
              assessor
            </Typography>
            <BootstrapTooltip title={tooltipData["assessor"]} arrow>
              <HelpOutlineIcon fontSize={"inherit"} sx={{ cursor: "pointer" }} />
            </BootstrapTooltip>
          </Stack>
          <SelectInput
            placeholder="select assessor"
            values={plugins
              .filter(
                (plugin) =>
                  plugin.pluginType === PluginTypesEnum.ASSESSOR &&
                  plugin.creatorAddress === userSlice.address
              )
              .map((plugin) => plugin.name)}
            selectValue={setCollector}
            selectedValue={collector}
          />
        </Grid>
        <Grid xs={12}>
          <Stack direction="row" gap={0.5}>
            <Typography variant="body2" color={PluginColors[PluginTypesEnum.LIQUIDATION]}>
              liquidation
            </Typography>
            <BootstrapTooltip title={tooltipData["liquidation"]} arrow>
              <HelpOutlineIcon fontSize={"inherit"} sx={{ cursor: "pointer" }} />
            </BootstrapTooltip>
          </Stack>
          <SelectInput
            placeholder="select liquidation"
            values={plugins
              .filter(
                (plugin) =>
                  plugin.pluginType === PluginTypesEnum.LIQUIDATION &&
                  plugin.creatorAddress === userSlice.address
              )
              .map((plugin) => plugin.name)}
            selectValue={setCloser}
            selectedValue={closer}
          />
        </Grid>
        <Grid xs={12}>
          <Stack direction="row" gap={0.5}>
            <Typography variant="body2" color={PluginColors[PluginTypesEnum.POSITION]}>
              position
            </Typography>
            <BootstrapTooltip title={tooltipData["position"]} arrow>
              <HelpOutlineIcon fontSize={"inherit"} sx={{ cursor: "pointer" }} />
            </BootstrapTooltip>
          </Stack>

          <MultiSelectInput
            placeholder="select position"
            values={plugins
              .filter(
                (plugin) =>
                  plugin.pluginType === PluginTypesEnum.POSITION &&
                  plugin.creatorAddress === userSlice.address
              )
              .map((plugin) => plugin.name)}
            selectValue={setTerminals}
            selectedValue={terminals}
          />
          {terminals.length === 0 && (
            <Typography variant="body2" color={"yellow"}>
              warning: MCR is set below 100% and no position plugin is selected.
            </Typography>
          )}
        </Grid>
      </Grid>
      <ActionButtons step={6} disabled={!collector || !closer} />
    </>
  );
}

export default Step6;

import { Alchemy, Network, NftFilters, NftTokenType, TokenBalanceType } from "alchemy-sdk";
import { formatUnits } from "viem";
import { ERC1155Asset, ERC20Asset, ERC721Asset } from "../types/basic";
import { containsOnlyZeros } from "./format";

const config = {
  apiKey: process.env.REACT_APP_ALCHEMY_API_KEY,
  network: Network.ETH_MAINNET,
};
const alchemy = new Alchemy(config);

export const getERC20Assets = async (address: string | undefined) => {
  if (!address) {
    return [];
  }
  // Get token balances
  const assets: ERC20Asset[] = [];
  const balances = await alchemy.core.getTokenBalances(address, { type: TokenBalanceType.DEFAULT_TOKENS });

  // Remove tokens with zero balance
  const nonZeroBalances = balances.tokenBalances.filter((token) => {
    return !containsOnlyZeros(token.tokenBalance);
  });

  // Loop through all tokens with non-zero balance
  for (let token of nonZeroBalances) {
    const metadata = await alchemy.core.getTokenMetadata(token.contractAddress);
    if (token?.tokenBalance) {
      const amount = formatUnits(BigInt(parseInt(token.tokenBalance, 16)), metadata.decimals || 18);
      assets.push({
        address: token.contractAddress,
        amount: Number(amount),
        decimal: metadata.decimals ?? 18,
        name: metadata.name ?? "",
        symbol: metadata.symbol ?? "",
      });
    }
  }
  return assets;
};

export const getNfts = async (address: string | undefined) => {
  const erc721: ERC721Asset[] = [];
  const erc1155: ERC1155Asset[] = [];
  if (!address) {
    return { erc721, erc1155 };
  }
  const nfts = await alchemy.nft.getNftsForOwner(address, {
    excludeFilters: [NftFilters.AIRDROPS, NftFilters.SPAM],
  });
  for (const nft of nfts?.ownedNfts) {
    if (nft.tokenType === NftTokenType.ERC1155) {
      if (nft.contract.name && nft.contract.symbol) {
        erc1155.push({
          address: nft.contract.address,
          name: nft.contract.name ?? "Unknown 1155",
          symbol: nft.contract.symbol ?? "1155",
          tokenId: nft.tokenId,
          amount: nft.balance,
        });
      }
    } else {
      if (nft.contract.name && nft.contract.symbol) {
        erc721.push({
          address: nft.contract.address,
          name: nft.contract.name,
          symbol: nft.contract.symbol,
          tokenId: nft.tokenId,
        });
      }
    }
  }
  return { erc721, erc1155 };
};

import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useNetwork, useSignTypedData } from "wagmi";
import Container from "@mui/material/Container";
import AddIcon from "@mui/icons-material/Add";

import { useAppDispatch, useAppSelector } from "../../redux/store";
import { createNewPlugin } from "../../redux/reducers/user-slice";
import { AccountManagerAddress } from "../../helpers/constants/address";
import { PluginTypesEnum } from "../../helpers/enums";

export default function CreateAccountButton() {
  const userSlice = useAppSelector((state) => state.user);
  const { chain } = useNetwork();
  const dispatch = useAppDispatch();

  const domain = {
    name: process.env.REACT_APP_NAME,
    version: process.env.REACT_APP_VERSION,
    chainId: chain?.id ?? Number(process.env.REACT_APP_CHAIN_ID),
    verifyingContract: AccountManagerAddress[chain?.id ?? 1],
  } as const;

  const types = {
    Message: [
      { name: "Welcome to Pharos", type: "string" },
      { name: "Wallet address", type: "address" },
      { name: "contents", type: "string" },
    ],
  } as const;
  const message = {
    "Welcome to Pharos": "",
    "Wallet address": userSlice.address as `0x${string}`,
    contents: "Create New Account!",
  } as const;

  const { data, isLoading, signTypedData } = useSignTypedData({
    domain,
    message,
    primaryType: "Message",
    types,
  });

  useEffect(() => {
    if (data) {
      dispatch(
        createNewPlugin({
          signature: data,
          message: { domain, types, value: message },
          pluginType: PluginTypesEnum.ACCOUNT,
          chainId: chain?.id ?? Number(process.env.REACT_APP_CHAIN_ID),
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, dispatch]);

  return (
    <Container maxWidth="xl">
      <Box mt={6} mb={4}>
        <Button
          variant="outlined"
          disabled={isLoading}
          sx={{
            py: 1.5,
            px: 3,
            borderRadius: 2,
            borderColor: "white.main",
            color: "white.main",
          }}
          startIcon={<AddIcon />}
          onClick={() => signTypedData()}
        >
          create account
        </Button>
      </Box>
    </Container>
  );
}

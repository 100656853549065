import React from "react";
import Box from "@mui/material/Box";
import TotalMarketInfo from "../../components/Dashboard/TotalMarketInfo";
import MarketTable from "../../components/Dashboard/MarketTable";
import { useOrderInfo } from "../../hooks/apis";
import PageMeta from "../../layout/PageMeta";
import { useNetwork } from "wagmi";

function Dashboard() {
  const { chain } = useNetwork();
  const { orderInfo, isLoading } = useOrderInfo(chain?.id);
  return (
    <>
      <PageMeta />
      <Box>
        <TotalMarketInfo orderInfo={orderInfo} isLoading={isLoading} chainId={chain?.id} />
        <MarketTable orderInfo={orderInfo} isLoading={isLoading} />
      </Box>
    </>
  );
}

export default Dashboard;

import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

const ITEM_HEIGHT = 54;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

type Props = {
  isPlaceholder?: boolean;
  values: string[];
  placeholder: string;
  selectedValue: string[];
  selectValue: (value: string[]) => void;
};

export default function MultiSelectInput({
  values,
  placeholder,
  selectValue,
  selectedValue,
  isPlaceholder,
}: Props) {
  const handleChange = (event: SelectChangeEvent<typeof selectedValue>) => {
    const {
      target: { value },
    } = event;
    selectValue(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <div>
      <FormControl sx={{ width: "100%", my: 1 }}>
        <Select
          multiple
          displayEmpty
          sx={{
            fontSize: 14,
            borderRadius: 2,
            border: 0,
            bgcolor: "background.default",
            "& fieldset": {
              borderColor: "transparent",
              borderRadius: 2,
              border: 0,
            },
          }}
          value={selectedValue}
          onChange={handleChange}
          renderValue={(selected) => {
            if (isPlaceholder) selected = [];
            if (selected.length === 0) {
              return <em>{placeholder}</em>;
            }

            return selected.join(", ");
          }}
          MenuProps={MenuProps}
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem disabled value="">
            <em>{placeholder}</em>
          </MenuItem>
          {values?.map((name) => (
            <MenuItem key={name} value={name}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

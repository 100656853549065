import React, { useEffect, useMemo } from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { Box, Container, Paper } from "@mui/material";
import { useAppSelector } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import { useAggregateInfo } from "../../hooks/apis";
import { ADDRESS_TO_PAIR } from "../../helpers/constants/tokens";

function MarketInfo({ chainId }: { chainId: number | undefined }) {
  const activeAssetInfo = useAppSelector((state) => state.app.activeAssetInfo);
  const tokenPrices = useAppSelector((state) => state.app.tokenPrices);
  const navigate = useNavigate();
  const { aggregateInfo, isLoading: aggregateInfoLoading } = useAggregateInfo(
    chainId,
    activeAssetInfo.assetData?.address
  );
  const values = useMemo(() => {
    const totalSupply =
      (tokenPrices[ADDRESS_TO_PAIR[activeAssetInfo.assetData?.address || ""]] ?? 0) *
      activeAssetInfo.totalSupplied;
    const totalBorrowed =
      (tokenPrices[ADDRESS_TO_PAIR[activeAssetInfo.assetData?.address || ""]] ?? 0) *
      activeAssetInfo.totalBorrowed;
    return [totalSupply.toFixed(0), totalBorrowed.toFixed(0)];
  }, [tokenPrices, activeAssetInfo]);

  useEffect(() => {
    if (!activeAssetInfo.assetData?.address) {
      navigate("/");
    }
  }, [activeAssetInfo, navigate]);

  return (
    <Paper elevation={0}>
      <Container maxWidth="xl">
        <Stack
          px={{ sx: 2, md: 3, lg: 4, xl: 5 }}
          py={2}
          flexDirection={"row"}
          gap={2}
          flexWrap={"wrap"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Box display={"flex"} gap={2}>
            <img
              src={activeAssetInfo.assetData?.img}
              width={40}
              height={40}
              alt={activeAssetInfo.assetData?.name}
            />
            <Box>
              <Typography color={"grey"} noWrap>
                {activeAssetInfo.assetData?.name}
              </Typography>
              <Typography variant="body2" color={"white"}>
                {activeAssetInfo.assetData?.symbol}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Typography color="grey">total supply</Typography>
            <Typography variant="h6" color="white">
              $ {values[0]}
            </Typography>
          </Box>
          <Box>
            <Typography color="grey">total borrowed</Typography>
            <Typography variant="h6" color="white">
              $ {values[1]}
            </Typography>
          </Box>
          <Box>
            <Typography color="grey">markets</Typography>
            <Typography variant="h6" color="white">
              {aggregateInfoLoading ? "--" : aggregateInfo?.orders}
            </Typography>
          </Box>
          <Box>
            <Typography color="grey">active loans</Typography>
            <Typography variant="h6" color="white">
              {aggregateInfoLoading ? "--" : aggregateInfo?.activeLoans}
            </Typography>
          </Box>
          <Box>
            <Typography color="grey">lenders</Typography>
            <Typography variant="h6" color="white">
              {aggregateInfoLoading ? "--" : aggregateInfo?.lenders}
            </Typography>
          </Box>
          <Box>
            <Typography color="grey">borrowers</Typography>
            <Typography variant="h6" color="white">
              {aggregateInfoLoading ? "--" : aggregateInfo?.borrowers}
            </Typography>
          </Box>
        </Stack>
      </Container>
    </Paper>
  );
}

export default MarketInfo;

import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useWeb3Modal } from "@web3modal/react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAccount, useChainId, useSwitchNetwork } from "wagmi";
import { shortenAddress } from "../helpers/utils/format";
import { useCurrentPage } from "../hooks/useCurrentPage";
import { pageRouters, pages } from "../config/menu";
import { usePharosUser } from "../hooks/apis";
import { useAppSelector } from "../redux/store";

function ResponsiveAppBar() {
  const { open } = useWeb3Modal();
  const userAddress = useAppSelector((state) => state.user.address);
  const chainId = useChainId();
  const { switchNetwork } = useSwitchNetwork();
  const { isConnected, address, isReconnecting } = useAccount();
  const navigate = useNavigate();
  const location = useLocation();

  const pageIndex = useCurrentPage(location);
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  usePharosUser(isConnected, address, isReconnecting, chainId);

  const handleCloseNavMenu = async (event: React.MouseEvent<HTMLElement>) => {
    if (!userAddress && event.currentTarget.tabIndex === 2) {
      await open();
    } else {
      navigate(`${pageRouters[event.currentTarget.tabIndex]}`);
    }
    setAnchorElNav(null);
  };

  const handleConnect = async () => {
    await open();
  };

  React.useEffect(() => {
    if (Number(process.env.REACT_APP_CHAIN_ID) !== chainId) {
      switchNetwork?.(Number(process.env.REACT_APP_CHAIN_ID));
    }
  }, [chainId, switchNetwork]);

  return (
    <AppBar position="static" sx={{ bgcolor: "background.default" }} elevation={0}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontWeight: 700,
            }}
          >
            <img src="/pharos_logo.png" alt="logo" width={150} />
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page, i) => (
                <MenuItem key={page} onClick={handleCloseNavMenu} tabIndex={i}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
            }}
          >
            <img src="/pharos_logo.png" alt="logo" width={130} />
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page, i) => (
              <Button
                key={page}
                tabIndex={i}
                onClick={handleCloseNavMenu}
                sx={{
                  my: 2,
                  color: i === pageIndex ? "primary.main" : "white.main",
                  display: "flex",
                  alignItems: "center",
                  fontSize: "20px",
                  borderRadius: 2,
                  py: 1,
                  px: 3,
                }}
                startIcon={i === pageIndex && <KeyboardArrowRightIcon />}
              >
                {page}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Button
              sx={{ borderRadius: 2, px: 2, py: 1, bgcolor: "background.paper", color: "white.main" }}
              onClick={handleConnect}
              startIcon={<></>}
            >
              <Typography textAlign="center" sx={{ fontSize: { xs: 12, md: 20 } }}>
                {isConnected ? shortenAddress(userAddress as string) : "connect wallet"}
              </Typography>
            </Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;

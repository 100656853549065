import React, { useState, useMemo } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  CircularProgress,
  FormControl,
  InputAdornment,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import { useAppDispatch, useAppSelector } from "../../redux/store";
import { useBookkeeperContract } from "../../hooks/contracts/useBookkeeperContract";
import { setOrderFillModalState } from "../../redux/reducers/order-slice";
import { Order } from "../../helpers/types/response";
import { getAssetData } from "../../helpers/utils/assets";
import { BootstrapTooltip } from "../Tooltip";
import RandomAvatar from "../RandomAvatar";
import { useNotification } from "../../hooks/useNotification";
import { OrderTypes } from "../../helpers/enums";
import { useNavigate } from "react-router-dom";
import { AssetType, PluginType } from "../../helpers/types/basic";
import { formatNumber } from "../../helpers/utils/format";

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ bgcolor: "background.paper", p: 3 }} {...other}>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 3,
            top: 3,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function OrderFillModal() {
  const userSlice = useAppSelector((state) => state.user);
  const orderFillModalState = useAppSelector((state) => state.order.orderFillModalState);
  const [selectedAccountId, setSelectedAccountId] = useState(0);
  const [collateralAssetsIdx, setCollateralAssetsIdx] = useState(0);
  const [collAmount, setCollAmount] = useState<number | undefined>();
  const [terminalIdx, setTerminalIdx] = useState(0);
  const [loanAmount, setLoanAmount] = useState<number | undefined>();
  const collAsset = useMemo(
    () =>
      getAssetData(
        orderFillModalState?.orderData.collateralAssets?.[collateralAssetsIdx],
        orderFillModalState?.orderData.chainId
      ),
    [
      collateralAssetsIdx,
      orderFillModalState?.orderData.chainId,
      orderFillModalState?.orderData.collateralAssets,
    ]
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { displayNotification } = useNotification();

  const tokenSymbol = useMemo(() => {
    return getAssetData(orderFillModalState?.orderData?.loanAsset, orderFillModalState?.orderData.chainId)
      ?.symbol;
  }, [orderFillModalState?.orderData.chainId, orderFillModalState?.orderData?.loanAsset]);

  const accountAmount = useMemo(() => {
    return (
      userSlice.accounts
        .find((account) => account.id === selectedAccountId)
        ?.assets.find((asset) => asset?.assetType?.addr === orderFillModalState.orderData?.loanAsset?.addr)
        ?.amount || 0
    );
  }, [orderFillModalState.orderData, selectedAccountId, userSlice.accounts]);

  const { fillOrder, isLoading } = useBookkeeperContract();

  const handleFill = async () => {
    if (!selectedAccountId) {
      displayNotification({ message: "Choose your account", type: "warning" });
      return;
    }
    if (!loanAmount) {
      displayNotification({ message: "Input loan amount", type: "warning" });
      return;
    }
    if (!collAmount) {
      displayNotification({ message: "Input collateral amount", type: "warning" });
      return;
    }
    try {
      await fillOrder(
        orderFillModalState?.orderData,
        selectedAccountId,
        loanAmount,
        collateralAssetsIdx,
        terminalIdx,
        collAmount
      );
      navigate("/");
    } catch (error: any) {
      displayNotification({ message: error.message, type: "error" });
    } finally {
      handleClose();
    }
  };

  const handleClose = () => {
    dispatch(setOrderFillModalState({ isOpened: false, orderData: {} as Order, available: 0 }));
  };
  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={orderFillModalState.isOpened || false}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}></BootstrapDialogTitle>
        <DialogContent
          sx={{
            bgcolor: "background.paper",
            width: { sm: "400px", md: "500px" },
            display: "flex",
            flexDirection: "column",
            gap: 3,
          }}
        >
          <Box>
            <FormControl fullWidth>
              <Select
                required
                variant="outlined"
                sx={{
                  borderRadius: 2,
                  border: 0,
                  bgcolor: "background.default",
                  "& fieldset": {
                    borderColor: "transparent",
                    borderRadius: 2,
                    border: 0,
                  },
                }}
                name="ordererId"
                inputProps={{ sx: { display: "flex" } }}
                onChange={(e) => setSelectedAccountId(Number(e.target.value))}
                renderValue={(selected) => {
                  if (!selected) {
                    return <em>select account</em>;
                  }
                  const index = userSlice.accounts.findIndex((account) => account.id === selected);
                  return userSlice.accounts[index]?.pluginName ?? `account #${index + 1}`;
                }}
                value={selectedAccountId}
              >
                <MenuItem value={0} disabled>
                  <ListItemText>
                    <em>select account</em>
                  </ListItemText>
                </MenuItem>
                {userSlice.accounts.map((account, i) => (
                  <MenuItem key={i} value={account.id}>
                    <ListItemText>{account?.pluginName || `account #${i + 1}`}</ListItemText>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box>
            <Typography variant="body2">loan amount</Typography>
            <TextField
              fullWidth
              variant="outlined"
              sx={{
                borderRadius: 2,
                my: 1,
                border: 0,
                bgcolor: "background.default",
                "& fieldset": {
                  borderColor: "transparent",
                  borderRadius: 2,
                  border: 0,
                },
                "& input": { fontSize: 14, py: 2 },
              }}
              color="primary"
              placeholder="enter amount"
              type="number"
              onChange={(e) => setLoanAmount(Number(e.target.value) || undefined)}
              value={loanAmount}
              InputProps={{
                inputProps: { style: { borderRadius: 5 } },
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography
                      fontSize={14}
                      borderLeft={1}
                      pl={2}
                      pr={1}
                      sx={{ opacity: 0.7 }}
                      color="main.white"
                    >
                      {tokenSymbol || "--"}
                    </Typography>
                  </InputAdornment>
                ),
              }}
            />
            <Typography variant="body2" color={"gray.main"}>
              available:{" "}
              {orderFillModalState?.orderData?.orderType === OrderTypes.LEND
                ? formatNumber(orderFillModalState.available)
                : formatNumber(Number(accountAmount))}{" "}
              {tokenSymbol}
            </Typography>
          </Box>
          {selectedAccountId !== 0 && (
            <>
              <Box>
                <Typography variant="body2">choose collateral asset</Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                  }}
                >
                  {orderFillModalState?.orderData?.collateralAssets?.map((asset: AssetType, colAssetIx: number) => {
                    const assetData = getAssetData(asset, orderFillModalState?.orderData.chainId);
                    if (asset.standard === 3 && asset.tokenId === "0") {
                      const index = userSlice.accounts.findIndex(
                        (account) => account.id === selectedAccountId
                      );
                      return userSlice.accounts[index]?.assets
                        .filter((userAsset) => userAsset.assetType.addr === asset.addr)
                        .map((selectedAccAsset, i) => {
                          const userAssetData = getAssetData(
                            selectedAccAsset.assetType,
                            orderFillModalState?.orderData.chainId
                          );
                          return (
                            <BootstrapTooltip key={i} title={selectedAccAsset.assetType.tokenId} arrow>
                              <Box
                                position={"relative"}
                                sx={{
                                  cursor: "pointer",

                                  opacity: collateralAssetsIdx === colAssetIx ? 1 : 0.5,
                                }}
                              >
                                <img
                                  src={userAssetData?.img}
                                  alt={userAssetData?.name}
                                  onClick={() => setCollateralAssetsIdx(colAssetIx)}
                                />
                              </Box>
                            </BootstrapTooltip>
                          );
                        });
                    } else {
                      return (
                        <BootstrapTooltip key={colAssetIx} title={asset.tokenId} arrow>
                          <Box
                            position={"relative"}
                            sx={{
                              cursor: "pointer",

                              opacity: collateralAssetsIdx === colAssetIx ? 1 : 0.5,
                            }}
                          >
                            <img
                              src={assetData?.img}
                              alt={assetData?.name}
                              onClick={() => setCollateralAssetsIdx(colAssetIx)}
                            />
                          </Box>
                        </BootstrapTooltip>
                      );
                    }
                  })}
                </Box>
              </Box>
              {orderFillModalState.orderData?.orderType === OrderTypes.LEND && (
                <Box>
                  <Typography variant="body2">collateral amount</Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    value={collAmount}
                    onChange={(e) => setCollAmount(Number(e.target.value) || undefined)}
                    sx={{
                      borderRadius: 2,
                      my: 1,
                      border: 0,
                      bgcolor: "background.default",
                      "& fieldset": {
                        borderColor: "transparent",
                        borderRadius: 2,
                        border: 0,
                      },
                      "& input": { fontSize: 14, py: 2 },
                    }}
                    type="number"
                    color="primary"
                    placeholder="enter amount"
                    InputProps={{
                      inputProps: { style: { borderRadius: 5 } },
                      endAdornment: (
                        <InputAdornment position="end">
                          <Typography
                            fontSize={14}
                            borderLeft={1}
                            pl={2}
                            pr={1}
                            sx={{ opacity: 0.7 }}
                            color="white.main"
                          >
                            {collAsset?.symbol || "--"}
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Typography variant="body2" color={"gray.main"}>
                    MCR: {orderFillModalState?.orderData.minCollateralRatio[collateralAssetsIdx]}%
                  </Typography>
                </Box>
              )}
            </>
          )}
          <Box>
            <Typography variant="body2">choose position</Typography>
            <Box sx={{ display: "flex", gap: 1 }}>
              {orderFillModalState.orderData.terminals?.map((terminal: PluginType, i: number) => (
                <BootstrapTooltip key={i} title={terminal?.pluginName} arrow>
                  <Box
                    component={"span"}
                    sx={{
                      cursor: "pointer",
                      borderRadius: "50%",
                      height: 52,
                      width: 52,
                      border: "1.5px solid transparent",
                      "&:hover": {
                        zIndex: 2,
                        borderColor: "primary.main",
                      },
                      opacity: terminalIdx === i ? 1 : 0.5,
                    }}
                    onClick={() => setTerminalIdx(i)}
                  >
                    <RandomAvatar address={terminal.pluginAddress} size={50} />
                  </Box>
                </BootstrapTooltip>
              ))}
            </Box>
          </Box>
          <Box>
            <LoadingButton
              fullWidth
              loading={isLoading}
              loadingIndicator={<CircularProgress color="inherit" size={28} />}
              sx={{
                py: 2,
                paddingX: 5,
                borderRadius: 2,
                bgcolor: "primary.main",
                color: "background.default",
                "&:hover": {
                  bgcolor: "background.default",
                  color: "white.main",
                },
              }}
              onClick={() => handleFill()}
            >
              confirm
            </LoadingButton>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

import { encodeAbiParameters, parseAbiParameters, parseUnits, stringToHex } from "viem";
import { useCallback, useState } from "react";
import { useAppSelector } from "../../redux/store";
import { BookkeeperContractAddress, HashZero } from "../../helpers/constants/address";
import { readContract, signTypedData } from "@wagmi/core";
import { BookkeeperABI } from "../../helpers/abis";
import { OrderStruct } from "../../helpers/abis";
import { OrderTypes } from "../../helpers/enums";
import { AssetParameters } from "../../config/parameter-abis";

type IPlugin = {
  addr: `0x${string}`;
  parameters: `0x${string}`;
};

export const useOrderData = (step: number) => {
  const userState = useAppSelector((state) => state.user);
  const createOrderData = useAppSelector((state) => state.order.orderCreationForm);

  const order = useCallback(() => {
    const loanAssets: `0x${string}`[] = [];
    const loanOracles: IPlugin[] = [];
    const minLoanAmounts: bigint[] = [];
    // eslint-disable-next-line array-callback-return
    createOrderData?.loanAssetsInfo?.map((assetInfo: any) => {
      minLoanAmounts.push(parseUnits(assetInfo.minLoanAmount.toString(), 18));
      loanAssets.push(
        encodeAbiParameters(parseAbiParameters(AssetParameters), [[
          assetInfo.asset.standard,
          assetInfo.asset.addr,
          assetInfo.asset.decimals,
          0n,
          HashZero,
        ]])
      );
      const oracle = userState.plugins.find((plugin) => plugin.id === assetInfo?.oracleId);
      if (!oracle) {
        throw new Error("Not found oracle");
      }
      loanOracles.push({
        addr: oracle.pluginAddress,
        parameters: oracle.parameters,
      });
    });
    const collAssets: `0x${string}`[] = [];
    const collOracles: IPlugin[] = [];
    const minCollateralRatio: bigint[] = [];
    // eslint-disable-next-line array-callback-return
    createOrderData?.collateralAssetsInfo?.map((assetInfo: any) => {
      collAssets.push(
        encodeAbiParameters(parseAbiParameters(AssetParameters), [[
          assetInfo.asset.standard,
          assetInfo.asset.addr,
          assetInfo.asset.decimals,
          0n,
          HashZero,
        ]])
      );
      const oracle = userState.plugins.find((plugin) => plugin.id === assetInfo?.oracleId);
      if (!oracle) {
        throw new Error("Not found oracle");
      }
      collOracles.push({
        addr: oracle.pluginAddress,
        parameters: oracle.parameters,
      });
      minCollateralRatio.push(parseUnits(`${assetInfo.mcr / 100}`, 18));
    });

    const selectedAccount = userState.accounts.find((account) => account.id === createOrderData?.ordererId);
    if (!selectedAccount) {
      throw new Error("Not found account");
    }
    const account = {
      addr: selectedAccount.pluginAddress,
      parameters: selectedAccount.parameters,
    };

    const selectedCollector = userState.plugins.find((plugin) => plugin.id === createOrderData?.collectorId);
    if (!selectedCollector) {
      throw new Error("Not found assessor");
    }
    const selectedCloser = userState.plugins.find((plugin) => plugin.id === createOrderData?.closerId);
    if (!selectedCloser) {
      throw new Error("Not found liquidation");
    }

    const factories = createOrderData?.terminalIds?.map(
      (id: number) => userState.plugins.find((plugin) => plugin.id === id)?.pluginAddress
    );

    const borrowerConfig = {
      collAmount:
        createOrderData.orderType === OrderTypes.LEND
          ? 0n
          : parseUnits(
              createOrderData?.collAmount.toString(),
              18
            ),
      positionParameters:
        createOrderData.orderType === OrderTypes.LEND
          ? stringToHex("")
          : encodeAbiParameters(parseAbiParameters("address"), [userState.address]),
    };
    const rawOrderData = {
      minLoanAmounts,
      loanAssets,
      collAssets,
      minCollateralRatio,
      fillers: createOrderData.takers,
      isLeverage: createOrderData.isLeverage,
      maxDuration:
        createOrderData.durationUnit === Number.MAX_SAFE_INTEGER
          ? 2n ** 256n - 1n
          : BigInt(createOrderData.duration * createOrderData.durationUnit),
      account,
      assessor: {
        addr: selectedCollector.pluginAddress,
        parameters: selectedCollector.parameters,
      },
      liquidator: {
        addr: selectedCloser.pluginAddress,
        parameters: selectedCloser.parameters,
      },
      loanOracles,
      collOracles,
      factories,
      isOffer: createOrderData.orderType === OrderTypes.LEND,
      borrowerConfig,
    };

    // Log the raw order data
    console.log('Raw Order Data:', rawOrderData);
    
    return encodeAbiParameters(OrderStruct, [
      {
        minLoanAmounts,
        loanAssets,
        collAssets,
        minCollateralRatio,
        fillers: createOrderData.takers,
        isLeverage: createOrderData.isLeverage,
        maxDuration:
          createOrderData.durationUnit === Number.MAX_SAFE_INTEGER
            ? 2n ** 256n - 1n
            : BigInt(createOrderData.duration * createOrderData.durationUnit),
        account,
        assessor: {
          addr: selectedCollector.pluginAddress,
          parameters: selectedCollector.parameters,
        },
        liquidator: {
          addr: selectedCloser.pluginAddress,
          parameters: selectedCloser.parameters,
        },
        loanOracles,
        collOracles,
        factories,
        isOffer: createOrderData.orderType === OrderTypes.LEND,
        borrowerConfig,
      },
    ]);
  }, [createOrderData, userState]);

  return { orderData: step === 7 ? order() : undefined };
};

export const useBlueprintData = (chainId: number) => {
  const [isLoading, setIsLoading] = useState(false);
  const getBlueprintData = useCallback(
    async (orderData: `0x${string}`, publisher: `0x${string}`) => {
      try {
        setIsLoading(true);

        const domain = {
          name: process.env.REACT_APP_NAME,
          version: process.env.REACT_APP_VERSION,
          chainId: chainId ?? Number(process.env.REACT_APP_CHAIN_ID),
          verifyingContract: BookkeeperContractAddress[chainId],
        } as const;

        const types = {
          Blueprint: [
            { name: "publisher", type: "address" },
            { name: "data", type: "bytes" },
            { name: "maxNonce", type: "uint256" },
            { name: "startTime", type: "uint256" },
            { name: "endTime", type: "uint256" },
          ],
        } as const;

        const packedDataField = await readContract({
          address: BookkeeperContractAddress[chainId],
          abi: BookkeeperABI,
          functionName: "packDataField",
          args: ["0x01", orderData],
        });
        const blueprint = {
          publisher,
          data: packedDataField,
          maxNonce: 2n ** 24n, // replace with the maximum nonce value
          startTime: 0n, // replace with the start time (in Unix timestamp format)
          endTime: 2n ** 24n, // replace with the end time (in Unix timestamp format)
        };
        const blueprintHash = await readContract({
          address: BookkeeperContractAddress[chainId],
          abi: BookkeeperABI,
          functionName: "getBlueprintHash",
          args: [blueprint],
        });
        const message = {
          ...blueprint,
        } as const;

        const signature = await signTypedData({
          domain,
          message,
          primaryType: "Blueprint",
          types,
        });

        return {
          blueprintData: {
            blueprint: {
              publisher,
              data: packedDataField,
              maxNonce: 2 ** 24, // replace with the maximum nonce value
              startTime: 0, // replace with the start time (in Unix timestamp format)
              endTime: 2 ** 24,
            },
            blueprintHash,
            signature,
          },
          message: {
            domain,
            types,
            value: {
              publisher,
              data: packedDataField,
              maxNonce: 2 ** 24, // replace with the maximum nonce value
              startTime: 0, // replace with the start time (in Unix timestamp format)
              endTime: 2 ** 24,
            },
          },
        };
      } catch (error: any) {
        console.error(error.message);
      } finally {
        setIsLoading(false);
      }
    },
    [chainId]
  );

  return { getBlueprintData, isLoading };
};

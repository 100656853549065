import React, { useMemo } from "react";
import { Container, Paper, Stack, Typography, Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { useAppSelector } from "../../redux/store";
import { shortenAddress } from "../../helpers/utils/format";
import { PluginColors } from "../../helpers/constants/plugin-colors";
import { useNavigate } from "react-router-dom";

const PluginList = () => {
  const userSlice = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  const defaultPlugins = useMemo(
    () => userSlice.plugins.filter((plugin) => plugin.creatorAddress === null),
    [userSlice]
  );

  const customPlugins = useMemo(
    () => userSlice.plugins.filter((plugin) => plugin.creatorAddress !== null),
    [userSlice]
  );
  return (
    <Container maxWidth="xl" sx={{ mt: 10 }}>
      <Grid container rowSpacing={3}>
        {userSlice.accounts.length !== 0 && (
          <Grid xs={12}>
            <Typography sx={{ px: 2 }}>my accounts</Typography>
          </Grid>
        )}
        {userSlice.accounts.map((account, i) => (
          <Grid key={account.id} xs={12} sm={6} md={4} lg={3}>
            <Paper
              elevation={0}
              component={Box}
              onClick={() => navigate("/account")}
              sx={{
                marginX: "auto",
                padding: 2,
                maxWidth: 300,
                borderRadius: 2,
                cursor: "pointer",
                border: "1px solid transparent",
                ":hover": { border: `1px solid ${PluginColors.account}` },
              }}
            >
              <Stack flexDirection={"row"} justifyContent={"space-between"}>
                <Typography color={PluginColors.account}>account</Typography>
                <Box sx={{ borderRadius: 100, bgcolor: PluginColors.account, width: 30, height: 30 }}></Box>
              </Stack>
              <Typography variant="body2" color="gray.main">
                {shortenAddress(account.pluginAddress)}
              </Typography>
              <Typography mt={2}>account {i + 1}</Typography>
            </Paper>
          </Grid>
        ))}
        {defaultPlugins.length !== 0 && (
          <Grid xs={12}>
            <Typography sx={{ px: 2 }}>default plugins</Typography>
          </Grid>
        )}
        {defaultPlugins.map((plugin) => (
          <Grid key={plugin.id} xs={12} sm={6} md={4} lg={3}>
            <Paper
              elevation={0}
              component={Box}
              onClick={() => navigate(`/plugin/${plugin.id}`)}
              sx={{
                marginX: "auto",
                padding: 2,
                maxWidth: 300,
                borderRadius: 2,
                cursor: "pointer",
                border: "1px solid transparent",
                ":hover": { border: `1px solid ${PluginColors[plugin.pluginType]}` },
              }}
            >
              <Stack flexDirection={"row"} justifyContent={"space-between"}>
                <Typography color={PluginColors[plugin.pluginType]}>{plugin.pluginType}</Typography>
                <Box
                  sx={{
                    borderRadius: 100,
                    bgcolor: PluginColors[plugin.pluginType],
                    width: 30,
                    height: 30,
                  }}
                ></Box>
              </Stack>
              <Typography variant="body2" color="gray.main">
                {shortenAddress(plugin.pluginAddress)}
              </Typography>
              <Typography mt={2}>{plugin.name}</Typography>
            </Paper>
          </Grid>
        ))}
        {customPlugins.length !== 0 && (
          <Grid xs={12}>
            <Typography sx={{ px: 2 }}>my plugins</Typography>
          </Grid>
        )}
        {customPlugins.map((plugin) => (
          <Grid key={plugin.id} xs={12} sm={6} md={4} lg={3}>
            <Paper
              elevation={0}
              component={Box}
              onClick={() => navigate(`/plugin/${plugin.id}`)}
              sx={{
                marginX: "auto",
                padding: 2,
                maxWidth: 300,
                borderRadius: 2,
                cursor: "pointer",
                border: "1px solid transparent",
                ":hover": { border: `1px solid ${PluginColors[plugin.pluginType]}` },
              }}
            >
              <Stack flexDirection={"row"} justifyContent={"space-between"}>
                <Typography color={PluginColors[plugin.pluginType]}>{plugin.pluginType}</Typography>
                <Box
                  sx={{
                    borderRadius: 100,
                    bgcolor: PluginColors[plugin.pluginType],
                    width: 30,
                    height: 30,
                  }}
                ></Box>
              </Stack>
              <Typography variant="body2" color="gray.main">
                {shortenAddress(plugin.pluginAddress)}
              </Typography>
              <Typography mt={2}>{plugin.name}</Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default PluginList;

import React from "react";
import {
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useActivity } from "../../hooks/apis";
import { convertDateType, shortenAddress } from "../../helpers/utils/format";
import { useAppSelector } from "../../redux/store";
import { ADDRESS_TO_PAIR } from "../../helpers/constants/tokens";
import PageMeta from "../../layout/PageMeta";
import { PageName } from "../../helpers/enums";
import { useNetwork } from "wagmi";
import Link from "@mui/material/Link";
import { NavLink } from "react-router-dom";

function Activity() {
  const tokenPrices = useAppSelector((state) => state.app.tokenPrices);
  const { chain } = useNetwork();
  const { activities, isLoading } = useActivity(chain?.id);
  return (
    <Container maxWidth="xl" sx={{ py: 5 }}>
      <PageMeta pageName={PageName.ACTIVITY} />
      <TableContainer component={Paper} sx={{ borderRadius: 2 }} elevation={0}>
        <Table sx={{ minWidth: 650 }} aria-label="market table">
          <TableHead>
            <TableRow sx={{ "td,th": { border: 0 } }}>
              <TableCell sx={{ fontSize: 14 }}>time</TableCell>
              <TableCell sx={{ fontSize: 14 }}>lender</TableCell>
              <TableCell sx={{ fontSize: 14 }}>borrower</TableCell>
              <TableCell sx={{ fontSize: 14 }}>agreement</TableCell>
              <TableCell sx={{ fontSize: 14 }}>value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow sx={{ "td,th": { border: 0 } }}>
                <TableCell>Loading</TableCell>
              </TableRow>
            ) : (
              <>
                {activities.length ? (
                  activities.map((activity, i) => {
                    return (
                      <TableRow hover key={i} sx={{ td: { border: 0 }, textDecoration: "none" }}>
                        <TableCell
                          scope="row"
                          sx={{ fontSize: 16, display: "flex", gap: 1, alignItems: "center" }}
                        >
                          {convertDateType(activity?.time)}
                        </TableCell>
                        <TableCell sx={{ fontSize: 16 }}>
                          <Link
                            color={"white.main"}
                            target="_blank"
                            rel="noreferrer"
                            underline="hover"
                            href={`${chain?.blockExplorers?.default.url}/address/${activity.lender}`}
                          >
                            {shortenAddress(activity.lender)}
                          </Link>
                        </TableCell>
                        <TableCell sx={{ fontSize: 16 }}>
                          <Link
                            color={"white.main"}
                            target="_blank"
                            rel="noreferrer"
                            underline="hover"
                            href={`${chain?.blockExplorers?.default.url}/address/${activity.borrower}`}
                          >
                            {shortenAddress(activity.borrower)}
                          </Link>
                        </TableCell>
                        <TableCell sx={{ fontSize: 16 }}>
                          <NavLink
                            className={"agreement-link"}
                            rel="noreferrer"
                            to={`/markets/agreement/${activity.agreement}`}
                          >
                            {shortenAddress(activity.agreement)}
                          </NavLink>
                        </TableCell>
                        {/* <TableCell sx={{ fontSize: 16 }}>
                          {activity.value} {assetData?.symbol}
                        </TableCell> */}
                        <TableCell sx={{ fontSize: 16 }}>
                          $
                          {parseFloat(
                            (
                              (activity.value ?? 0) *
                              (tokenPrices[ADDRESS_TO_PAIR[activity.loanAsset.addr]] ?? 0)
                            ).toFixed(2)
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell sx={{ px: 2 }}>no activities</TableCell>
                  </TableRow>
                )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}

export default Activity;

import React from "react";
import {
  Box,
  Button,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { getAssetData } from "../../helpers/utils/assets";
import RandomAvatar from "../../components/RandomAvatar";
import { OrderTypes } from "../../helpers/enums";
import { BootstrapTooltip } from "../../components/Tooltip";
import { Order } from "../../helpers/types/response";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { setOrderFillModalState } from "../../redux/reducers/order-slice";
import { useNavigate } from "react-router-dom";
import { useNotification } from "../../hooks/useNotification";

type Props = {
  orderDetail: Order[];
  isLoading: boolean;
  orderType: OrderTypes;
  setOrderType: (type: OrderTypes) => void;
  availableAmounts: number[];
};

function OrderDetailTable({ orderDetail, isLoading, orderType, setOrderType, availableAmounts }: Props) {
  const accounts = useAppSelector((state) => state.user.accounts);
  const dispatch = useAppDispatch();
  const { displayNotification } = useNotification();
  const navigate = useNavigate();

  // const fee = useCallback((order: Order) => {
  //   const parsedData = decodeAbiParameters(
  //     parseAbiParameters(AssessorParameters),
  //     order?.assessor.parameters
  //   );
  //   return {
  //     originationFeeRatio: parseFloat(formatUnits(parsedData[0].originationFeeRatio, 18)),
  //     interestRatio: parseFloat(formatUnits(parsedData[0].interestRatio, 18)),
  //     profitShareRatio: parseFloat(formatUnits(parsedData[0].profitShareRatio, 18)),
  //   };
  // }, []);

  const handleFillButton = (order: Order, available: number) => {
    if (accounts.length > 0) {
      dispatch(setOrderFillModalState({ isOpened: true, orderData: order, available }));
    } else {
      displayNotification({ message: "Please create accounts first", type: "info" });
      navigate("/account");
    }
  };

  const handleDetailViewButton = (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>, order: Order) => {
    if ((e.target as HTMLElement).tagName !== "BUTTON") {
      const assetData = getAssetData(order.loanAsset, order.chainId);
      navigate(`/markets/${assetData?.address}/${order.blueprintData.blueprintHash}?tokenId=${order.loanAsset.tokenId}`);
    }
  };

  const durationToDay = (duration: number) => {
    if (Number(duration) === Number.MAX_SAFE_INTEGER) {
      return "infinity";
    } else {
      return parseFloat((duration / 60 / 60 / 24).toFixed(2));
    }
  };

  return (
    <Container maxWidth="xl" sx={{ py: 5 }}>
      <Box py={2}>
        <Button
          startIcon={orderType === OrderTypes.LEND ? <>&gt;</> : <></>}
          onClick={() => setOrderType(OrderTypes.LEND)}
          sx={{
            color: orderType === OrderTypes.LEND ? "primary" : "gray.main",
          }}
        >
          borrow
        </Button>
        <Button
          startIcon={orderType === OrderTypes.BORROW ? <>&gt;</> : <></>}
          onClick={() => setOrderType(OrderTypes.BORROW)}
          sx={{
            color: orderType === OrderTypes.BORROW ? "primary" : "gray.main",
          }}
        >
          supply
        </Button>
      </Box>
      <TableContainer component={Paper} sx={{ borderRadius: 2 }} elevation={0}>
        <Table sx={{ minWidth: 650 }} aria-label="market table">
          <TableHead>
            <TableRow sx={{ "td,th": { border: 0 } }}>
              <TableCell sx={{ fontSize: 14 }}>duration (days)</TableCell>
              <TableCell sx={{ fontSize: 14 }}>min.amount</TableCell>
              {orderType === OrderTypes.LEND && <TableCell sx={{ fontSize: 14 }}>available</TableCell>}
              {/* <TableCell sx={{ fontSize: 14 }}>borrow APY</TableCell>
              <TableCell sx={{ fontSize: 14 }}>loan fee</TableCell>
              <TableCell sx={{ fontSize: 14 }}>profit share</TableCell> */}
              {/* <TableCell sx={{ fontSize: 14 }}>MCR</TableCell> */}
              {orderType === OrderTypes.BORROW && <TableCell sx={{ fontSize: 14 }}>ICR</TableCell>}
              <TableCell sx={{ fontSize: 14 }}>oracle</TableCell>
              <TableCell sx={{ fontSize: 14 }}>collateral</TableCell>
              <TableCell sx={{ fontSize: 14 }}>assessor</TableCell>
              <TableCell sx={{ fontSize: 14 }}>liquidation</TableCell>
              <TableCell sx={{ fontSize: 14 }}>position</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          {isLoading ? (
            <TableBody>
              <TableRow sx={{ td: { border: 0 }, textDecoration: "none" }}>
                <TableCell sx={{ fontSize: 16 }}>Loading...</TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {orderDetail.length ? (
                orderDetail.map((order, i) => {
                  // const fees = fee(order);
                  return (
                    <TableRow
                      hover
                      onClick={(e) => handleDetailViewButton(e, order)}
                      key={order.id}
                      sx={{ td: { border: 0 }, textDecoration: "none", cursor: "pointer" }}
                    >
                      <TableCell sx={{ fontSize: 16 }}>{durationToDay(order.duration)}</TableCell>
                      <TableCell sx={{ fontSize: 16 }}>{order.minLoanAmount}</TableCell>
                      {orderType === OrderTypes.LEND && (
                        <TableCell sx={{ fontSize: 16 }}>
                          {parseFloat(availableAmounts[i].toFixed(4))}
                        </TableCell>
                      )}
                      {/* 1 years*/}
                      {/* <TableCell sx={{ fontSize: 16 }}>{fees.interestRatio * 31536000}</TableCell>
                      <TableCell sx={{ fontSize: 16 }}>{fees.originationFeeRatio}</TableCell>
                      <TableCell sx={{ fontSize: 16 }}>{fees.profitShareRatio}</TableCell> */}
                      {/* <TableCell sx={{ fontSize: 16 }}>{order.minCollateralRatio / 100}</TableCell> */}
                      {orderType === OrderTypes.BORROW && (
                        <TableCell sx={{ fontSize: 16 }}>{order?.initialCollateralRatio / 100}</TableCell>
                      )}
                      <TableCell sx={{ fontSize: 16 }}>{order.loanOracle?.pluginName}</TableCell>
                      <TableCell>
                        <Box sx={{ display: "flex" }}>
                          {order.collateralAssets.length
                            ? order.collateralAssets?.map((asset, i) => {
                                const data = getAssetData(asset, order.chainId);
                                return (
                                  <BootstrapTooltip key={i} title={data?.name} arrow>
                                    <img
                                      style={{
                                        translate: -(8 * i),
                                        borderRadius: "50%",
                                        cursor: "pointer",
                                      }}
                                      width={32}
                                      src={data?.img}
                                      alt="coll_image"
                                    />
                                  </BootstrapTooltip>
                                );
                              })
                            : ""}
                        </Box>
                      </TableCell>
                      <TableCell sx={{ fontSize: 16 }}>{order.collector?.pluginName}</TableCell>
                      <TableCell sx={{ fontSize: 16 }}>{order.closer?.pluginName}</TableCell>
                      <TableCell>
                        <Box sx={{ display: "flex" }}>
                          {order.terminals.length
                            ? order.terminals?.map((terminal, i) => (
                                <BootstrapTooltip key={i} title={terminal?.pluginName} arrow>
                                  <Box
                                    component={"span"}
                                    sx={{
                                      cursor: "pointer",
                                      borderRadius: "50%",
                                      height: 34,
                                      translate: -(10 * i),
                                      border: "1.5px solid transparent",
                                      "&:hover": {
                                        zIndex: 2,
                                        borderColor: "primary.main",
                                      },
                                    }}
                                  >
                                    <RandomAvatar address={terminal.pluginAddress} size={32} />
                                  </Box>
                                </BootstrapTooltip>
                              ))
                            : ""}
                        </Box>
                      </TableCell>
                      <TableCell sx={{ fontSize: 16 }}>
                        {/* {order.isFilled ? (
                        <Button disabled color="primary">
                          filled
                        </Button>
                      ) : ( */}
                        <Button onClick={() => handleFillButton(order, availableAmounts[i])} color="primary">
                          fill
                        </Button>
                        {/* )} */}
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow sx={{ td: { border: 0 }, textDecoration: "none" }}>
                  <TableCell sx={{ fontSize: 16 }}>no markets</TableCell>
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </Container>
  );
}

export default OrderDetailTable;

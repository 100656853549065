import { useState, useEffect } from "react";
import { Agreement } from "../../helpers/types/response";
import { getAgreements } from "../../helpers/utils/api";

export const useAgreements = (
  ordererAddress: string | undefined,
  chainId = Number(process.env.REACT_APP_CHAIN_ID)
) => {
  const [agreements, setAgreements] = useState<Agreement[]>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const agreements = await getAgreements(ordererAddress, chainId);
        setAgreements(agreements);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [chainId, ordererAddress]);

  return { agreements, isLoading };
};

import React from "react";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CreatePanel from "../../components/CreatePanel";
import StepTracker from "../../components/CreatePanel/StepTracker";

function CreateOrder() {
  return (
    <Container maxWidth="lg">
      <Stack flexDirection={"row"} mt={6} mb={2} alignItems={'flex-end'}>
        <Typography variant="h6" color="white.main">
          new market
        </Typography>
        <StepTracker />
      </Stack>
      <CreatePanel />
    </Container>
  );
}

export default CreateOrder;

import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { writeContract, waitForTransaction, readContract, signTypedData } from "@wagmi/core";
import { useNetwork } from "wagmi";
import { encodeAbiParameters, parseAbiParameters, parseUnits, stringToHex } from "viem";

import { BookkeeperABI, ERC20ABI } from "../../helpers/abis";
import { BookkeeperContractAddress } from "../../helpers/constants/address";
import { useNotification } from "../useNotification";
import { Agreement, Order } from "../../helpers/types/response";
import { useAppSelector } from "../../redux/store";
import { OrderTypes } from "../../helpers/enums";

export const useBookkeeperContract = () => {
  const { displayNotification } = useNotification();
  const userSlice = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  const { chain } = useNetwork();
  const [isLoading, setIsLoading] = useState(false);

  const fillOrder = useCallback(
    async (
      orderData: Order,
      selectedAccountId: number,
      loanAmount: number,
      collateralAssetsIdx: number,
      terminalIdx: number,
      collAmount: number | undefined
    ) => {
      try {
        setIsLoading(true);
        if (!userSlice.address) {
          throw new Error("Undefined publisher");
        }
        if (!chain) {
          throw new Error("Undefined Chain");
        }

        const selectedAccount = userSlice.accounts.find((account) => account.id === selectedAccountId);
        if (!selectedAccount) {
          throw new Error("Not found account");
        }
        const account = {
          addr: selectedAccount.pluginAddress,
          parameters: selectedAccount.parameters,
        };
        const takerIdx = orderData.takers.length ? orderData.takers.indexOf(userSlice.address) : 0;
        if (takerIdx === -1) {
          throw new Error("Invalid taker");
        }
        if (!collAmount) {
          throw new Error("Input collateral amount");
        }
        const borrowerConfig = {
          collAmount: orderData.orderType === OrderTypes.BORROW ? 0n : parseUnits(`${collAmount}`, 18),
          positionParameters:
            orderData.orderType === OrderTypes.BORROW
              ? stringToHex("")
              : encodeAbiParameters(parseAbiParameters("address"), [userSlice.address]),
        };
        const tokenId = selectedAccount.assets.find(
          (asset) => asset.assetType.addr === orderData.collateralAssets?.[collateralAssetsIdx].addr
        )?.assetType.tokenId;
        const fill = {
          account,
          loanAmount: parseUnits(`${loanAmount}`, 18),
          takerIdx: BigInt(takerIdx),
          loanAssetIdx: BigInt(orderData.loanIdx),
          collAssetIdx: BigInt(collateralAssetsIdx),
          factoryIdx: BigInt(terminalIdx),
          fillerData: encodeAbiParameters(parseAbiParameters("(uint256, address)"), [
            [BigInt(tokenId ?? 0), selectedAccount.creatorAddress],
          ]),
          isOfferFill: true,
          borrowerConfig,
        };
        const { hash: fillOrderHash } = await writeContract({
          address: BookkeeperContractAddress[chain.id],
          abi: BookkeeperABI,
          functionName: "fillOrder",
          args: [
            fill,
            {
              blueprint: {
                publisher: orderData.blueprintData.blueprint.publisher,
                data: orderData.blueprintData.blueprint.data,
                maxNonce: BigInt(orderData.blueprintData.blueprint.maxNonce),
                startTime: BigInt(orderData.blueprintData.blueprint.startTime),
                endTime: BigInt(orderData.blueprintData.blueprint.endTime),
              },
              blueprintHash: orderData.blueprintData.blueprintHash,
              signature: orderData.blueprintData.signature,
            },
          ],
        });
        await waitForTransaction({ hash: fillOrderHash });
        displayNotification({ message: "Successfully Filled!", type: "success" });
      } catch (error: any) {
        console.error(error);
        displayNotification({ message: error.message, type: "error" });
      } finally {
        setIsLoading(false);
      }
    },
    [chain, displayNotification, userSlice.accounts, userSlice.address]
  );

  const closeOrder = useCallback(
    async (agreement: Agreement, cost: any) => {
      try {
        setIsLoading(true);
        if (!userSlice.address) {
          throw new Error("Undefined publisher");
        }
        if (!chain) {
          throw new Error("Undefined Chain");
        }
        const domain = {
          name: process.env.REACT_APP_NAME,
          version: process.env.REACT_APP_VERSION,
          chainId: chain?.id ?? Number(process.env.REACT_APP_CHAIN_ID),
          verifyingContract: BookkeeperContractAddress[chain?.id],
        } as const;

        const types = {
          Blueprint: [
            { name: "publisher", type: "address" },
            { name: "data", type: "bytes" },
            { name: "maxNonce", type: "uint256" },
            { name: "startTime", type: "uint256" },
            { name: "endTime", type: "uint256" },
          ],
        } as const;

        const blueprint = {
          publisher: userSlice.address,
          data: agreement.agreementData,
          maxNonce: 2n ** 24n, // replace with the maximum nonce value
          startTime: 0n, // replace with the start time (in Unix timestamp format)
          endTime: 2n ** 24n, // replace with the end time (in Unix timestamp format)
        };
        const blueprintHash = await readContract({
          address: BookkeeperContractAddress[chain.id],
          abi: BookkeeperABI,
          functionName: "getBlueprintHash",
          args: [blueprint],
        });
        const message = {
          ...blueprint,
        } as const;

        const signature = await signTypedData({
          domain,
          message,
          primaryType: "Blueprint",
          types,
        });
        // const allowance = await readContract({
        //   address: cost[0].addr,
        //   abi: erc20ABI,
        //   functionName: "allowance",
        //   args: [userSlice.address, agreement.positionAddress],
        // });

        // if (allowance < cost[1]) {
        const { hash: approveHash } = await writeContract({
          address: cost[0].addr,
          abi: ERC20ABI,
          functionName: "approve",
          args: [agreement.positionAddress, parseUnits(`100000000`, 18)],
        });
        await waitForTransaction({ hash: approveHash });
        // }
        const { hash: closeOrderHash } = await writeContract({
          address: BookkeeperContractAddress[chain.id],
          abi: BookkeeperABI,
          functionName: "closePosition",
          args: [
            {
              blueprint,
              blueprintHash,
              signature,
            },
          ],
        });
        await waitForTransaction({ hash: closeOrderHash });
        displayNotification({ message: "Order Closed!", type: "success" });
        navigate("/");
      } catch (error: any) {
        console.error(error.message);
      } finally {
        setIsLoading(false);
      }
    },
    [chain, displayNotification, navigate, userSlice.address]
  );

  return { fillOrder, closeOrder, isLoading };
};

import React from "react";
import { Typography } from "@mui/material";

function TextBanner() {
  return (
    <Typography color={"yellow"} textAlign={"center"} bgcolor={'background.paper'}>
      pharos is in alpha currently. this ui is provided for demo and testing purposes.
    </Typography>
  );
}

export default TextBanner;

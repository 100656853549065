import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button, Stack } from "@mui/material";
import { OrderTypes } from "../../../helpers/enums";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { fillCreationForm } from "../../../redux/reducers/order-slice";
import ActionButtons from "../ActionButtons";

function Step1() {
  const formData = useAppSelector((state) => state.order.orderCreationForm);
  const dispatch = useAppDispatch();
  const [orderTypeValue, setOrderTypeValue] = useState(formData?.orderType || "");

  useEffect(() => {
    dispatch(fillCreationForm(["orderType", orderTypeValue]));
  }, [dispatch, orderTypeValue]);

  return (
    <Box>
      <Typography variant="h6" color={"white.main"}>
        let's get started!
      </Typography>
      <Stack flexDirection={"row"} gap={5} mt={4}>
        <Button
          sx={{
            py: 2,
            paddingX: 5,
            borderRadius: 2,
            bgcolor: orderTypeValue === OrderTypes.LEND ? "primary.main" : "background.default",
            color: orderTypeValue === OrderTypes.LEND ? "background.default" : "white.main",
            "&:hover": {
              bgcolor: orderTypeValue === OrderTypes.LEND ? "primary.main" : "",
              color: orderTypeValue === OrderTypes.LEND ? "background.default" : "",
            },
          }}
          onClick={() => setOrderTypeValue(OrderTypes.LEND)}
        >
          i want to lend
        </Button>
        <Button
          sx={{
            py: 2,
            paddingX: 5,
            borderRadius: 2,
            bgcolor: orderTypeValue === OrderTypes.BORROW ? "primary.main" : "background.default",
            color: orderTypeValue === OrderTypes.BORROW ? "background.default" : "white.main",
            "&:hover": {
              bgcolor: orderTypeValue === OrderTypes.BORROW ? "primary.main" : "",
              color: orderTypeValue === OrderTypes.BORROW ? "background.default" : "",
            },
          }}
          onClick={() => setOrderTypeValue(OrderTypes.BORROW)}
        >
          i want to borrow
        </Button>
      </Stack>
      <ActionButtons step={1} disabled={orderTypeValue === ""} />
    </Box>
  );
}

export default Step1;

/* eslint-disable array-callback-return */
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";

import { Order } from "../../../helpers/types/response";
import { secondToDateType } from "../../../helpers/utils/format";
import { getAssetData } from "../../../helpers/utils/assets";

export default function OrdersTable({ orders }: { orders: Order[] }) {
  const navigate = useNavigate();
  const handleDetailViewButton = (order: Order) => {
    const assetData = getAssetData(order.loanAsset, order.chainId);
    navigate(`/markets/${assetData?.address}/${order.blueprintData.blueprintHash}?tokenId=${order.loanAsset.tokenId}`);
  };
  return (
    <TableContainer component={Paper} elevation={0} sx={{ borderRadius: 2 }}>
      <Table sx={{ minWidth: 650, bgcolor: "black.light" }} aria-label="asset table">
        <TableHead sx={{ bgcolor: "background.default" }}>
          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell>market type</TableCell>
            <TableCell align="right">public/private</TableCell>
            <TableCell align="right">duration</TableCell>
            {/* <TableCell align="right">MCR</TableCell> */}
            <TableCell align="right">ICR</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders?.length ? (
            <>
              {orders.map((order, i) => {
                const durationData = secondToDateType(order?.duration);
                return (
                  <TableRow
                    hover
                    key={i}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor: "pointer" }}
                    onClick={() => handleDetailViewButton(order)}
                  >
                    <TableCell>{order.orderType}</TableCell>
                    <TableCell align="right">{order.takers.length ? "private" : "public"}</TableCell>
                    <TableCell align="right">
                      {durationData.value} {durationData.unit}
                    </TableCell>
                    {/* <TableCell align="right">{order?.minCollateralRatio / 100}</TableCell> */}
                    <TableCell align="right">{order?.initialCollateralRatio / 100 || "--"}</TableCell>
                  </TableRow>
                );
              })}
            </>
          ) : (
            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell sx={{ px: 2 }}>no markets</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

import React from "react";
import { Stack, Typography } from "@mui/material";
import { useAppSelector } from "../../redux/store";

function StepTracker() {
  const orderState = useAppSelector((state) => state.order);
  return (
    <>
      <Stack flexDirection={"row"} gap={2} sx={{ marginLeft: "auto" }}>
        <Typography variant="h6" color="white.main">
          1
        </Typography>
        {Array(6)
          .fill("")
          .map((el, i) => (
            <Typography
              variant="h6"
              key={i}
              sx={{ opacity: i + 2 > orderState.step ? 0.5 : 1 }}
              color="white.main"
            >
              - {i + 2 === orderState.step && el} {i + 2}
            </Typography>
          ))}
      </Stack>
    </>
  );
}

export default StepTracker;

import React, { useMemo } from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { Box, Container, Paper } from "@mui/material";
import { OrderInfo } from "../../helpers/types/response";
import { useAggregateInfo } from "../../hooks/apis";
import { useAppSelector } from "../../redux/store";
import { ADDRESS_TO_PAIR } from "../../helpers/constants/tokens";

type Props = {
  orderInfo: OrderInfo[];
  isLoading: boolean;
  chainId: number | undefined;
};

function TotalMarketInfo({ orderInfo, isLoading, chainId }: Props) {
  const tokenPrices = useAppSelector((state) => state.app.tokenPrices);
  const { aggregateInfo, isLoading: aggregateInfoLoading } = useAggregateInfo(chainId);

  const values = useMemo(() => {
    let totalSupply = 0,
      totalBorrowed = 0;
    // eslint-disable-next-line array-callback-return
    orderInfo.map((info) => {
      totalSupply += (tokenPrices[ADDRESS_TO_PAIR[info.asset.addr]] ?? 0) * Number(info.totalSupplied);
      totalBorrowed += (tokenPrices[ADDRESS_TO_PAIR[info.asset.addr]] ?? 0) * Number(info.totalBorrowed);
    });
    return [totalSupply.toFixed(0), totalBorrowed.toFixed(0)];
  }, [tokenPrices, orderInfo]);

  return (
    <Paper elevation={0}>
      <Container maxWidth="xl">
        <Stack
          px={{ sx: 2, md: 3, lg: 4, xl: 5 }}
          py={2}
          gap={2}
          flexDirection={"row"}
          justifyContent={"space-between"}
          flexWrap={"wrap"}
          alignItems={"center"}
        >
          <Box>
            <Typography color="grey">total supply</Typography>
            <Typography variant="h6" color="white">
              ${values[0] || "--"}
            </Typography>
          </Box>
          <Box>
            <Typography color="grey">total borrowed</Typography>
            <Typography variant="h6" color="white">
              ${values[1] || "--"}
            </Typography>
          </Box>
          <Box>
            <Typography color="grey">assets</Typography>
            <Typography variant="h6" color="white">
              {isLoading ? "--" : orderInfo.length}
            </Typography>
          </Box>
          <Box>
            <Typography color="grey">markets</Typography>
            <Typography variant="h6" color="white">
              {aggregateInfoLoading ? "--" : aggregateInfo?.orders}
            </Typography>
          </Box>
          <Box>
            <Typography color="grey">active loans</Typography>
            <Typography variant="h6" color="white">
              {aggregateInfoLoading ? "--" : aggregateInfo?.activeLoans}
            </Typography>
          </Box>
          <Box>
            <Typography color="grey">lenders</Typography>
            <Typography variant="h6" color="white">
              {aggregateInfoLoading ? "--" : aggregateInfo?.lenders}
            </Typography>
          </Box>
          <Box>
            <Typography color="grey">borrowers</Typography>
            <Typography variant="h6" color="white">
              {aggregateInfoLoading ? "--" : aggregateInfo?.borrowers}
            </Typography>
          </Box>
        </Stack>
      </Container>
    </Paper>
  );
}

export default TotalMarketInfo;

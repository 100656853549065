import { useState, useEffect } from "react";
import { pageRouters } from "../config/menu";
import { Location } from "react-router-dom";

export const useCurrentPage = (location: Location): number => {
  const [pageRouterIndex, setPageRouterIndex] = useState(0);

  useEffect(() => {
    const pageRouter = location.pathname.split("/")[1];
    setPageRouterIndex(pageRouters.indexOf(pageRouter));
  }, [location]);

  return pageRouterIndex;
};

import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { FormControl, Stack, Button, TextField, FormLabel } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import AddIcon from "@mui/icons-material/Add";

import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { fillCreationForm } from "../../../redux/reducers/order-slice";
import ActionButtons from "../ActionButtons";
import { BootstrapTooltip } from "../../Tooltip";
import untypedData from "../../../helpers/data/tooltip-data.json";
const tooltipData: any = untypedData;

function Step2() {
  const orderSlice = useAppSelector((state) => state.order);
  const dispatch = useAppDispatch();

  const [isPrivate, setIsPrivate] = useState<boolean>(
    orderSlice.orderCreationForm?.takers?.length ? true : false
  );
  const [isLeverage, setIsLeverage] = useState(orderSlice.orderCreationForm?.isLeverage || false);
  const [newTaker, setNewTaker] = useState("");
  const [takers, setTakers] = useState<string[]>(orderSlice.orderCreationForm?.takers ?? []);

  const handleAddTaker = () => {
    if (newTaker) {
      setTakers((prev: string[]) => {
        if (prev[0] === "") {
          return [newTaker];
        } else {
          return [...prev, newTaker];
        }
      });
      setNewTaker("");
    }
  };

  useEffect(() => {
    return () => {
      dispatch(fillCreationForm(["takers", takers]));
      dispatch(fillCreationForm(["isLeverage", isLeverage]));
    };
  }, [dispatch, isLeverage, takers]);

  return (
    <>
      <Box display={"flex"} gap={0.5}>
        <Typography variant="h6" color={"white.main"}>
          market type
        </Typography>
        <BootstrapTooltip title={tooltipData["market-type"]} arrow>
          <HelpOutlineIcon fontSize={"inherit"} sx={{ cursor: "pointer" }} />
        </BootstrapTooltip>
      </Box>
      <Stack flexDirection={{ xs: "column", md: "row" }} gap={5} my={4}>
        <Button
          sx={{
            py: 2,
            width: 240,
            borderRadius: 2,
            color: isPrivate ? "white.main" : "background.default",
            bgcolor: isPrivate ? "background.default" : "primary.main",
            "&:hover": {
              color: isPrivate ? "" : "background.default",
              bgcolor: isPrivate ? "" : "primary.main",
            },
          }}
          onClick={() => setIsPrivate(false)}
        >
          public
        </Button>
        <Button
          sx={{
            py: 2,
            width: 240,
            borderRadius: 2,
            color: isPrivate ? "background.default" : "white.main",
            bgcolor: isPrivate ? "primary.main" : "background.default",
            "&:hover": {
              bgcolor: isPrivate ? "primary.main" : "",
              color: isPrivate ? "background.default" : "",
            },
          }}
          onClick={() => setIsPrivate(true)}
        >
          private
        </Button>
      </Stack>

      {isPrivate && (
        <>
          <FormLabel id="multi-takers" sx={{ display: "flex", gap: 0.5 }}>
            <Typography sx={{ mb: 2 }}>enter address for access</Typography>
            <BootstrapTooltip title={tooltipData["private-address"]} arrow>
              <HelpOutlineIcon fontSize={"inherit"} sx={{ cursor: "pointer" }} />
            </BootstrapTooltip>
          </FormLabel>
          <Stack flexDirection={"row"} gap={3}>
            <FormControl>
              <TextField
                id="multi-takers"
                placeholder="enter address"
                variant="outlined"
                value={newTaker}
                sx={{
                  bgcolor: "background.default",
                  width: 500,
                  borderRadius: 2,
                  "& fieldset": {
                    border: 0,
                    borderRadius: 2,
                    borderColor: "transparent",
                  },
                }}
                onChange={(e) => setNewTaker(e.target.value)}
              />
            </FormControl>
            <Button
              sx={{
                py: 2,
                width: 240,
                borderRadius: 2,
                color: "white.main",
                bgcolor: "background.default",
                display: "flex",
              }}
              startIcon={<AddIcon />}
              onClick={() => handleAddTaker()}
            >
              add address
            </Button>
          </Stack>
          <TextField
            id="multi-takers"
            variant="outlined"
            value={takers}
            multiline
            sx={{
              bgcolor: "background.default",
              width: 500,
              borderRadius: 2,
              mt: 2,
              "& fieldset": {
                border: 0,
                borderRadius: 2,
                borderColor: "transparent",
              },
            }}
            onChange={(e) => setTakers(e.target.value.split(",").length ? e.target.value.split(",") : [])}
          />
        </>
      )}
      <Box display={"flex"} gap={0.5}>
        <Typography variant="h6" color={"white.main"}>
          leverage
        </Typography>
      </Box>
      <Stack flexDirection={{ xs: "column", md: "row" }} gap={5} my={4}>
        <Button
          sx={{
            py: 2,
            width: 240,
            borderRadius: 2,
            color: isLeverage ? "background.default" : "white.main",
            bgcolor: isLeverage ? "primary.main" : "background.default",
            "&:hover": {
              bgcolor: isLeverage ? "primary.main" : "",
              color: isLeverage ? "background.default" : "",
            },
          }}
          onClick={() => setIsLeverage(true)}
        >
          true
        </Button>
        <Button
          sx={{
            py: 2,
            width: 240,
            borderRadius: 2,
            color: isLeverage ? "white.main" : "background.default",
            bgcolor: isLeverage ? "background.default" : "primary.main",
            "&:hover": {
              color: isLeverage ? "" : "background.default",
              bgcolor: isLeverage ? "" : "primary.main",
            },
          }}
          onClick={() => setIsLeverage(false)}
        >
          false
        </Button>
      </Stack>
      <ActionButtons step={2} disabled={!orderSlice.orderCreationForm?.ordererId} />
    </>
  );
}

export default Step2;

export enum OrderTypes {
  LEND = "lend",
  BORROW = "borrow",
}

export enum PluginTypesEnum {
  ACCOUNT = "account",
  ASSESSOR = "assessor",
  LIQUIDATION = "liquidation",
  ORACLE = "oracle",
  POSITION = "position",
}

export enum PageName {
  DEFAULT = "default",
  ACCOUNT = "account",
  MARKETS = "markets",
  ACTIVITY = "activity",
  PLUGINS = "plugins",
  LIQUIDATION = "liquidation",
  NOTFOUND = "not-found",
}

export enum PluginName {
  StandardAssessor = "StandardAssessor",
  Wallet = "Wallet",
  StandardLiquidation = "StandardLiquidation",
  StaticOracle = "StaticOracle",
  BeanstalkSilo = "BeanstalkSilo",
  BeanOracle = "BeanOracle",
  ChainlinkOracle = "ChainlinkOracle",
  BeanDepositOracle = "BeanDepositOracle",
}

import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import ActionButtons from "../ActionButtons";
import { useAppSelector } from "../../../redux/store";
import { secondToDateType } from "../../../helpers/utils/format";
import { OrderTypes } from "../../../helpers/enums";
import { Divider } from "@mui/material";
import { getAssetData } from "../../../helpers/utils/assets";

function Step7() {
  const formData = useAppSelector((state) => state.order.orderCreationForm);
  const accounts = useAppSelector((state) => state.user.accounts);
  const plugins = useAppSelector((state) => state.user.plugins);
  return (
    <>
      <Grid container>
        <Grid xs={12}>
          <Typography variant="h6" mb={1}>
            review
          </Typography>
        </Grid>
        {/* Should be changed for prod version */}
        {formData?.duration * formData?.durationUnit > 2592000 && (
          <Grid xs={12} mb={1}>
            <Typography color={"yellow"} variant="body2" textAlign={"center"} bgcolor={"background.paper"}>
              max duration is set as 30 days for demo and testing purposes.
            </Typography>
          </Grid>
        )}
        <Grid xs={12} container>
          <Grid xs={12} sm={6} md={3}>
            <Typography variant="body2" color={"gray.main"}>
              market type
            </Typography>
            <Typography variant="body1">
              {formData.orderType === OrderTypes.LEND ? "lend" : "borrow"}
            </Typography>
          </Grid>
          <Grid xs={12} sm={6} md={3}>
            <Typography variant="body2" color={"gray.main"}>
              account
            </Typography>
            <Typography variant="body1">
              account #{accounts.findIndex((account) => account.id === formData.ordererId) + 1}
            </Typography>
          </Grid>
          <Grid xs={12} sm={6} md={3}>
            <Typography variant="body2" color={"gray.main"}>
              type
            </Typography>
            <Typography variant="body1">{formData?.takers.length ? "private" : "public"}</Typography>
          </Grid>
          <Grid xs={12} sm={6} md={3}>
            <Typography variant="body2" color={"gray.main"}>
              duration
            </Typography>
            {/* Should be changed for prod version */}
            <Typography variant="body1">
              {formData?.duration * formData?.durationUnit > 2592000
                ? "30 days"
                : `${formData?.duration} ${secondToDateType(
                    formData?.durationUnit
                  ).unit.toLocaleLowerCase()}`}
            </Typography>
          </Grid>
          {formData.orderType === OrderTypes.BORROW && (
            <Grid xs={12} sm={6} md={3}>
              <Typography variant="body2" color={"gray.main"}>
                ICR
              </Typography>
              <Typography variant="body1">{formData?.initialCollateralRatio}%</Typography>
            </Grid>
          )}
          <Grid xs={12} sm={6} md={3}>
            <Typography variant="body2" color={"gray.main"}>
              assessor
            </Typography>
            <Typography variant="body1">
              {plugins.find((plugin) => plugin.id === formData?.collectorId)?.name}
            </Typography>
          </Grid>
          <Grid xs={12} sm={6} md={3}>
            <Typography variant="body2" color={"gray.main"}>
              liquidation
            </Typography>
            <Typography variant="body1">
              {plugins.find((plugin) => plugin.id === formData?.closerId)?.name}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Divider sx={{ borderColor: "white", borderWidth: 1, opacity: 1, my: 3 }} />
      <Grid container>
        <Grid xs={12}>
          <Typography variant="h6" mb={1}>
            assets
          </Typography>
        </Grid>
        {formData?.loanAssetsInfo?.map((info: any, i: number) => {
          const assetData = getAssetData(info?.asset, accounts[0].chainId);
          return (
            <Grid xs={12} container key={i}>
              <Grid xs={12} sm={6} md={3}>
                <Typography variant="body2" color={"gray.main"}>
                  asset type
                </Typography>
                <Typography variant="body1">loan</Typography>
              </Grid>
              <Grid xs={12} sm={6} md={3}>
                <Typography variant="body2" color={"gray.main"}>
                  asset
                </Typography>
                <Typography variant="body1">{assetData?.symbol}</Typography>
              </Grid>
              <Grid xs={12} sm={6} md={3}>
                <Typography variant="body2" color={"gray.main"}>
                  oracle
                </Typography>
                <Typography variant="body1">
                  {plugins.find((plugin) => plugin.id === info?.oracleId)?.name}
                </Typography>
              </Grid>
              <Grid xs={12} sm={6} md={3}>
                <Typography variant="body2" color={"gray.main"}>
                  min. loan amount
                </Typography>
                <Typography variant="body1">
                  {info?.minLoanAmount} {assetData?.symbol}
                </Typography>
              </Grid>
            </Grid>
          );
        })}
        {formData?.collateralAssetsInfo?.map((info: any, i: number) => {
          const assetData = getAssetData(info?.asset, accounts[0].chainId);
          return (
            <Grid xs={12} container key={i}>
              <Grid xs={12} sm={6} md={3}>
                <Typography variant="body2" color={"gray.main"}>
                  asset type
                </Typography>
                <Typography variant="body1">collateral</Typography>
              </Grid>
              <Grid xs={12} sm={6} md={3}>
                <Typography variant="body2" color={"gray.main"}>
                  asset
                </Typography>
                <Typography variant="body1">{assetData?.symbol}</Typography>
              </Grid>
              <Grid xs={12} sm={6} md={3}>
                <Typography variant="body2" color={"gray.main"}>
                  oracle
                </Typography>
                <Typography variant="body1">
                  {plugins.find((plugin) => plugin.id === info?.oracleId)?.name}
                </Typography>
              </Grid>
              <Grid xs={12} sm={6} md={3}>
                <Typography variant="body2" color={"gray.main"}>
                  MCR
                </Typography>
                <Typography variant="body1">{info?.mcr}%</Typography>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
      <ActionButtons step={7} disabled={false} />
    </>
  );
}

export default Step7;

import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Asset } from "../../../helpers/types/basic";
import { getAssetData } from "../../../helpers/utils/assets";

export default function AssetsTable({ assets }: { assets: Asset[] }) {
  return (
    <TableContainer component={Paper} elevation={0} sx={{ borderRadius: 2 }}>
      <Table sx={{ minWidth: 650, bgcolor: "black.light" }} aria-label="asset table">
        <TableHead sx={{ bgcolor: "background.default" }}>
          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell>asset</TableCell>
            <TableCell align="right">amount</TableCell>
            <TableCell align="right">loaned</TableCell>
            <TableCell align="right">borrowed</TableCell>
            <TableCell align="right">collateral</TableCell>
            <TableCell align="right">available</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {assets?.length ? (
            <>
              {assets.map((asset, i) => {
                const assetData = getAssetData(asset.assetType, asset.chainId);
                return (
                  <TableRow key={i} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ display: "flex", gap: 1, alignItems: "center" }}
                    >
                      <img src={assetData?.logoURI} alt={assetData?.name} />
                      {assetData?.name}
                    </TableCell>
                    <TableCell align="right">
                      {asset.amount} {assetData?.symbol}
                    </TableCell>
                    <TableCell align="right">{asset.loanedAmount}</TableCell>
                    <TableCell align="right">{asset.borrowedAmount}</TableCell>
                    <TableCell align="right">{asset.collateralAmount}</TableCell>
                    <TableCell align="right">
                      {parseFloat((Number(asset.amount) - Number(asset.collateralAmount)).toFixed(4))}
                    </TableCell>
                  </TableRow>
                );
              })}
            </>
          ) : (
            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell sx={{ px: 2 }}>no assets</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

import React from "react";
import Box from "@mui/material/Box";

import UserInfo from "../../components/Account/UserInfo";
import CreateAccountButton from "../../components/Account/CreateAccountButton";
import AccountsInfo from "../../components/Account/AccountsInfo";
import AccountManageModal from "../../components/Account/AccountMangeModal";
import { useAppSelector } from "../../redux/store";
import { useOrderList } from "../../hooks/apis";
import PageMeta from "../../layout/PageMeta";
import { PageName } from "../../helpers/enums";
import { useNetwork } from "wagmi";

function Account() {
  const userAddress = useAppSelector((state) => state.user.address);
  const { chain } = useNetwork();
  const { orderList } = useOrderList(userAddress, chain?.id);
  return (
    <>
      <PageMeta pageName={PageName.ACCOUNT} />
      <Box>
        <UserInfo loansNumber={orderList?.length || 0} />
        <CreateAccountButton />
        <AccountManageModal />
        <AccountsInfo orders={orderList} chainId={chain?.id} />
      </Box>
    </>
  );
}

export default Account;

export function splitStringWithLineBreaks(str: string, maxLength = 42) {
  let result = "";
  if (str.length < 42) {
    return str;
  } else {
    str = str.replace(/\n/g, "").replace(",", "").replace(" ", "");
  }
  for (let i = 0; i < str.length; i += maxLength) {
    const substring = str.substr(i, maxLength);
    result += substring + "\n";
  }
  return result;
}

export const shortenAddress = (address: string | undefined) => {
  if (!address) return "...";
  return `${address.slice(0, 6)}...${address.slice(-4)}`;
};

export function containsOnlyZeros(str: string | null) {
  if (str) {
    str = str.replace(/^0x/, "");
    return /^0+$/.test(str);
  }
  return true;
}

export const unpackDataField = (data: `0x${string}`) => {
  return "0x" + data.slice(4);
};

export const secondToDateType = (seconds: number): { value: number | undefined; unit: string } => {
  if (Number(seconds) === Number.MAX_SAFE_INTEGER) {
    return { value: undefined, unit: "infinity" };
  } else if (seconds >= 2592000) {
    return { value: seconds / 2592000, unit: "months" };
  } else if (seconds >= 604800) {
    return { value: seconds / 604800, unit: "weeks" };
  } else if (seconds >= 86400) {
    return { value: seconds / 86400, unit: "days" };
  } else {
    return { value: seconds / 3600, unit: "hours" };
  }
};

export const convertDateType = (timestamp: string) => {
  const date = new Date(timestamp);

  const formattedDate = date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  const formattedTime = date.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  return `${formattedDate.replace(/\//g, "-")} ${formattedTime.toLowerCase()}`;
};

export const formatNumber = (value: number | bigint) => {
  return Intl.NumberFormat("en-US", {
    notation: "compact",
    maximumFractionDigits: 1,
  }).format(value);
};

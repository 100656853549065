import React, { useState, useEffect, useMemo } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import CancelIcon from "@mui/icons-material/Cancel";
import { Autocomplete, Box, Button, IconButton, InputAdornment, Stack, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useChainId } from "wagmi";
import { fetchToken } from "@wagmi/core";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { fillCreationForm } from "../../../redux/reducers/order-slice";
import { getAssetData, symbolToAsset } from "../../../helpers/utils/assets";
import ActionButtons from "../ActionButtons";
import SingleSelectInput from "../../SelectBox/SingleSelectInput";
import { AssetType } from "../../../helpers/types/basic";
import { useNotification } from "../../../hooks/useNotification";
import { PluginTypesEnum, OrderTypes } from "../../../helpers/enums";
import { tokenList } from "../../../helpers/constants/tokens";
import { BootstrapTooltip } from "../../Tooltip";
import untypedData from "../../../helpers/data/tooltip-data.json";
import { HashZero } from "../../../helpers/constants/address";
const tooltipData: any = untypedData;

function Step3() {
  const orderSlice = useAppSelector((state) => state.order);
  const userSlice = useAppSelector((state) => state.user);
  const { displayNotification } = useNotification();
  const [oracle, setOracle] = useState<string>("");
  const [loan, setLoan] = useState<string>("");
  const [tokenId, setTokenId] = useState<string>("0");
  const [tokenStandard, setTokenStandard] = useState<number>(1);
  const [unknownAddress, setUnknownAddress] = useState("");
  const [minLoanAmount, setMinLoanAmount] = useState<number | undefined>();
  const dispatch = useAppDispatch();
  const chainId = useChainId();

  const supportedAssets = useMemo(() => {
    if (orderSlice.orderCreationForm.orderType === OrderTypes.BORROW) {
      return tokenList.filter(
        (token) => token.chainId === (chainId || Number(process.env.REACT_APP_CHAIN_ID))
      );
    }
    const loadedAssets = userSlice.accounts.find(
      (account) => account.id === orderSlice.orderCreationForm.ordererId
    )?.assets;
    let values: any[] = [];
    if (loadedAssets) {
      values = loadedAssets
        .map((asset) => getAssetData(asset.assetType, asset.chainId))
        .filter((asset) => asset?.symbol !== undefined);
    }
    return values;
  }, [
    chainId,
    orderSlice.orderCreationForm.orderType,
    orderSlice.orderCreationForm.ordererId,
    userSlice.accounts,
  ]);

  const [loanAssetsInfo, setLoanAssetsInfo] = useState<
    {
      asset: AssetType;
      minLoanAmount: number;
      oracleId: number;
    }[]
  >(orderSlice.orderCreationForm?.loanAssetsInfo ?? []);

  const addAsset = async () => {
    let newAsset: AssetType;
    if (!loan) {
      if (unknownAddress) {
        try {
          const unknownToken = await fetchToken({ address: unknownAddress as `0x${string}`, chainId });
          newAsset = {
            standard: 1,
            addr: unknownToken.address,
            decimals: unknownToken.decimals,
            tokenId,
            data: HashZero,
          };
        } catch (error: any) {
          console.error(error);
          displayNotification({ message: "Invalid token address", type: "error" });
          return;
        }
      } else {
        displayNotification({ message: "Please select loan asset", type: "warning" });
        return;
      }
    } else {
      newAsset = symbolToAsset(loan, chainId, tokenId);
    }
    if (minLoanAmount && minLoanAmount <= 0) {
      displayNotification({ message: "Minimum loan amount should be more than 0", type: "warning" });
      return;
    }
    if (!oracle) {
      displayNotification({ message: "Please select oracle", type: "warning" });
      return;
    }
    const selectedOracle = userSlice?.plugins?.find(
      (plugin) => plugin.name === oracle && plugin.pluginType === PluginTypesEnum.ORACLE
    );
    if (!selectedOracle) {
      displayNotification({ message: "Invalid oracle", type: "warning" });
      return;
    }
    if (loanAssetsInfo?.findIndex((el) => el.asset.addr === newAsset.addr) >= 0) {
      displayNotification({ message: "Duplicated loan assets", type: "warning" });
      return;
    }
    setLoanAssetsInfo((prev) => {
      return [
        ...prev,
        {
          asset: newAsset,
          minLoanAmount: minLoanAmount || 0,
          oracleId: selectedOracle.id,
        },
      ];
    });
    setLoan("");
    setOracle("");
    setMinLoanAmount(undefined);
  };

  const onTagsChange = (event: any, value: any) => {
    setLoan(value?.symbol);
    setTokenStandard(value?.standard ?? 1);
    if (orderSlice.orderCreationForm.orderType === OrderTypes.LEND) {
      const tokenId = userSlice.accounts
        .find((account) => account.id === orderSlice.orderCreationForm.ordererId)
        ?.assets.find((asset) => asset.assetType.addr === value?.address)?.assetType.tokenId;
      setTokenId(tokenId?.toString() || "0");
    }
  };

  useEffect(() => {
    return () => {
      dispatch(fillCreationForm(["loanAssetsInfo", loanAssetsInfo]));
    };
  }, [chainId, dispatch, loanAssetsInfo]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid xs={12}>
          <Typography variant="h6" mb={3} color={"white.main"}>
            loan assets
          </Typography>
        </Grid>
        <Grid xs={12} component={Stack} flexDirection={"row"} alignItems={"flex-end"} gap={2}>
          {loanAssetsInfo.map((info, i) => {
            const assetData = getAssetData(info.asset, chainId);
            return (
              <Box key={i} position={"relative"}>
                <img src={assetData?.img} alt={assetData?.name} />
                <IconButton
                  onClick={() =>
                    setLoanAssetsInfo((prev) => prev.filter((el) => el.asset.addr !== assetData?.address))
                  }
                  sx={{ cursor: "pointer", position: "absolute", right: -5, top: -5, width: 20, height: 20 }}
                >
                  <CancelIcon sx={{ color: "red.main", width: 20 }} />
                </IconButton>
              </Box>
            );
          })}
        </Grid>
        <Grid xs={12} md={6}>
          <Typography variant="body2">asset</Typography>
          <Autocomplete
            freeSolo={orderSlice.orderCreationForm.orderType === OrderTypes.BORROW}
            options={supportedAssets}
            onChange={onTagsChange}
            sx={{ width: "100%", my: 1 }}
            getOptionLabel={(option) => option.symbol}
            renderOption={(props, option) => (
              <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                <img
                  loading="lazy"
                  width={25}
                  height={25}
                  src={option.logoURI}
                  srcSet={`${option.img} 2x`}
                  alt={option.name}
                />
                <Box>
                  <Typography variant="body1" color={"white.main"}>
                    {option.name}
                  </Typography>
                  <Typography variant="body2" color={"gray.main"}>
                    {option.symbol}
                  </Typography>
                </Box>
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  fontSize: 14,
                  borderRadius: 2,
                  border: 0,
                  bgcolor: "background.default",
                  "& fieldset": {
                    borderColor: "transparent",
                    borderRadius: 2,
                    border: 0,
                  },
                  "& input": { fontSize: 14, py: 2 },
                }}
                placeholder={`select asset ${
                  orderSlice.orderCreationForm.orderType === OrderTypes.BORROW ? "or paste address" : ""
                }`}
                onChange={(e) => setUnknownAddress(e.target.value)}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid xs={12} md={6}>
          {tokenStandard !== 1 && (
            <>
              <Typography variant="body2">token id</Typography>
              <TextField
                fullWidth
                variant="outlined"
                sx={{
                  borderRadius: 2,
                  my: 1,
                  border: 0,
                  bgcolor: "background.default",
                  "& fieldset": {
                    borderColor: "transparent",
                    borderRadius: 2,
                    border: 0,
                  },
                  "& input": { fontSize: 14, py: 2 },
                }}
                color="primary"
                placeholder="enter amount"
                value={tokenId}
                disabled={orderSlice.orderCreationForm.orderType === OrderTypes.LEND}
                onChange={(e) => setTokenId(e.target.value || "0")}
                type="string"
              />
            </>
          )}
        </Grid>
        <Grid xs={12} md={4}>
          <Stack direction="row" gap={0.5}>
            <Typography variant="body2">oracle</Typography>
            <BootstrapTooltip title={tooltipData["oracle"]} arrow>
              <HelpOutlineIcon fontSize={"inherit"} sx={{ cursor: "pointer" }} />
            </BootstrapTooltip>
          </Stack>
          <SingleSelectInput
            placeholder="select oracle"
            values={userSlice?.plugins
              .filter(
                (plugin) =>
                  plugin.pluginType === PluginTypesEnum.ORACLE && plugin.creatorAddress === userSlice.address
              )
              .map((plugin) => plugin.name)}
            selectValue={setOracle}
            selectedValue={oracle}
          />
        </Grid>
        <Grid xs={12} md={4}>
          <Typography variant="body2">minimum loan amount</Typography>
          <TextField
            fullWidth
            variant="outlined"
            sx={{
              borderRadius: 2,
              my: 1,
              border: 0,
              bgcolor: "background.default",
              "& fieldset": {
                borderColor: "transparent",
                borderRadius: 2,
                border: 0,
              },
              "& input": { fontSize: 14, py: 2 },
            }}
            color="primary"
            placeholder="enter amount"
            value={minLoanAmount}
            onChange={(e) => setMinLoanAmount(Number(e.target.value) || undefined)}
            type="number"
            InputProps={{
              inputProps: { style: { borderRadius: 5 } },
              endAdornment: (
                <InputAdornment position="end">
                  <Typography
                    fontSize={14}
                    borderLeft={1}
                    pl={2}
                    pr={1}
                    sx={{ opacity: 0.7 }}
                    color="main.white"
                  >
                    {loan || "--"}
                  </Typography>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid xs={12} md={4} display={"flex"} alignItems={"flex-end"} justifyContent={"center"}>
          <Button
            sx={{
              py: 2,
              mb: 1,
              width: 240,
              borderRadius: 2,
              color: "white.main",
              bgcolor: "background.default",
              display: "flex",
            }}
            startIcon={<AddIcon />}
            onClick={addAsset}
          >
            add loan asset
          </Button>
        </Grid>
      </Grid>
      <ActionButtons step={3} disabled={!loanAssetsInfo.length} />
    </>
  );
}

export default Step3;

import React, { useMemo, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";
import { signTypedData } from "@wagmi/core";
import { useAccount, useNetwork } from "wagmi";
import { useWeb3Modal } from "@web3modal/react";

import { PageName, PluginTypesEnum } from "../../helpers/enums";
import PageMeta from "../../layout/PageMeta";
import PageSpinner from "../../components/PageSpinner";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { PluginColors } from "../../helpers/constants/plugin-colors";
import {
  decodeAbiParameters,
  encodeAbiParameters,
  formatUnits,
  hexToString,
  parseAbiParameters,
  parseUnits,
  stringToHex,
} from "viem";
import { ParametersToDecode } from "../../config/parameter-abis";
import { createNewPlugin, deletePlugin, updatePlugin } from "../../redux/reducers/user-slice";
import { HashZero } from "../../helpers/constants/address";

function PluginDetail() {
  
  const params = useParams();
  const userSlice = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { chain } = useNetwork();
  const { isConnected } = useAccount();
  const { open } = useWeb3Modal();

  const [isDisabled, setIsDisabled] = useState(true);

  const selectedPlugin = useMemo(() => {
    if (params.pluginId) return userSlice.plugins.find((plugin) => plugin.id === Number(params.pluginId));
    return undefined;
  }, [params.pluginId, userSlice.plugins]);

  const getParameters = useMemo(() => {
    if (selectedPlugin) {
      const abiParameters = ParametersToDecode[selectedPlugin.pluginName];
      if (abiParameters === "") {
        return undefined;
      }
      return decodeAbiParameters(
        parseAbiParameters(abiParameters),
        selectedPlugin.parameters as `0x${string}`
      )[0];
    } else {
      return undefined;
    }
  }, [selectedPlugin]);

  const [parameters, setParameters] = useState<{ [key: string]: any }>(getParameters as any);
  const [description, setDescription] = useState(selectedPlugin?.description);
  const [name, setName] = useState(selectedPlugin?.name);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (selectedPlugin) {
      console.log("Selected plugin parameters:", selectedPlugin.parameters);
    }
  }, [selectedPlugin]);
  const getValue = (key: string, value: any) => {
    if (typeof value === "bigint") {
      return formatUnits(value, 18);
    } else if (key === "salt") {
      return hexToString(value, { size: 32 });
    } else {
      return value as string;
    }
  };
  const handleCancel = () => {
    setParameters(getParameters as any);
    setDescription(selectedPlugin?.description);
    setName(selectedPlugin?.name);
    setIsDisabled(true);
  };

  const domain = {
    name: process.env.REACT_APP_NAME,
    version: process.env.REACT_APP_VERSION,
    chainId: chain?.id ?? Number(process.env.REACT_APP_CHAIN_ID),
    verifyingContract: selectedPlugin?.pluginAddress,
  } as const;

  const types = {
    Message: [
      { name: "Welcome to Pharos", type: "string" },
      { name: "Wallet address", type: "address" },
      { name: "contents", type: "string" },
    ],
  } as const;
  const createMessage = {
    "Welcome to Pharos": "",
    "Wallet address": userSlice.address as `0x${string}`,
    contents: "Create New Instance!",
  } as const;

  const deleteMessage = {
    "Welcome to Pharos": "",
    "Wallet address": userSlice.address as `0x${string}`,
    contents: "Delete Instance!",
  } as const;

  const editMessage = {
    "Welcome to Pharos": "",
    "Wallet address": userSlice.address as `0x${string}`,
    contents: "Update Instance!",
  } as const;

  const handleButton = async (isEdit: boolean) => {
    if (isDisabled) {
      setIsDisabled((prev) => !prev);
      setIsEdit(isEdit);
    }
  };

  const handleSave = async () => {
    try {
      setIsLoading(true);
      if (isEdit) {
        const signature = await signTypedData({
          domain,
          message: editMessage,
          primaryType: "Message",
          types,
        });
        const pluginName = selectedPlugin?.pluginName;
        const pluginType = selectedPlugin?.pluginType;
        const id = selectedPlugin?.id;
        if (signature && pluginName && pluginType && id) {
            let params: `0x${string}`| "" = "";
            if (ParametersToDecode[pluginName] !== "") {
            params = encodeAbiParameters(parseAbiParameters(ParametersToDecode[pluginName]), [parameters])
            }
            else {
            params = '';
            };
            console.log("Saving plugin parameters:", params);

          await dispatch(
            updatePlugin({
              signature,
              message: { domain, types, value: editMessage },
              pluginType,
              chainId: chain?.id ?? Number(process.env.REACT_APP_CHAIN_ID),
              id,
              description,
              name,
              pluginName,
              parameters: params,
            })
          );
        }
      } else {
        const signature = await signTypedData({
          domain,
          message: createMessage,
          primaryType: "Message",
          types,
        });

        const pluginName = selectedPlugin?.pluginName;
        const pluginType = selectedPlugin?.pluginType;
        if (signature && pluginName && pluginType) {
          let params: `0x${string}` | "" = "";
            if (ParametersToDecode[pluginName] !== "") {
            params = encodeAbiParameters(parseAbiParameters(ParametersToDecode[pluginName]), [parameters])
            }
            else {
            params = "";
            };
          console.log("Saving plugin parameters:", params);

          await dispatch(
            createNewPlugin({
              signature,
              message: { domain, types, value: createMessage },
              pluginType,
              chainId: chain?.id ?? Number(process.env.REACT_APP_CHAIN_ID),
              description,
              name,
              pluginName,
              parameters: params,
            })
          );
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      setIsDeleting(true);
      const signature = await signTypedData({
        domain,
        message: deleteMessage,
        primaryType: "Message",
        types,
      });
      const id = selectedPlugin?.id;
      if (signature && id) {
        await dispatch(
          deletePlugin({
            signature,
            message: { domain, types, value: deleteMessage },
            id,
          })
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsDeleting(false);
      navigate("/plugin");
    }
  };

  return (
    <>
      <PageMeta pageName={PageName.PLUGINS} />
      {selectedPlugin === undefined ? (
        <PageSpinner />
      ) : (
        <Container maxWidth="lg">
          <Stack flexDirection={"row"} gap={3} mt={6} mb={2} alignItems={"center"}>
            <IconButton aria-label="back" onClick={() => navigate("/plugin")}>
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6">plugin #{selectedPlugin?.id}</Typography>
            {isConnected ? (
              <>
                {selectedPlugin?.parameters && (
                  <LoadingButton
                    loading={isLoading}
                    loadingIndicator={<CircularProgress color="inherit" size={28} />}
                    startIcon={isDisabled ? <AddIcon /> : <SaveIcon />}
                    sx={{
                      py: 1,
                      px: 2,
                      borderRadius: 2,
                      bgcolor: "background.paper",
                      color: "white.main",
                    }}
                    onClick={() => (isDisabled ? handleButton(false) : handleSave())}
                  >
                    {isDisabled ? "create new instance" : "save"}
                  </LoadingButton>
                )}
              </>
            ) : (
              <Button
                onClick={async () => open()}
                sx={{ borderRadius: 2, px: 2, py: 1, bgcolor: "background.paper", color: "white.main" }}
              >
                connect wallet
              </Button>
            )}

            {isDisabled && userSlice.address === selectedPlugin?.creatorAddress && (
              <Button
                sx={{
                  py: 1,
                  px: 2,
                  borderRadius: 2,
                  bgcolor: "background.paper",
                  color: "white.main",
                }}
                startIcon={<EditIcon />}
                onClick={() => handleButton(true)}
              >
                edit
              </Button>
            )}
            {!isDisabled && (
              <Button
                sx={{
                  py: 1,
                  px: 2,
                  borderRadius: 2,
                  bgcolor: "background.paper",
                  color: "white.main",
                }}
                startIcon={<ClearIcon />}
                onClick={handleCancel}
              >
                cancel
              </Button>
            )}
            {isDisabled && userSlice.address === selectedPlugin?.creatorAddress && (
              <LoadingButton
                loading={isDeleting}
                color="error"
                loadingIndicator={<CircularProgress color="inherit" size={28} />}
                startIcon={<DeleteIcon />}
                sx={{
                  py: 1,
                  px: 2,
                  borderRadius: 2,
                  bgcolor: "background.paper",
                }}
                onClick={handleDelete}
              >
                delete
              </LoadingButton>
            )}
          </Stack>
          <Paper elevation={0} sx={{ p: 4 }}>
            <Grid container rowSpacing={2}>
              <Grid xs={12} container>
                <Grid xs={12}>
                  <Typography variant="body2" pb={1} px={1} color={"gray.main"}>
                    name
                  </Typography>
                  <TextField
                    variant="outlined"
                    disabled={isDisabled}
                    sx={{
                      border: 0,
                      borderRadius: 1,
                      bgcolor: isDisabled ? "background.paper" : "background.default",
                      "& fieldset": {
                        borderColor: "transparent",
                        borderRadius: 1,
                        border: 0,
                      },
                      "& .Mui-disabled": {
                        WebkitTextFillColor: "white !important",
                      },
                      "& input": {
                        px: 1,
                        fontSize: 14,
                        py: 1,
                        color: "white.main",
                        borderColor: "transparent",
                      },
                    }}
                    color="primary"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    type="text"
                  />
                </Grid>
                <Grid xs={12}>
                  <Typography variant="body2" pb={1} px={1} color={"gray.main"}>
                    description
                  </Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    disabled={isDisabled}
                    sx={{
                      border: 0,
                      borderRadius: 1,
                      bgcolor: isDisabled ? "background.paper" : "background.default",
                      "& fieldset": {
                        borderColor: "transparent",
                        borderRadius: 1,
                        border: 0,
                      },
                      "& .Mui-disabled": {
                        WebkitTextFillColor: "white !important",
                      },
                      "& input": {
                        px: 1,
                        fontSize: 14,
                        py: 1,
                        color: "white.main",
                        borderColor: "transparent",
                      },
                    }}
                    color="primary"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    type="text"
                  />
                </Grid>
                <Grid xs={12} sm={4}>
                  <Typography variant="body2" pb={1} px={1} color={"gray.main"}>
                    category
                  </Typography>
                  <Typography
                    variant="body1"
                    px={1}
                    color={PluginColors[selectedPlugin?.pluginType || PluginTypesEnum.ACCOUNT]}
                  >
                    {selectedPlugin?.pluginType}
                  </Typography>
                </Grid>
                <Grid xs={12} sm={4}>
                  <Typography variant="body2" pb={1} px={1} color={"gray.main"}>
                    plugin
                  </Typography>
                  <Typography px={1} variant="body1">
                    {selectedPlugin?.pluginName}
                  </Typography>
                </Grid>
                {/* <Grid xs={12} sm={4}>
                  <Typography variant="body2" pb={1} px={1} color={"gray.main"}>
                    instance
                  </Typography>
                  <Typography variant="body1" px={1}>
                    #{selectedPlugin?.id}
                  </Typography>
                </Grid> */}
              </Grid>
            </Grid>
            <Divider sx={{ borderColor: "white", borderWidth: 1, opacity: 1, my: 3 }} />
            {parameters !== undefined && (
              <Grid container rowSpacing={2}>
                <Grid xs={12}>
                  <Typography variant="h6" mb={1}>
                    parameters
                  </Typography>
                </Grid>
                {Object.entries(parameters).map(([key, value]) => (
                  <Grid xs={12} key={key}>
                    <Typography variant="body2" pb={1} px={1} color={"gray.main"}>
                      {key}
                    </Typography>
                    <TextField
                      variant="outlined"
                      disabled={isDisabled}
                      sx={{
                        border: 0,
                        borderRadius: 1,
                        bgcolor: isDisabled ? "background.paper" : "background.default",
                        "& fieldset": {
                          borderColor: "transparent",
                          borderRadius: 1,
                          border: 0,
                        },
                        "& .Mui-disabled": {
                          WebkitTextFillColor: "white !important",
                        },
                        "& input": {
                          px: 1,
                          fontSize: 14,
                          py: 1,
                          color: "white.main",
                          borderColor: "transparent",
                        },
                      }}
                      color="primary"
                      placeholder="enter value"
                      value={getValue(key, value)}
                      onChange={(e) =>
                        setParameters((prev) => {
                          const newValue =
                            typeof value === "bigint"
                              ? parseUnits(e.target.value, 18)
                              : key === "salt"
                              ? stringToHex(e.target.value, { size: 32 })
                              : e.target.value;
                          return { ...prev, [key]: newValue };
                        })
                      }
                      type="text"
                    />
                  </Grid>
                ))}
              </Grid>
            )}
          </Paper>
        </Container>
      )}
    </>
  );
}

export default PluginDetail;

import io, { Socket } from "socket.io-client";

export let socket: Socket;

export const getSocket = () => {
  if (!socket) {
    socket = io(process.env.REACT_APP_API_URL || "https://api.pharosprotocol.com");
  }
  return socket;
};

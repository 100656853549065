import { useState, useEffect } from "react";
import { Order } from "../../helpers/types/response";
import { getOneOrder } from "../../helpers/utils/api";
import { useNotification } from "../useNotification";
import { AxiosError } from "axios";

export const useOneOrder = (
  assetAddress: string | undefined,
  hash: string | undefined,
  chainId: number | undefined,
  tokenId: string,
) => {
  const [order, setOrder] = useState<Order>();
  const [availableAmount, setAvailableAmount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const { displayNotification } = useNotification();

  useEffect(() => {
    (async () => {
      try {
        if (assetAddress && hash) {
          setIsLoading(true);
          const result = await getOneOrder(assetAddress, hash, chainId, tokenId);
          setOrder(result[0]);
          setAvailableAmount(result[1]);
        }
      } catch (error: any) {
        const e = error as AxiosError;
        if (e.isAxiosError) {
          displayNotification({ message: error?.response?.data.message, type: "error" });
        } else {
          console.error(error);
        }
      } finally {
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { order, availableAmount, isLoading };
};

import React from "react";
import Paper from "@mui/material/Paper";
import { useAppSelector } from "../../redux/store";
import StepComponents from "./steps";

function CreatePanel() {
  const orderState = useAppSelector((state) => state.order);

  return (
    <Paper elevation={0} sx={{ padding: 6, borderRadius: 3 }}>
      <StepComponents step={orderState.step} />
    </Paper>
  );
}

export default CreatePanel;

import { useState, useEffect } from "react";
import { getActivities } from "../../helpers/utils/api";
import { Activity } from "../../helpers/types/response";

export const useActivity = (chainId = Number(process.env.REACT_APP_CHAIN_ID)) => {
  const [activities, setOrderInfo] = useState<Activity[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const activities = await getActivities(chainId);
        setOrderInfo(activities);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [chainId]);

  return { activities, isLoading };
};

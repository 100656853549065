import React from "react";
import PluginInfo from "../../components/Plugin/PluginInfo";
import PluginList from "../../components/Plugin/PluginList";
import PageMeta from "../../layout/PageMeta";
import { PageName } from "../../helpers/enums";

function Plugin() {
  return (
    <>
      <PageMeta pageName={PageName.PLUGINS} />
      <PluginInfo />
      <PluginList />
    </>
  );
}

export default Plugin;
